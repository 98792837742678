import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import moment from 'moment';

import Loading from 'atoms/Loading';
import AnimationSuggestion from 'components/AnimationSuggestion';
import URLTools from 'utils/URL';

import './market.scss';
import AnimationMap from 'components/AnimationMap';

type Props = {
	history: History<any>;
};

type State = {
	displayMore: boolean;
	hovered?: any;
	markets: Array<any>;
	marketPage?: Array<any>;
	error: boolean;
	loading: boolean;
	page: number;
};

const PER_PAGE = 12;

const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

function getDate(data: any): string {
	if ((!data && !!data.dates_valides) || !data.dates_valides[0]) {
		return 'Dates non disponible';
	}

	const dayOne = data.dates_valides[0];
	const sauf = dayOne.sauf && dayOne.sauf.filter((elem: any) => !!elem);

	if (dayOne.date_debut === dayOne.date_fin) {
		return `le ${dayOne.date_debut}`;
	} else if (sauf && sauf.length > 0) {
		const validDays: Array<string> = [];

		days.forEach((day) => {
			if (sauf.map((elem: string) => elem.toLowerCase()).indexOf(day) < 0) {
				validDays.push(day);
			}
		});

		if (validDays.length < 1) {
			return 'Dates non disponible';
		} else if (validDays.length === 7) {
			return 'Tous les jours';
		}

		let res = validDays.length > 1 ? 'Les ' : 'Le ';
		validDays.forEach(
			(day, index) => (res += index === validDays.length - 1 ? day : `${day}, `),
		);

		return res;
	} else {
		return `du ${dayOne.date_debut} au ${dayOne.date_fin}`;
	}
}

function getDay(data: string) {
	for (let i = 0; i < days.length; i++) {
		if (data.includes(days[i])) {
			return i;
		}
	}

	return null;
}

function sortMarket(a, b) {
	const dateA = getDate(a).toLowerCase();
	const dateB = getDate(b).toLowerCase();

	const dayA = getDay(dateA);
	const dayB = getDay(dateB);

	if (dayA !== null) {
		if (dayB !== null) {
			return dayA - dayB;
		}

		return -1;
	}

	if (dateA.startsWith('le') || dateA.startsWith('les')) {
		if (dateB.startsWith('le') || dateB.startsWith('les')) {
			return moment(a.date_debut, 'DD/MM/YYYY') > moment(b.date_debut, 'DD/MM/YYYY') ? 1 : -1;
		}

		return -1;
	}

	if (dateB.startsWith('du')) {
		return moment(a.date_debut, 'DD/MM/YYYY') > moment(b.date_debut, 'DD/MM/YYYY') ? 1 : -1;
	}

	return 1;
}

export default class Markets extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			displayMore: false,
			markets: [],
			error: false,
			loading: false,
			page: 1,
		};
	}

	private getGoToAnimation = (id: number) => () => this.goToAnimation(id);

	private goToAnimation = (id: number) => this.props.history.push(`/animations/${id}`);

	private onMarketsFetched = (data: Array<any>) => {
		data = data.sort(sortMarket);

		this.setState({
			markets: this.state.markets.concat(data),
			loading: false,
			page: this.state.page + 1,
			displayMore: !!data && data.length === PER_PAGE,
		});
	};

	private fetchMarkets = () => {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true, error: false, displayMore: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/animations?marches&per_page=${PER_PAGE}&page=${this.state.page}`,
				),
				{
					method: 'GET',
				},
			)
				.then((response: any) => response.json())
				.then(this.onMarketsFetched)
				.catch((e: any) => {
					this.setState({ error: true, loading: false });
				});
		});
	};

	/**
	 * left part of /market : table from guttenberg
	 */
	private fetchMarketPage = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(
				URLTools.getApiURL(
					`//wp-json/cornouaille/v1/pages?slug=marches`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onMarketPageFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	}

	private onMarketPageFetched = (data: any) => {
		this.setState({ loading: false, marketPage: data, page: this.state.page + 1 }, () => {
			if (data && data.length === PER_PAGE) {
				this.fetchMarketPage();
			}
		});
	}

	private onAnimationHoverIn = (animation: any) => () =>
		setTimeout(() => this.setState({ hovered: animation }));

	private onAnimationHoverOut = (elem: any) => () =>
		setTimeout(() => {
			if (!this.state.hovered || elem.id !== this.state.hovered.id) {
				return;
			}

			this.setState({ hovered: undefined });
		});

	public componentDidMount() {
		this.fetchMarkets();
		this.fetchMarketPage()

		ReactGA.pageview(`/market`);
	}

	public render() {
		const { loading, markets, marketPage } = this.state;

		return (
			<div id="markets-page">
				<Helmet>
					<title>Cornouaille Animations - Les marchés de Cornouaille</title>
					<meta
						property="og:title"
						content="Cornouaille Animations - Les marchés de Cornouaille"
					/>
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Marchés</div>

				<div id="markets-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>LES MARCHÉS</span>

						<img alt="" src="/stand.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="description">
					<div id="description-title">
						<img alt="" src="/picto-lettrine.svg" aria-hidden="true" role="img"></img>

						<span>Foires et Marchés</span>
					</div>

					<div id="description-content">
						<p>
							Les foires et marchés sont autant d’occasions d’approcher le cœur d’un
							pays. Avec plus de 60 rendez-vous hebdomadaires, la Cornouaille ne fait
							pas exception.
						</p>
						<p>
							Au sein des halles d’Audierne, Douarnenez, Concarneau, Pont l’Abbé,
							Quimper et Quimperlé 85 commerçants sont à votre service. Le commerce
							sous halles c’est la convivialité, les contacts humains, la fraîcheur et
							la qualité des produits, la magie des étals colorés.
						</p>
					</div>
				</div>

				<div id="list-and-map-container">
					<div id="list-container">
						{loading && <Loading></Loading>}

						{!loading && marketPage && (
							<div id="market-list">
								{ marketPage.map((item, index) => <div key={index} dangerouslySetInnerHTML={{__html: item.content.rendered}}></div>) }
							</div>
						)}
					</div>

					<div id="map-container">
						<AnimationMap
							animationPopUpOnClick={this.getGoToAnimation}
							animations={markets}
							highlighted={this.state.hovered}
							zoom={9}
						/>
					</div>
				</div>

				<AnimationSuggestion history={this.props.history} />
			</div>
		);
	}
}
