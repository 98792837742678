import React, { useRef, useState } from 'react';
import { History } from 'history';

import { FlexRow } from 'atoms/Flex';
import URLTools from 'utils/URL';

import './Footer.scss';

export interface Props {
	history: History<any>;
}

const Footer: React.FC<Props> = (props) => {
	const [email, setEmail] = useState('');
	const [buttonMessage, setButtonMessage] = useState("OK");
	const formEl = useRef<HTMLFormElement>(null);

	const getGoTo = (target: string) => () => props.history.push(target);

	const gotToApsulis = () => window.open('https://www.apsulis.io');

	const goToQCD = () => window.open('https://www.quimper-cornouaille-developpement.bzh');

	const subscribe = (e) => {
		e.preventDefault();

		if (formEl.current) {
			if (formEl.current.reportValidity()) {
				setButtonMessage('EN COURS');

				const headers = new Headers();

				headers.append('Access-Control-Allow-Origin', URLTools.getApiURL(''));

				fetch(URLTools.getApiURL('/wp-json/cornouaille/v1/campaign_monitor'), {
					method: 'POST',
					body: new URLSearchParams({
						action: 'subscribe',
						EmailAddress: email,
						ConsentToTrack: 'Yes',
					}),
					headers,
				})
					.then((r) => {
						if (!r.ok) {
							throw new Error();
						}

						return Promise.resolve();
					})
					.then(() => {
						setButtonMessage('VOUS ÊTES MAINTENANT INSCRIT !');
					})
					.catch(() => {
						setButtonMessage('UNE ERREUR EST SURVENUE');

						setTimeout(() => {
							setButtonMessage("OK");
						}, 3000);
					});
			}
		}
	};

	let url = new URL(window.location.href);
	let params = new URLSearchParams(url.search.slice(1));

	return (
		<div id="footer">
			<div id="footer-content">
				{(() => {
					if (params.has('widget')) {
						return '';
					} else {
						return (
							<div className="form-container">
								<form ref={formEl} id="mail-input-container">
									<p>Abonnez-vous<br/>à notre newsletter</p>
									<input
										placeholder="Adresse email"
										type="email"
										className="mail-input"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>

									<button className="mail-button" onClick={subscribe}>
										{buttonMessage}
									</button>
								</form>
								<div id="shortcuts-button-container">
									<FlexRow className="button-container-flex-row">
										<button
											className="button-container"
											onClick={getGoTo('/contact')}>
											<img
												alt=""
												src="/infos.svg"
												className="button-icon"
												aria-hidden="true"
												role="img"
											/>

											<span>LES OFFICES<br/>DE TOURISME</span>
										</button>

										<button
											className="button-container"
											onClick={getGoTo('/flyer')}>
											<img
												src="/flyer.svg"
												className="button-icon"
												alt=""
												aria-hidden="true"
												role="img"
											/>

											<span>BROCHURES<br/>ET CARTES</span>
										</button>

										<button
											className="button-container"
											onClick={getGoTo('/widget')}>
											<img
												src="/picto-widget.svg"
												className="button-icon"
												alt=""
												aria-hidden="true"
												role="img"
											/>

											<span>LES ANIMATIONS<br/>SUR VOTRE SITE</span>
										</button>

										<button
											className="button-container"
											onClick={() =>
												window.open(URLTools.getApiURL('/animations/feed/'))
											}>
											<img
												src="/picto-rss.svg"
												className="button-icon"
												alt=""
												aria-hidden="true"
												role="img"
											/>

											<span>FLUX RSS<br/>DES COUPS DE COEUR</span>
										</button>
									</FlexRow>
								</div>
							</div>
						);
					}
				})()}
				<div id="logo-container">
					<img
						src="/logo-DQCB-blanc.svg"
						className="logo"
						alt=""
						aria-hidden="true"
						role="img"
					/>

					<img
						src="/logo-QCD-blanc.svg"
						className="logo"
						alt=""
						aria-hidden="true"
						role="img"
					/>

					<img
						src="/footer-content.svg"
						className="logo"
						alt=""
						aria-hidden="true"
						role="img"
					/>
				</div>
			</div>

			<div id="legal-mentions-container">
				<span className="legal-mention-item" onClick={getGoTo('/mentions')}>
					Mentions légales
				</span>{' '}
				-{' '}
				<span className="legal-mention-item" onClick={getGoTo('/plan')}>
					Plan
				</span>{' '}
				-{' '}
				<span className="legal-mention-item" onClick={getGoTo('/widget')}>
					Widget
				</span>{' '}
				-{' '}
				<span className="legal-mention-item" onClick={gotToApsulis}>
					APSULIS ©2019
				</span>{' '}
				-{' '}
				<span className="legal-mention-item" onClick={goToQCD}>
					Quimper Cornouaille Développement
				</span>
				<div className="border"></div>
			</div>
		</div>
	);
};

export default Footer;
