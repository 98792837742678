import React, { Component } from 'react';
import { History } from 'history';

import Button from 'atoms/Button';
import Loading from 'atoms/Loading';
import MaCornouaillePreview from 'components/MaCornouaillePreview';

import './maCornouaille.scss';

type Props = {
	history: History<any>;
};

type State = {
	error: boolean;
	loading: boolean;
	open: boolean;
	posts: Array<any>;
};

const SUMMER = 18;
const WINTER = 19;
const AUTUMN = 20;
const SPRING = 21;

export default class MaCornouaille extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			open: false,
			posts: [],
		};
	}

	private onDataFetched = (data: Array<any>) => {
		this.setState({ posts: this.state.posts.concat(data), loading: false });
	};

	private fetchData = () => {
		const today = new Date();
		const month = today.getMonth();
		let season: number;

		if (month < 3) {
			season = WINTER;
		} else if (month < 6) {
			season = SPRING;
		} else if (month < 9) {
			season = SUMMER;
		} else {
			season = AUTUMN;
		}

		this.setState({ loading: true, error: false }, () => {
			fetch(
				`https://www.macornouaille.bzh/wp-json/wp/v2/posts?_embed&per_page=3&orderby=date&saison=${season}`,
				{
					method: 'GET',
				},
			)
				.then(function(response: any) {
					return response.json();
				})
				.then(this.onDataFetched)
				.catch((e: any) => this.setState({ error: true }));
		});
	};

	private goToMaCornouaille = () => window.open('https://www.macornouaille.bzh/');

	private toggleOpen = () => this.setState({ open: !this.state.open });

	public componentDidMount() {
		this.fetchData();
	}

	public render() {
		return (
			<div id="macornouaille" className={this.state.open ? 'open' : ''}>
				<div className="macornouaille-title" onClick={this.toggleOpen}>
					<img src="/picto-triangle.svg" alt="" aria-hidden="true" role="img" /> LES
					EXPÉRIENCES #MACORNOUAILLE
				</div>

				{this.state.loading && <Loading />}

				<div id="macornouaille-posts-container">
					<div id="macornouaille-posts">
						{this.state.posts &&
							this.state.posts.map((elem, index) => (
								<MaCornouaillePreview data={elem} key={index} />
							))}
						{!(this.state.posts && this.state.posts.length > 0) && this.state.error && (
							<div>
								Impossible de récupérer les animations, veuillez réessayer plus tard
							</div>
						)}
					</div>
				</div>

				<Button onClick={this.goToMaCornouaille} color="orange">
					<span>ACCÉDER À MACORNOUAILLE.BZH</span>
				</Button>
			</div>
		);
	}
}
