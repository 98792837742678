import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import URLTools from 'utils/URL';

import './privacy.scss';
import Loading from 'atoms/Loading';

type Props = {
	history: History<any>;
};

type State = {
	privacy?: Array<any>;
	error: boolean;
	loading: boolean;
	page: number;
};

const PER_PAGE = 100;
const SLUG = 'politique-de-confidentialite';

export default class Privacy extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			page: 1,
		};
	}

	private onPrivacyFetched = (data: any) => {
		this.setState({ loading: false, privacy: data, page: this.state.page + 1 }, () => {
			if (data && data.length === PER_PAGE) {
				this.fetchPrivacy();
			}
		});
	};

	private fetchPrivacy = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/pages?per_page=${PER_PAGE}&page=${this.state.page}&slug=${SLUG}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onPrivacyFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	public componentDidMount() {
		this.fetchPrivacy();

		ReactGA.pageview(`/privacy`);
	}

	public render() {
		const { error, privacy, loading } = this.state;

		return (
			<div id="privacy-page">
				<Helmet>
					<title>Cornouaille Animations - Politique de confidentialité</title>
					<meta
						property="og:title"
						content="Cornouaille Animations - Politique de confidentialité"
					/>
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Accueil / Politique de confidentialité</div>

				<div id="privacy-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>POLITIQUE DE CONFIDENTIALITE</span>

						<img alt="" src="/privacy.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="documents-container">
					{loading ? (
						<Loading />
					) : error ? (
						<div>Une erreur a eu lieu, veuillez ré-essayer plus tard</div>
					) : (
						privacy &&
						privacy.map((privacy, index) => (
							<div className="document-item" key={index}>
								<div
									key={index}
									dangerouslySetInnerHTML={{
										__html: privacy.content.rendered as string,
									}}
								/>
							</div>
						))
					)}
				</div>
			</div>
		);
	}
}
