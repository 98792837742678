import React from 'react';
import { DateRange } from 'react-date-range';
import fr from 'date-fns/locale/fr';

import Loading from 'atoms/Loading';
import CategorySelector from 'components/CategorySelector';
import LocationSelector from 'components/LocationSelector';
import { fetchCategories, fetchCities } from 'utils/DataStore';
import URLTools from 'utils/URL';

import type { SearchParams } from 'types/searchParamsType';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

interface Props {
	categories: Array<any>;
	cities: any;
	displayed: boolean;
	searchParams: Partial<SearchParams>;

	onClose(): void;
	setSearchState(searchState: any): void;
}

type State = {
	areCategoriesLoading: boolean;
	categories: Array<number>;
	searchParams: Partial<SearchParams>;
	suggestedCities: Array<{ nom: string; communes: Array<string>; id: number }>;
};

export default class SearchPanel extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		const newSuggestedCities = props.cities
			? [...props.cities.pays, ...props.cities.communes].map((city, index) => {
					return {
						id: index,
						nom: city.nom ?? city,
						communes: city.communes ?? [],
					};
			  })
			: [];

		this.state = {
			areCategoriesLoading: true,
			categories: [],
			searchParams: this.props.searchParams,
			suggestedCities: newSuggestedCities,
		};
	}

	private onCategoriesFetched = (data: any) => {
		if (!data) {
			return;
		}

		const categories = data.liste.sort((a: any, b: any) => a.term_id - b.term_id);

		this.setState({
			areCategoriesLoading: false,
			categories,
		});
	};

	private getCategories = () => {
		//* setLoading
		this.setState({ areCategoriesLoading: true });

		//* fetch data
		fetchCategories(URLTools.getApiURL('wp-json/cornouaille/v1/taxonomies/categorie'), {
			method: 'GET',
			withCache: true,
		}).then(this.onCategoriesFetched);
	};

	private getCategoryOnClick = (elem: any) => () => {
		const newSelectedCategories = new Set(this.state.searchParams.selectedCategories);

		if (newSelectedCategories.has(elem.term_id)) {
			newSelectedCategories.delete(elem.term_id);
		} else {
			newSelectedCategories.add(elem.term_id);
		}

		const selectedCategories = Array.from(newSelectedCategories);

		this.setState({
			searchParams: { ...this.state.searchParams, selectedCategories },
		});
	};

	private isSelectedCategory = (elem: any) =>
		this.state.searchParams.selectedCategories
			? this.state.searchParams.selectedCategories.includes(elem.term_id)
			: false;

	private selectChildrenFriendly = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, childrenFriendly: true },
		});

	private selectNotChildrenFriendly = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, childrenFriendly: false },
		});

	private onGetPosition = ({ coords }: any) => {
		const position = {
			latitude: coords.latitude,
			longitude: coords.longitude,
		};

		this.setState({
			searchParams: { ...this.state.searchParams, position, isGeolocalized: true },
		});
	};

	private onErrorPosition = (error: any) => {
		this.setState(
			{
				searchParams: { ...this.state.searchParams, isGeolocalized: false },
			},
			() => {
				if (error.code !== error.PERMISSION_DENIED) {
					alert('Impossible de vous géolocaliser');
				}
			},
		);
	};

	private selectNotGeolocalize = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, isGeolocalized: false },
		});

	private cityOnChangeRadio = (e: any) => {
		const { cities, selectedCitiesIndex } = this.state.searchParams;

		const selectedCityId = e.target?.dataset?.index
			? parseInt(e.target?.dataset?.index)
			: e.value.id;
		const selectedCityName =
			e.target?.dataset?.city ?? e.value?.nom ?? e.target?.dataset?.item ?? '';

		if (!selectedCitiesIndex && !cities) {
			const newCity = this.state.suggestedCities.find((city) => city.id === selectedCityId);

			if (newCity) {
				this.setState({
					searchParams: {
						...this.state.searchParams,
						cities: [newCity],
						selectedCitiesIndex: [newCity.id],
					},
				});
			}
		} else if (selectedCitiesIndex && cities) {
			if (!selectedCitiesIndex.includes(selectedCityId)) {
				const newCity = this.state.suggestedCities.find(
					(city) => city.id === selectedCityId,
				);

				if (newCity) {
					let newSelectedCitiesIndex = selectedCitiesIndex;
					newSelectedCitiesIndex.push(selectedCityId);
					let newCities = cities;
					newCities.push(newCity);

					this.setState({
						searchParams: {
							...this.state.searchParams,
							cities: newCities,
							selectedCitiesIndex: newSelectedCitiesIndex,
						},
					});
				}
			} else {
				const newSelectedCitiesIndex = selectedCitiesIndex.filter(
					(city) => city !== selectedCityId,
				);
				const newCities = cities.filter((city) => city.nom !== selectedCityName);

				this.setState({
					searchParams: {
						...this.state.searchParams,
						cities: newCities,
						selectedCitiesIndex: newSelectedCitiesIndex,
					},
				});
			}
		} else {
			this.setState({
				searchParams: {
					...this.state.searchParams,
					cities: undefined,
					selectedCitiesIndex: undefined,
				},
			});
		}
	};

	private renderSearchPanelTitle = () => {
		return (
			<div id="search-params-header">
				<span id="header-title">FAIRE UNE RECHERCHE</span>

				<img src="/close.svg" alt="Fermer" onClick={this.props.onClose} />
			</div>
		);
	};

	private renderCategorySelector = () => {
		return (
			<CategorySelector
				categories={this.state.categories}
				getCategoryOnClick={this.getCategoryOnClick}
				isSelectedCategory={this.isSelectedCategory}
				sectionTitleMobile={`Catégories d'animations`}
				sectionTitleDesktop={`Sélectionner une catégorie`}
			/>
		);
	};

	private renderDatePicker = () => {
		const { searchParams } = this.state;
		let selectionRange = {
			startDate: searchParams.beginDate ?? new Date(),
			endDate: searchParams.endDate ?? new Date(),
			key: 'selection',
			color: '#0096ae',
		};
		return (
			<div id="date-param" className="search-params-section">
				<div className="title">SÉLECTIONNER LES DATES :</div>
				<div className="specific-date-selector">
					<span onClick={this.changeDate} data-specificdate="today">
						Aujourd'hui
					</span>
					<span onClick={this.changeDate} data-specificdate="tomorrow">
						Demain
					</span>
					<span onClick={this.changeDate} data-specificdate="this-week">
						Cette semaine
					</span>
				</div>
				<DateRange
					locale={fr}
					ranges={[selectionRange]}
					minDate={new Date()}
					onChange={this.changeDate}
					// showDateDisplay={false}
					editableDateInputs={true}
					dateDisplayFormat={'dd/MM/yyyy'}
					weekStartsOn={1}
					// color={'#0096ae'}
				/>
			</div>
		);
	};

	private changeDate = (e) => {
		const specificDate = e.currentTarget?.dataset?.specificdate;

		if (!!specificDate) {
			if (specificDate === 'today') {
				const today = new Date();

				this.setState({
					searchParams: {
						...this.state.searchParams,
						beginDate: today,
						endDate: today,
					},
				});
			} else if (specificDate === 'tomorrow') {
				let tomorrow = new Date();
				tomorrow = new Date(tomorrow.setTime(tomorrow.getTime() + 24 * 3600 * 1000));

				this.setState({
					searchParams: {
						...this.state.searchParams,
						beginDate: tomorrow,
						endDate: tomorrow,
					},
				});
			} else if (specificDate === 'this-week') {
				const beginDate = new Date();
				const startDateIndex = beginDate.getDay();
				let endDate = new Date();
				endDate.setDate(endDate.getDate() + (7 - startDateIndex));

				this.setState({
					searchParams: {
						...this.state.searchParams,
						beginDate,
						endDate,
					},
				});
			}
		} else {
			const beginDate = e.selection.startDate;
			const endDate = e.selection.endDate;

			this.setState({
				searchParams: {
					...this.state.searchParams,
					beginDate,
					endDate,
				},
			});
		}
	};

	private renderChildrenOption = () => {
		return (
			<div id="is-children-friendly" className="search-params-section">
				<div className="title">Adapté aux enfants ?</div>

				<div className="select">
					<div
						onClick={this.selectChildrenFriendly}
						className={!!this.state.searchParams.childrenFriendly ? 'selected' : ''}>
						OUI
					</div>

					<div
						onClick={this.selectNotChildrenFriendly}
						className={!this.state.searchParams.childrenFriendly ? 'selected' : ''}>
						NON
					</div>
				</div>
			</div>
		);
	};

	private renderLocationSelector = () => {
		const { searchParams, suggestedCities } = this.state;

		return (
			<LocationSelector
				cityOnChange={this.cityOnChangeRadio}
				cityOnChangeRadio={this.cityOnChangeRadio}
				onErrorPosition={this.onErrorPosition}
				onGetPosition={this.onGetPosition}
				searchParams={searchParams}
				selectNotGeolocalize={this.selectNotGeolocalize}
				suggestedCities={suggestedCities}
			/>
		);
	};

	private renderSearchValidationMobile = () => {
		return (
			<div id="validate">
				<span
					onClick={() => {
						this.props.setSearchState(this.state.searchParams);
						this.props.onClose();
					}}>
					VALIDER LA RECHERCHE
				</span>
			</div>
		);
	};

	private renderSearchValidationDesktop = () => {
		return (
			<div id="validate" className="display-on-desktop desktop-validation__right">
				<div className="validate__buttons">
					<button
						className="validate__btn"
						onClick={() => {
							this.props.setSearchState(this.state.searchParams);
							this.props.onClose();
						}}>
						Valider
					</button>

					<button className="validate__btn" onClick={this.props.onClose}>
						Annuler
					</button>
				</div>

				<img src="/close.svg" alt="Fermer" onClick={this.props.onClose} />
			</div>
		);
	};

	public componentDidMount = () => {
		if (window.navigator && window.navigator.geolocation && window.innerWidth >= 600) {
			const location = window.navigator.geolocation;

			if (location) {
				location.getCurrentPosition(this.onGetPosition, this.onErrorPosition);
			}
		}

		setTimeout(() => {
			if (this.props.categories) {
				return this.onCategoriesFetched(this.props.categories);
			}

			this.getCategories();
		}, 0);

		setTimeout(() => {
			fetchCities();
		});
	};

	public componentDidUpdate = (prevProps) => {
		const { cities, searchParams } = this.props;
		const { cities: oldCities, searchParams: oldSearchParams } = prevProps;

		//* update cities
		const newSuggestedCities = cities
			? [...cities.pays, ...cities.communes].map((city, index) => {
					return {
						id: index,
						nom: city.nom ?? city,
						communes: city.communes ?? [],
					};
			  })
			: [];

		if (cities !== oldCities) {
			this.setState({
				suggestedCities: newSuggestedCities,
			});
		}

		// update this.state.searchParams when this.props.searchParams has changed
		// it means it has been changed from ResearchSummary
		if (searchParams !== oldSearchParams) {
			this.setState({ searchParams: this.props.searchParams });
		}
	};

	public render() {
		const { areCategoriesLoading } = this.state;

		if (areCategoriesLoading) {
			return (
				<div
					id="search-params-container"
					className={this.props.displayed ? 'show' : 'hide'}>
					<div id="search-params">
						<Loading />
					</div>
				</div>
			);
		}

		return (
			<div id="search-params-container" className={this.props.displayed ? 'show' : 'hide'}>
				<div id="search-params">
					<div className="display-on-mobile">
						{this.renderSearchPanelTitle()}

						{this.renderCategorySelector()}

						{this.renderDatePicker()}

						{this.renderLocationSelector()}

						{this.renderChildrenOption()}

						{this.renderSearchValidationMobile()}
					</div>

					<div className="display-on-desktop">
						<div className="search-param-desktop">
							{this.renderCategorySelector()}

							<div className="desktop-middle-selectors">
								{this.renderChildrenOption()}

								{this.renderDatePicker()}
							</div>

							<div className="desktop-right-selectors">
								{this.renderLocationSelector()}
							</div>
						</div>

						<div className="desktop-validation flex-row">
							<div className="desktop-validation__left flex-1/flex-2>1500" />

							<div className="desktop-validation__middle flex-1" />

							{this.renderSearchValidationDesktop()}
						</div>
					</div>
				</div>

				<div id="params-blurring" onClick={this.props.onClose} />
			</div>
		);
	}
}
