import React from 'react';

import {
	getActivitySelection,
	getSelection,
	setActivitySelection,
	setSelection,
} from 'utils/Selection';

import './itemPreview.scss';

export interface ItemPreviewProps {
	data: any;
	deleteIcon?: boolean;
	itemType?: string;
	fullSelection?: boolean;

	onAddition?(): void;
	onClick(): void;
	onDeletion?(id: number): void;
	onMouseEnter?(): void;
	onMouseLeave?(): void;
	setFullSelection?(): void;
}

function isFree(data: any) {
	const isEntryFree = data.entree && data.entree.match(/(gratuit(e)?(s)?)|(free)/i);

	return isEntryFree && isEntryFree.length > 0;
}

function getImageSrc(data: any) {
	return (
		(data.featured_media &&
			data.featured_media.sizes &&
			data.featured_media.sizes.medium &&
			data.featured_media.sizes.medium.src) ||
		(data.featured_media &&
			data.featured_media.sizes &&
			data.featured_media.sizes.medium_large &&
			data.featured_media.sizes.medium_large.src) ||
		(data.featured_media && data.featured_media.src)
	);
}

function getImgClassName(data: any): string {
	if (data && data.featured_media) {
		return data.featured_media.width > data.featured_media.sizes.height
			? 'landscape'
			: 'portrait';
	}

	return 'landscape';
}

const ItemPreview: React.FC<ItemPreviewProps> = ({
	data,
	deleteIcon,
	fullSelection,
	itemType,
	onAddition,
	onClick,
	onDeletion,
	onMouseEnter,
	onMouseLeave,
	setFullSelection,
}) => {
	function addSelection(e: any, id: number, onAddition?: () => void, itemType?: string) {
		if (!e) {
			e = window.event;
		}
		e.cancelBubble = true;
		if (e.stopPropagation) {
			e.stopPropagation();
		}

		if (!!fullSelection) {
			return;
		}

		let storedArray: void | Array<number> = [];
		// const storedArray: Array<number> = getSelection() || [];
		if (!!itemType && itemType === 'activité') {
			storedArray = getActivitySelection() || [];
		} else {
			storedArray = getSelection() || [];
		}

		if (!!storedArray && !storedArray.includes(id)) {
			storedArray.push(id);

			if (!!itemType && itemType === 'activité') {
				setActivitySelection(storedArray);
			} else {
				setSelection(storedArray);
			}

			if (setFullSelection) {
				setFullSelection();
			}

			if (onAddition) {
				onAddition();
			}
		}
		// };
	}

	function removeSelected(id: number, onDeletion?: (id: number) => void, itemType?: string) {
		return (e: any) => {
			if (!e) {
				e = window.event;
			}
			e.cancelBubble = true;
			if (e.stopPropagation) {
				e.stopPropagation();
			}

			if (setFullSelection) {
				setFullSelection();
			}

			let storedArray: void | Array<number> = [];
			if (!!itemType && itemType === 'activité') {
				storedArray = getActivitySelection();
			} else {
				storedArray = getSelection();
			}

			if (!!storedArray) {
				const index = storedArray.indexOf(id);
				storedArray.splice(index, index + 1);

				if (!!itemType && itemType === 'activité') {
					setActivitySelection(storedArray);
				} else {
					setSelection(storedArray);
				}

				if (onDeletion) {
					onDeletion(id);
				}
			}
		};
	}
	const src = getImageSrc(data) || '/logo-bleu.png';
	let selected = false;
	let stored: void | Array<number> = [];

	if (!!itemType && itemType === 'activité') {
		stored = getActivitySelection();
	} else {
		stored = getSelection();
	}

	if (stored && stored.includes(data.id)) {
		selected = true;
	}

	return (
		<div
			className="item-preview"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}>
			{!selected && (
				<div className="selection">
					<img
						onClick={(e) => addSelection(e, data.id, onAddition, itemType)}
						src="/empty-star.svg"
						alt=""
						className="selected-icon to-select-icon"
						aria-hidden="true"
						role="img"></img>
					{fullSelection && (
						<div className="warning">
							Vous ne pouvez plus ajouter d'élément à votre sélection. Merci de
							supprimer des éléments si vous souhaitez en ajouter.
						</div>
					)}
				</div>
			)}

			{!deleteIcon && selected && (
				<img
					src="/blue-star.svg"
					alt=""
					className="selected-icon"
					aria-hidden="true"
					role="img"></img>
			)}
			{selected && deleteIcon && (
				<img
					src="/picto-suppr.svg"
					alt=""
					className="selected-icon delete-icon"
					aria-hidden="true"
					role="img"
					onClick={removeSelected(data.id, onDeletion, itemType)}></img>
			)}

			{!!src && (
				<div
					style={{
						backgroundImage: `url(${src})`,
					}}
					aria-hidden="true"
					role="img"
					className={`item-image ${getImgClassName(data)}`}></div>
			)}

			<div className="info-container">
				<div className="info-container-left">
					<div className="item-name">{data.nom_offre}</div>
					<div className="item-city">{data.commune}</div>
				</div>

				<div className="item-extra-data">
					{isFree(data) && (
						<img src="/picto-gratuit.svg" alt="Gratuit" className="free-icon" />
					)}
				</div>
			</div>

			{itemType !== 'activité' && (
				<div className="info-date-container">
					{`${data.date_debut} au ${data.date_fin}`}
				</div>
			)}

			<div className="item-hover-plus">
				<div className="circle-plus">
					<img alt="" src="/picto-plus.svg" aria-hidden="true" role="img" />
				</div>
			</div>
		</div>
	);
};

export default ItemPreview;
