export function getCalendar(data: any) {
	const { latitude, longitude } = data;

	return (
		'BEGIN:VCALENDAR\r\n' +
		'VERSION:2.0\r\n' +
		'PRODID:-//cornouaille-animations.bzh//animation calendar//FR\r\n' +
		'VERSION:2.0\r\n' +
		'BEGIN:VEVENT\r\n' +
		`SUMMARY:${data.extrait.replace(/(\r\n)|(\n)/gm, ' ')}\r\n` +
		`DTSTART:${getEventDate(data)}\r\n` +
		`DTSTAMP:${getCalendarToday()}\r\n` +
		`UID:cornouaille-animations.bzh/animations/${data.id}/${Date.now()}\r\n` +
		`LOCATION:${data.lieu_principal}${data.adresse1 ? `, ${data.adresse1}` : ''}${
			data.adresse2 ? `, ${data.adresse2}` : ''
		}\r\n` +
		`GEO:${latitude};${longitude}\r\n` +
		`DESCRIPTION:${data.description.replace(/(\r\n)|(\n)/gm, ' ')}\r\n` +
		'END:VEVENT\r\n' +
		'END:VCALENDAR'
	);
}

export function getCalendarToday() {
	const date = new Date();
	const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
	const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

	const hour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
	const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;

	return `${date.getFullYear()}${month}${day}T${hour}${minutes}00`;
}

export function getEventDate(data: any) {
	const eventDate = data.dates_valides && data.dates_valides[0];

	if (eventDate) {
		const dmy = eventDate.date_debut.split('/').map((elem: string) => parseInt(elem, 10));

		const date = new Date(dmy[2], dmy[1] - 1, dmy[0]);
		const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
		const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

		const hour = eventDate.heure_debut ? eventDate.heure_debut.replace(':', '') : '0000';

		return `${date.getFullYear()}${month}${day}T${hour}00`;
	}
}

/**
 * Formate une date JS en date lisible sous la forme dd/mm/yyyy
 * @param date date
 */
export function convertDate(inputFormat) {
	function pad(s) {
		return s < 10 ? '0' + s : s;
	}
	var d = new Date(inputFormat);
	return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}
