// npm imports
import React, { Component } from 'react';
import Modal from 'react-modal';

// local imports
import { getImgClassName } from 'utils/ImageUtils';

import './singleItemContent.scss';

// types
type Props = {
    goBackOnHistory: any;
    hilightsId: number;
    item: any;
};

type State = {
    modalIsOpen: boolean;
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

// specific constants and variables
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

// component
export default class SingleItemContent extends Component<Props, State> {
	public constructor(props: Props) {
        super(props);
        
		this.state = {
			modalIsOpen: false,
        };
        
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
    };

	openModal() {
		this.setState({ modalIsOpen: true });
    };
    
	closeModal() {
		this.setState({ modalIsOpen: false });
    };

    private isHilights(data: any) {
        if (!data || !data.categorie_id) {
            return false;
        };
    
        for (const catId of data.categorie_id) {
            if (catId === this.props.hilightsId) {
                return true;
            };
        };
    
        return false;
    };
    

    render() {
        const { item } = this.props;
		const image = item.featured_media;

        return(
            <>
                <div id="item-location">
                    Accueil / {item.lieu_principal} - {item.nom_offre}
                </div>

                <div id="item-header">
                    <img
                        className="arrow-back"
                        onClick={this.props.goBackOnHistory}
                        src="/right-arrow.svg"
                        aria-hidden="true"
                        role="img"
                        alt=""
                    />

                    <span id="item-header-title">{item.nom_offre}</span>

                    {this.isHilights(item) && (
                        <div className="is-hilight">
                            <span>TEMPS FORTS</span>{' '}

                            <img src="/heart.svg" alt="" aria-hidden="true" role="img" />
                        </div>
                    )}
                </div>

                <div id="item-data-container">
                    {image && image.src && (
                        <div id="item-main-picture">
                            <img
                                src={item.featured_media.src}
                                alt=""
                                className={getImgClassName(image.width, image.height)}
                                onClick={this.openModal}
                            />
                        </div>
                    )}

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Example Modal"
                        style={customStyles}>
                        <img
                            src={item.featured_media.src}
                            alt=""
                            className={getImgClassName(image.width, image.height)}
                            onClick={this.closeModal}
                        />
                    </Modal>
                    
                    <div>
                        <div id="item-title">
                            <div id="title-text">
                                {item.nom_offre}
                                <img
                                    src="/picto-lettrine.svg"
                                    className="lettrine"
                                    alt=""
                                    aria-hidden="true"
                                    role="img"
                                />
                            </div>
                        </div>

                        <div
                            id="item-content"
                            dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </div>
                </div>
            </>
        );
    };
};
