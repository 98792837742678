import React, { Component } from 'react';
import { History, UnregisterCallback } from 'history';

import AnimationMap from 'components/AnimationMap';
import ItemSearchResults from 'components/ItemSearchResults';
import SearchPanel from 'components/Animations/AnimationsSearchPanel';
import ResearchSummary from 'components/ResearchSummary';
import { DataConsumer, fetchAnimations } from 'utils/DataStore';
import { convertDate } from 'utils/DateUtils';

import './Animations.scss';

import type { SearchParams } from 'types/searchParamsType';

export interface Props {
	animations: any;
	displaySearchParams: boolean;
	history: History<any>;
	parentScrollContainer?: string;
	url: string;

	closeSearch(): void;
	openSearch(): void;
	toggleSearch(): void;
}

type State = {
	animations: Array<any>;
	loading: boolean;
	page: number;
	mapMode: boolean;
	bounds?: L.LatLngBounds;
	mapAndListSynchronized: boolean;

	searchInProgress: boolean;
	searchParams: Partial<SearchParams>;
	searchParamsHaveChanged: boolean;
	mounting: boolean;

	position?: {
		latitude: number;
		longitude: number;
	};
};

const PER_PAGE = 100;

export default class Animations extends Component<Props, State> {
	private map?: AnimationMap | null;
	private historyListener?: UnregisterCallback;
	private self?: HTMLDivElement | null;

	public constructor(props: Props) {
		super(props);

		let previousSearch;

		if (window.sessionStorage && this.props.history.action === 'POP') {
			const searchStr = window.sessionStorage.getItem('animationSearch');

			if (searchStr) {
				previousSearch = JSON.parse(searchStr);
				previousSearch.beginDate = !!previousSearch.beginDate
					? convertDate(previousSearch.beginDate)
					: convertDate(new Date());
				previousSearch.endDate = !!previousSearch.endDate
					? convertDate(previousSearch.endDate)
					: convertDate(new Date());
			}
		}

		this.state = {
			animations: [],
			loading: false,
			mapAndListSynchronized: false,
			mapMode: false,
			page: 1,
			searchInProgress: !!previousSearch,
			searchParams: previousSearch ?? {},
			searchParamsHaveChanged: false,
			mounting: true,
		};
	}

	private onAnimationsDataFetched = (data: any, withCache: boolean) => {
		requestAnimationFrame(() => {
			this.setState(
				{
					loading: false,
					page: this.state.page + 1,
					animations: this.state.animations.concat(data),
				},
				() => {
					if (data && data.length === PER_PAGE) {
						this.getAnimations(withCache);
					}

					if (this.state.mounting && this.props.history.action === 'POP') {
						let lastPosition;
						let lastHomePosition;

						if (window.sessionStorage) {
							const lastPositionStr = window.sessionStorage.getItem('lastPosition');
							const lastHomePositionStr =
								window.sessionStorage.getItem('lastHomePosition');

							if (lastPositionStr) {
								const lastPositionStack = JSON.parse(lastPositionStr);
								lastPosition = lastPositionStack.pop() || 0;
								window.sessionStorage.setItem(
									'lastPosition',
									JSON.stringify(lastPositionStack),
								);
							}

							if (lastHomePositionStr) {
								const lastHomePositionStack = JSON.parse(lastHomePositionStr);
								lastHomePosition = lastHomePositionStack.pop() || 0;
								window.sessionStorage.setItem(
									'lastHomePosition',
									JSON.stringify(lastHomePositionStack),
								);
							}
						}

						if (lastPosition || lastHomePosition) {
							if (this.self && this.props.parentScrollContainer) {
								const scrollContainer = document.querySelector(
									this.props.parentScrollContainer,
								);

								if (scrollContainer) {
									scrollContainer.scrollTo(0, lastHomePosition || 0);
								}
							}

							window.scrollTo(0, lastPosition || 0);
						}

						this.setState({ mounting: false });
					}
				},
			);
		});
	};

	private setSearchState = (searchParams: Partial<SearchParams>) => {
		this.setState({ searchParams, searchParamsHaveChanged: true }, this.updateSessionStorage);
	};

	private updateSessionStorage = () => {
		const { searchParams } = this.state;

		if (window.sessionStorage) {
			window.sessionStorage.setItem('animationSearch', JSON.stringify(searchParams));
		}

		if (
			!searchParams.childrenFriendly &&
			!searchParams.city &&
			!searchParams.beginDate &&
			!searchParams.endDate &&
			!searchParams.selectedCategories?.length
		) {
			window.sessionStorage.removeItem('animationSearch');
		}

		this.onSearchParamsValidate();
	};

	private hasParams = () => !!Object.keys(this.state.searchParams).length;

	private hasSearchSessionStorage = () => !!window.sessionStorage.getItem('animationSearch');

	private getAnimations = (withCache = false) => {
		let url = `${this.props.url}?per_page=${PER_PAGE}&ordre=ASC&page=${this.state.page}`;

		const {
			beginDate,
			endDate,
			childrenFriendly,
			city,
			cities,
			isGeolocalized,
			position,
			selectedCategories,
		} = this.state.searchParams;

		if (beginDate) {
			url += `&a_partir=${convertDate(beginDate)}`;
		} else {
			url += `&a_partir=${convertDate(new Date())}`;
		}

		if (endDate) {
			url += `&jusqu_a=${convertDate(endDate)}`;
		} else {
			url += `&jusqu_a=${convertDate(new Date())}`;
		}

		if (childrenFriendly) {
			url += `&enfants`;
		}

		if (isGeolocalized) {
			if (position) {
				url += `&latitude=${position.latitude}&longitude=${position.longitude}&radius=20`;
			}
		} else if (cities) {
			let separator = '';
			let communes = '&commune=';

			// Le nombre de villes est ok lorsqu'on console log l'url par rapport à ce qu'on a dans cities => ok

			try {
				cities?.forEach((city) => {
					city.communes.forEach((elem) => {
						communes += separator + elem;

						if (separator === '') {
							separator = ',';
						}
					});
					if (city.nom !== '' && !city.nom.includes('Autour')) {
						communes += separator + city.nom;
						if (separator === '') {
							separator = ',';
						}
					}
				});

				url += communes;
			} catch (e) {}
		} else if (city) {
			let separator = '';
			let communes = '&commune=';

			try {
				city.communes.forEach((elem) => {
					communes += separator + elem;

					if (separator === '') {
						separator = ',';
					}
				});

				url += communes;
			} catch (e) {}
		}

		if (selectedCategories && selectedCategories.length) {
			url += `${url}&categorie=${selectedCategories.join(',')}`;
		}

		this.setState({ loading: true }, () => {
			fetchAnimations(url, {
				method: 'GET',
				concatWithCache: this.state.page !== 1 && !this.hasParams(),
				withCache: withCache && !this.hasParams(),
			}).then((data) => this.onAnimationsDataFetched(data, withCache));
		});
	};

	private onSearchParamsValidate = () => {
		this.setState(
			{
				animations: [],
				page: 1,
				searchInProgress: true,
			},
			() => {
				this.getAnimations();
			},
		);
	};

	private getAnimationOnClick = (id?: number) => () => {
		this.props.history.push(`/animations/${id || ''}`);
	};

	private closeSearchParams = () => this.props.closeSearch();

	private saveUserCurrentPosition = ({ coords }) => {
		this.setState({ position: { latitude: coords.latitude, longitude: coords.longitude } });
	};

	private selectMapMode = () =>
		this.setState({ mapMode: true }, () => {
			if (window.navigator && window.navigator.geolocation && window.innerWidth < 600) {
				const location = window.navigator.geolocation;

				if (location) {
					location.getCurrentPosition(this.saveUserCurrentPosition);
				}
			}

			this.map && this.map.invalidateSize();
		});

	private selectListMode = () => this.setState({ mapMode: false });

	private onMoveEnd = (bounds: L.LatLngBounds) => {
		this.setState({ bounds });
	};

	public componentDidMount() {
		//* rerender the previous search when it exists in sessionStorage when the user come from another page
		let previousSearch;
		const searchStr = window.sessionStorage.getItem('animationSearch');

		if (searchStr) {
			previousSearch = JSON.parse(searchStr);
			previousSearch.beginDate = new Date(previousSearch.beginDate);
			previousSearch.endDate = new Date(previousSearch.endDate);

			this.setState({ searchParams: previousSearch });
		}

		//
		setTimeout(() => {
			if (this.props.animations && !this.hasParams()) {
				return this.onAnimationsDataFetched(this.props.animations, true);
			}

			this.getAnimations(true);
		}, 0);

		if (this.props.history.location.pathname.startsWith(`/search`)) {
			this.props.openSearch();
		}

		this.historyListener = this.props.history.listen((location, action) => {
			if (this.props.history.location.pathname.startsWith(`/search`)) {
				this.props.openSearch();
			}
		});

		if (window.innerWidth >= 600) {
			this.setState({
				mapAndListSynchronized: true,
			});
		}
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const { searchParams, searchParamsHaveChanged } = this.state;
		const { searchParams: prevSearchParams } = prevState;

		if (!!searchParamsHaveChanged && searchParams === prevSearchParams) {
			this.setState({ searchParamsHaveChanged: false });
		}
	}

	private toggleMapAndListSynchronization = () => {
		this.setState({ mapAndListSynchronized: !this.state.mapAndListSynchronized });
	};

	public componentWillUnmount() {
		if (this.historyListener) {
			this.historyListener();
		}

		if (window.sessionStorage && this.props.history.action !== 'POP') {
			const lastPositionStr = window.sessionStorage.getItem('lastPosition');
			const lastPositionStack = lastPositionStr ? JSON.parse(lastPositionStr) : [];

			lastPositionStack.push(window.scrollY);
			window.sessionStorage.setItem('lastPosition', JSON.stringify(lastPositionStack));

			if (this.props.parentScrollContainer) {
				const scrollContainer = document.querySelector(this.props.parentScrollContainer);

				if (scrollContainer) {
					const lastHomePositionStr = window.sessionStorage.getItem('lastHomePosition');
					const lastHomePositionStack = lastHomePositionStr
						? JSON.parse(lastHomePositionStr)
						: [];
					lastHomePositionStack.push(scrollContainer.scrollTop);

					window.sessionStorage.setItem(
						'lastHomePosition',
						JSON.stringify(lastHomePositionStack),
					);
				}
			}
		}
	}

	public render() {
		const {
			animations,
			bounds,
			loading,
			mapAndListSynchronized,
			mapMode,
			searchParams,
			searchParamsHaveChanged,
		} = this.state;
		const { displaySearchParams, history, parentScrollContainer, toggleSearch } = this.props;

		return (
			<div id="animations" ref={(item) => (this.self = item)}>
				<DataConsumer>
					{(store) => (
						<SearchPanel
							categories={store.categories}
							cities={store.cities}
							displayed={this.props.displaySearchParams}
							searchParams={searchParams}
							onClose={this.closeSearchParams}
							setSearchState={this.setSearchState}
						/>
					)}
				</DataConsumer>
				<div className="animations-body">
					<div className="animations-body-left">
						<div className={mapMode ? 'shown' : 'hidden'} id="animation-map-container">
							<AnimationMap
								onMoveEnd={this.onMoveEnd}
								ref={(elem) => (this.map = elem)}
								animations={this.state.animations}
								animationPopUpOnClick={this.getAnimationOnClick}
								currentPosition={this.state.position}
							/>

							<div className="map-parameters">
								{this.hasSearchSessionStorage() && (
									// && searchInProgress
									<DataConsumer>
										{(store) => (
											<ResearchSummary
												categories={store.categories}
												closeSearch={this.props.closeSearch}
												searchParams={searchParams}
												setSearchState={this.setSearchState}
											/>
										)}
									</DataConsumer>
								)}
								<div className="map-parameters__map-mouse">
									<input
										type="checkbox"
										checked={this.state.mapAndListSynchronized}
										onChange={this.toggleMapAndListSynchronization}
									/>
									<span>Rechercher quand je déplace la souris</span>
								</div>
							</div>
						</div>
					</div>

					<ItemSearchResults
						bounds={bounds}
						displaySearchParams={displaySearchParams}
						items={animations}
						itemType={`animation`}
						getItemOnClick={this.getAnimationOnClick}
						history={history}
						loading={loading}
						mapAndListSynchronized={mapAndListSynchronized}
						mapMode={mapMode}
						parentScrollContainer={parentScrollContainer}
						searchParamsHaveChanged={searchParamsHaveChanged}
						searchParamsOnClick={toggleSearch}
						selectListMode={this.selectListMode}
						selectMapMode={this.selectMapMode}
						title={`Les animations`}
					/>
				</div>
			</div>
		);
	}
}
