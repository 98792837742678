import React from 'react';

import './activityTypeButton.scss';

export interface ActivityTypeButtonProps {
    id: number | string;
    isActive: boolean;
    name: string;
    picto: string;

	onClick?(e: React.MouseEvent): void;
};

const ActivityTypeButton: React.FC<ActivityTypeButtonProps> = (props) => {
	return (
		<button
            className={`activity-type__button ${props.isActive ? 'active' : ''}`}
            onClick={props.onClick}
        >
            <img src={`/${props.picto}`} alt="" aria-hidden="true" role="img" />
			<p>{props.name}</p>
		</button>
	);
};

export default ActivityTypeButton;