// npm imports
import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// local imports
import Loading from 'atoms/Loading';
import AnimationMap from 'components/AnimationMap';
import Carousel from 'components/Carousel';
import SingleItemContent from 'components/SingleItemContent';
import AnimationsSuggestion from 'components/Suggestions';
import UsefulInfos from 'components/UsefulInfos';
import { fetchCities } from 'utils/DataStore';
import URLTools from 'utils/URL';
import { getSelection, setSelection } from 'utils/Selection';

import './animation.scss';

// types
type Props = {
	history: History<any>;
	match: any;
};

type State = {
	animation?: any;
	isSelected: boolean;
	loading: boolean;
	animationError: boolean;

	animationsSuggestion?: Array<any>;
	suggestionLoading: boolean;
	suggestionError: boolean;
};

const HILIGHTS_ID = 73;
const CHILDREN_FRIENDLY_ID = 11;

// component
export default class Animation extends Component<Props, State> {
	private toPrint!: any;

	public constructor(props: Props) {
		super(props);

		this.state = {
			animationError: false,
			suggestionError: false,
			isSelected: false,
			loading: false,
			suggestionLoading: false,
		};
	}

	// life cycle
	public componentDidMount() {
		this.fetchAnimation();

		ReactGA.pageview(`/animations/${this.props.match.params.id}`);
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.fetchAnimation();

			ReactGA.pageview(`/animations/${this.props.match.params.id}`);
		}
	}

	// data
	private fetchSuggestions = (data: any) => {
		const onDataFetched = (animationsSuggestion: any) =>
			this.setState({
				suggestionLoading: false,
				animationsSuggestion,
			});

		fetchCities().then((cities) => {
			this.setState({ suggestionLoading: true, suggestionError: false }, () => {
				let citiesToSearch;
				try {
					citiesToSearch = cities.pays.find((elem) =>
						elem.communes.includes(data.commune),
					).communes;
				} catch (e) {
					citiesToSearch = [data.commune];
				}

				fetch(
					URLTools.getApiURL(
						`/wp-json/cornouaille/v1/animations?commune=${citiesToSearch.join(
							',',
						)}&per_page=4&ordre=ASC`,
					),
					{ method: 'GET' },
				)
					.then((response: any) => response.json())
					.then(onDataFetched)
					.catch((e: any) => {
						this.setState({ suggestionError: true });
					});
			});
		});
	};

	private fetchAnimation = () => {
		const idParam = this.props.match.params.id;
		let id;

		if (typeof idParam === 'number') {
			id = idParam;
		} else if (typeof idParam === 'string' && idParam.indexOf('-') !== -1) {
			id = parseInt(idParam.substr(idParam.lastIndexOf('-') + 1), 10);
		} else {
			id = parseInt(idParam, 10);
		}

		const onDataFetched = (data: any) => {
			let selected = false;
			const stored = getSelection();
			if (stored) {
				for (const elem of stored) {
					if (elem === data.id) {
						selected = true;
						break;
					}
				}
			}

			this.setState({ loading: false, animation: data, isSelected: selected }, () => {
				this.fetchSuggestions(data);
				window.scrollTo(0, 0);
			});
		};

		this.setState({ loading: true }, () => {
			fetch(URLTools.getApiURL(`/wp-json/cornouaille/v1/animations/${id}`), { method: 'GET' })
				.then((response: any) => response.json())
				.then(onDataFetched)
				.catch((e: any) => this.setState({ animationError: true }));
		});
	};

	// useful infos
	private selectionOnClick = () => {
		const storedArray: Array<number> = getSelection() || [];

		if (!this.state.isSelected) {
			storedArray.push(this.state.animation.id);
		} else {
			const index = storedArray.indexOf(this.state.animation.id);
			storedArray.splice(index, index + 1);
		}

		setSelection(storedArray);
		this.setState({ isSelected: !this.state.isSelected });
	};

	// map
	private goToGPS = () => () => {
		const { latitude, longitude } = this.state.animation;
		window.open(`https://maps.google.com/?q=${latitude},${longitude}&z=8`);
	};

	// suggestions
	private getGoToAnimation = (id: number) => () => this.props.history.push(`/animations/${id}`);

	// component render
	public render() {
		const { history } = this.props;
		const { animation, animationsSuggestion, isSelected, loading, suggestionLoading } =
			this.state;

		if (loading || !animation) {
			return <Loading />;
		}

		const { latitude, longitude } = animation;

		return (
			<div id="animation-page" ref={(elem) => (this.toPrint = elem)}>
				<Helmet>
					<title>Cornouaille Animations {animation && `- ${animation.nom_offre}`}</title>
					<meta name="description" content={animation && animation.description} />
					<meta property="og:title" content={animation && animation.nom_offre} />
					<meta property="og:description" content={animation && animation.description} />
					<meta property="og:image" content={animation && animation.featured_media.src} />
				</Helmet>

				<SingleItemContent
					goBackOnHistory={history.goBack}
					hilightsId={HILIGHTS_ID}
					item={animation}
				/>

				<Carousel item={animation} />

				<div id="animation-bottom-container">
					<UsefulInfos
						childFriendlyId={CHILDREN_FRIENDLY_ID}
						isSelected={isSelected}
						item={animation}
						itemName={'animation'}
						selectionOnClick={this.selectionOnClick}
						toPrint={this.toPrint}
					/>

					<div id="animation-page-map">
						<div id="animation-map-title">
							<span>LOCALISER L'ANIMATION</span>
							<img alt="" src="/picto-pin_black.svg" aria-hidden="true" role="img" />
						</div>

						<AnimationMap
							center={[parseFloat(latitude), parseFloat(longitude)]}
							animations={[animation]}
							zoom={14}
							animationPopUpMessage="S'Y RENDRE"
							animationPopUpOnClick={this.goToGPS}
						/>
					</div>
				</div>

				{/* Suggestion */}
				{suggestionLoading && <Loading />}

				{!suggestionLoading &&
					animation &&
					animationsSuggestion &&
					animationsSuggestion.length > 0 && (
						<AnimationsSuggestion
							item={animation}
							suggestion={animationsSuggestion}
							getGoToItem={this.getGoToAnimation}
						/>
					)}
			</div>
		);
	}
}
