import React from 'react';

interface Props {
	categories: Array<any>;
    sectionTitleMobile: string;
    sectionTitleDesktop: string;

    getCategoryOnClick(elem: any): () => void;
    isSelectedCategory(elem: any): boolean;
};

type State = {
	categoriesDisplayed: boolean;
};

export default class CategorySelector extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {
			categoriesDisplayed: false,
		};
    };
  
	private displayCategoriesOnClick = () =>
        this.setState({ categoriesDisplayed: !this.state.categoriesDisplayed });
    
    private renderCategories = () => {
        const { categories, getCategoryOnClick, isSelectedCategory } = this.props; 
    
		return (
			<div id="categories-container">
				{categories
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((elem: any, index) => (
						<div
							// https://stackoverflow.com/questions/51977823/type-void-is-not-assignable-to-type-event-mouseeventhtmlinputelement
							onClick={getCategoryOnClick(elem)}
							key={index}
							className={index % 2 === 0 ? 'even' : 'odd'}>
							<input
								type="checkbox"
								checked={isSelectedCategory(elem)}
								onChange={getCategoryOnClick(elem)}
							/>

							<span>{elem.name}</span>
						</div>
					))}
			</div>
		);
	};
  
    public render() {
        const {
            categories,
            sectionTitleMobile,
            sectionTitleDesktop,
        } = this.props;
        const { categoriesDisplayed } = this.state;

		return (
			categories.length > 0 && (
				<>
					<div id="categories" className="search-params-section display-on-mobile">
						<div
                            className="title" 
                            onClick={this.displayCategoriesOnClick}
                        >
							<span>{sectionTitleMobile}</span>

							<div id="open-icon-container">
								<img
									alt=""
									src="/arrow-head.svg"
									className={categoriesDisplayed ? 'opened' : 'closed'}
									aria-hidden="true"
									role="img"
								/>
							</div>
						</div>

						{ categoriesDisplayed && this.renderCategories() }
					</div>

					<div id="categories" className="search-params-section display-on-desktop desktop-left-selectors">
						<div className="title">{sectionTitleDesktop}</div>

						{this.renderCategories()} 
					</div>
				</>
			)
		)
  };
};