// npm imports
import React from 'react';

// local imports
import ActivityTypeButton from 'atoms/ActivityTypeButton';
import Loading from 'atoms/Loading';
import CategorySelector from 'components/CategorySelector';
import LocationSelector from 'components/LocationSelector';
import { fetchActivityTypeCategories, fetchCities } from 'utils/DataStore';
import URLTools from 'utils/URL';

import { ActivitySearchParams } from 'types/searchParamsType';

// types
interface Props {
	categories: Array<any>;
	cities: any;
	currentActivityType: string;
	displayed: boolean;
	searchParams: Partial<ActivitySearchParams>;

	onClose(): void;
	setSearchState(searchState: Partial<ActivitySearchParams>, currentActivityType: string): void;
}

type State = {
	activityTypeList: Array<{ id: string; isActive: boolean; name: string; picto: string }>;
	areCategoriesLoading: boolean;
	categories: Array<number>;
	currentActivityType: string;
	searchParams: Partial<ActivitySearchParams>;
	suggestedCities: Array<{ nom: string; communes: Array<string>; id: number }>;
};

// component data from outside the store or the state
const activityTypeListData = [
	{
		id: 'deg',
		isActive: false,
		name: 'Déguster',
		picto: 'picto-deguster.png',
	},
	{
		id: 'pcu',
		isActive: false,
		name: 'Visiter',
		picto: 'picto-visiter.png',
	},
	{
		id: 'asc',
		isActive: false,
		name: 'Sports et culture',
		picto: 'picto-sports.png',
	},
	{
		id: 'loi',
		isActive: false,
		name: 'Loisirs',
		picto: 'picto-loisirs.png',
	},
];

// component
export default class SearchPanel extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		//* get previous activity type to update activityTypeList
		let previousActivityType;
		let activityTypeList = activityTypeListData;

		if (window.sessionStorage) {
			const searchStr = window.sessionStorage.getItem('currentActivityType');

			if (searchStr) {
				previousActivityType = searchStr;
				activityTypeList = this.updateActivityTypeList(
					activityTypeListData,
					previousActivityType,
				);
			}
		}

		//* init state
		const newSuggestedCities = props.cities
			? [...props.cities.pays, ...props.cities.communes].map((city, index) => {
					return {
						id: index,
						nom: city.nom ?? city,
						communes: city.communes ?? [],
					};
			  })
			: [];

		this.state = {
			activityTypeList,
			areCategoriesLoading: true,
			categories: [],
			currentActivityType: this.props.currentActivityType,
			searchParams: this.props.searchParams,
			suggestedCities: newSuggestedCities,
		};
	}

	// LIFE CYCLE
	public componentDidMount = () => {
		if (window.navigator && window.navigator.geolocation && window.innerWidth >= 600) {
			const location = window.navigator.geolocation;

			if (location) {
				location.getCurrentPosition(this.onGetPosition, this.onErrorPosition);
			}
		}

		setTimeout(() => {
			this.getCategories();
		}, 0);

		setTimeout(() => {
			fetchCities();
		});
	};

	public componentDidUpdate = (prevProps, prevState) => {
		const { cities, searchParams } = this.props;
		const { cities: oldCities, searchParams: oldSearchParams } = prevProps;
		const { currentActivityType } = this.state;
		const { currentActivityType: oldActivityType } = prevState;

		//* update cities
		const newSuggestedCities = cities
			? [...cities.pays, ...cities.communes].map((city, index) => {
					return {
						id: index,
						nom: city.nom ?? city,
						communes: city.communes ?? [],
					};
			  })
			: [];

		if (cities !== oldCities) {
			this.setState({
				suggestedCities: newSuggestedCities,
			});
		}

		//* update activityTypeCategories
		if (currentActivityType !== oldActivityType) {
			this.getCategories();
		}

		//* update this.state.searchParams when this.props.searchParams has changed
		// it means it has been changed from ResearchSummary
		if (searchParams !== oldSearchParams) {
			this.setState({ searchParams: this.props.searchParams });
		}
	};

	// ACTIVITY TYPES
	/**
	 * handle change of activity type on click on a button
	 * into activeTypeList and currentActivityType states
	 * and fetch categories according to currentActivityType state
	 *
	 * @param activityId id of the selected activity type on click
	 */
	private activityTypeSelection = (activityId: string) => {
		const { activityTypeList } = this.state;
		// update activity type list
		let updatedActivityTypeList = this.updateActivityTypeList(activityTypeList, activityId);

		// update the state
		this.setState(
			{
				activityTypeList: updatedActivityTypeList,
				currentActivityType: activityId,
			},
			() => this.getCategories(),
		);
	};

	/**
	 * update the activityTypeList array according to the selected activity type
	 *
	 * @param activityTypeList
	 * @param activityId
	 */
	private updateActivityTypeList = (activityTypeList: Array<any>, activityId: string) => {
		return activityTypeList.map((activity) => {
			// active on the selected type
			if (activity.id === activityId) {
				return {
					...activity,
					isActive: true,
				};
			}

			// other types are not active
			return {
				...activity,
				isActive: false,
			};
		});
	};

	// DATA
	private getCategories = () => {
		//* set loading
		this.setState({ areCategoriesLoading: true });

		//* fetch data
		let url = URLTools.getApiURL(
			`wp-json/cornouaille/v1/taxonomies/categorie_${this.state.currentActivityType}`,
		);

		fetchActivityTypeCategories(url, {
			method: 'GET',
			withCache: true,
		}).then(this.onCategoriesFetched);
	};

	private onCategoriesFetched = (data: any) => {
		if (!data) {
			return;
		}

		const categories = data.liste.sort((a: any, b: any) => a.term_id - b.term_id);

		this.setState({
			areCategoriesLoading: false,
			categories,
		});
	};

	private getCategoryOnClick = (elem: any) => () => {
		const { currentActivityType } = this.state;

		// we need to get selected categories according to the current activity type in the object selectedCategories in searchParams
		let currentActivitySelectedCategories = this.handleCurrentActivitySelectedCategories();

		//* create an array with the new selection of categories
		const newSelectedCategories = new Set(currentActivitySelectedCategories);

		if (newSelectedCategories.has(elem.term_id)) {
			newSelectedCategories.delete(elem.term_id);
		} else {
			newSelectedCategories.add(elem.term_id);
		}

		const updatedSelectedCategories = Array.from(newSelectedCategories);

		//* update ActivitiesSearchPanel state accordingly
		this.setState({
			searchParams: {
				...this.state.searchParams,
				selectedCategories: {
					...this.state.searchParams.selectedCategories,
					[currentActivityType]: updatedSelectedCategories,
				},
			},
		});
	};

	private isSelectedCategory = (elem: any) => {
		// we need to get selected categories according to the current activity type in the object selectedCategories in searchParams
		let currentActivitySelectedCategories = this.handleCurrentActivitySelectedCategories();

		return this.state.searchParams.selectedCategories
			? currentActivitySelectedCategories.includes(elem.term_id)
			: false;
	};

	/**
	 * allows us to get the selected categories only for the current activity type
	 * since we have an object into selectedCategories with the 4 activity types as key
	 */
	private handleCurrentActivitySelectedCategories = () => {
		let selection: any = [];

		selection = Object.entries(this.state.searchParams.selectedCategories)
			.map((entry) => {
				return { id: entry[0], value: entry[1] };
			})
			.find((item) => {
				return item.id === this.state.currentActivityType;
			});

		selection = selection.value;

		return selection;
	};

	// STATE MANAGEMENT
	private selectChildrenFriendly = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, childrenFriendly: true },
		});

	private selectNotChildrenFriendly = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, childrenFriendly: false },
		});

	private onGetPosition = ({ coords }: any) => {
		const position = {
			latitude: coords.latitude,
			longitude: coords.longitude,
		};

		this.setState({
			searchParams: { ...this.state.searchParams, position, isGeolocalized: true },
		});
	};

	private onErrorPosition = (error: any) => {
		this.setState(
			{
				searchParams: { ...this.state.searchParams, isGeolocalized: false },
			},
			() => {
				if (error.code !== error.PERMISSION_DENIED) {
					alert('Impossible de vous géolocaliser');
				}
			},
		);
	};

	private selectNotGeolocalize = () =>
		this.setState({
			searchParams: { ...this.state.searchParams, isGeolocalized: false },
		});

	private cityOnChangeRadio = (e: any) => {
		const { cities, selectedCitiesIndex } = this.state.searchParams;

		const selectedCityId = e.target?.dataset?.index
			? parseInt(e.target?.dataset?.index)
			: e.value.id;
		const selectedCityName =
			e.target?.dataset?.city ?? e.value?.nom ?? e.target?.dataset?.item ?? '';

		if (!selectedCitiesIndex && !cities) {
			const newCity = this.state.suggestedCities.find((city) => city.id === selectedCityId);

			if (newCity) {
				this.setState({
					searchParams: {
						...this.state.searchParams,
						cities: [newCity],
						selectedCitiesIndex: [newCity.id],
					},
				});
			}
		} else if (selectedCitiesIndex && cities) {
			if (!selectedCitiesIndex.includes(selectedCityId)) {
				const newCity = this.state.suggestedCities.find(
					(city) => city.id === selectedCityId,
				);

				if (newCity) {
					let newSelectedCitiesIndex = selectedCitiesIndex;
					newSelectedCitiesIndex.push(selectedCityId);
					let newCities = cities;
					newCities.push(newCity);

					this.setState({
						searchParams: {
							...this.state.searchParams,
							cities: newCities,
							selectedCitiesIndex: newSelectedCitiesIndex,
						},
					});
				}
			} else {
				const newSelectedCitiesIndex = selectedCitiesIndex.filter(
					(city) => city !== selectedCityId,
				);
				const newCities = cities.filter((city) => city.nom !== selectedCityName);

				this.setState({
					searchParams: {
						...this.state.searchParams,
						cities: newCities,
						selectedCitiesIndex: newSelectedCitiesIndex,
					},
				});
			}
		} else {
			this.setState({
				searchParams: {
					...this.state.searchParams,
					cities: undefined,
					selectedCitiesIndex: undefined,
				},
			});
		}
	};

	/**
	 * update the state to keep only the current activity categories into selectedCategories
	 * update Activities state and close the search panel
	 */
	private onSearchValidation = () => {
		const { currentActivityType } = this.state;

		let currentActivitySelectedCategories = this.handleCurrentActivitySelectedCategories();

		this.setState(
			{
				searchParams: {
					...this.state.searchParams,
					selectedCategories: {
						asc: [],
						deg: [],
						loi: [],
						pcu: [],
						[currentActivityType]: currentActivitySelectedCategories,
					},
				},
			},
			() => {
				this.props.setSearchState(this.state.searchParams, this.state.currentActivityType);
				this.props.onClose();
			},
		);
	};

	// PARTIAL RENDERS
	private renderActivityTypeSelection = () => {
		const { activityTypeList } = this.state;

		return (
			<div id="activity-type__list">
				{activityTypeList.map((activity) => {
					return (
						<ActivityTypeButton
							key={activity.id}
							id={activity.id}
							isActive={activity.isActive}
							name={activity.name}
							picto={activity.picto}
							onClick={() => this.activityTypeSelection(activity.id)}
						/>
					);
				})}
			</div>
		);
	};

	private renderSearchPanelTitle = () => {
		return (
			<div id="search-params-header">
				<span id="header-title">FAIRE UNE RECHERCHE</span>

				<img src="/close.svg" alt="Fermer" onClick={this.props.onClose} />
			</div>
		);
	};

	private renderCategorySelector = () => {
		return (
			<CategorySelector
				categories={this.state.categories}
				getCategoryOnClick={this.getCategoryOnClick}
				isSelectedCategory={this.isSelectedCategory}
				sectionTitleMobile={`Catégories d'activités`}
				sectionTitleDesktop={`Sélectionner une activité`}
			/>
		);
	};

	private renderChildrenOption = () => {
		return (
			<div id="is-children-friendly" className="search-params-section">
				<div className="title">Adapté aux enfants ?</div>

				<div className="select">
					<div
						onClick={this.selectChildrenFriendly}
						className={!!this.state.searchParams.childrenFriendly ? 'selected' : ''}>
						OUI
					</div>

					<div
						onClick={this.selectNotChildrenFriendly}
						className={!this.state.searchParams.childrenFriendly ? 'selected' : ''}>
						NON
					</div>
				</div>
			</div>
		);
	};

	private renderLocationSelector = () => {
		const { searchParams, suggestedCities } = this.state;

		return (
			<LocationSelector
				cityOnChange={this.cityOnChangeRadio}
				cityOnChangeRadio={this.cityOnChangeRadio}
				onErrorPosition={this.onErrorPosition}
				onGetPosition={this.onGetPosition}
				searchParams={searchParams}
				selectNotGeolocalize={this.selectNotGeolocalize}
				suggestedCities={suggestedCities}
			/>
		);
	};

	private renderSearchValidationMobile = () => {
		return (
			<div id="validate">
				<span onClick={this.onSearchValidation}>VALIDER LA RECHERCHE</span>
			</div>
		);
	};

	private renderSearchValidationDesktop = () => {
		return (
			<div id="validate" className="display-on-desktop desktop-validation__right">
				<div className="validate__buttons">
					<button className="validate__btn" onClick={this.onSearchValidation}>
						Valider
					</button>

					<button className="validate__btn" onClick={this.props.onClose}>
						Annuler
					</button>
				</div>

				<img src="/close.svg" alt="Fermer" onClick={this.props.onClose} />
			</div>
		);
	};

	// COMPONENT RENDER
	public render() {
		const { areCategoriesLoading } = this.state;

		if (areCategoriesLoading) {
			return (
				<div
					id="search-params-container"
					className={this.props.displayed ? 'show' : 'hide'}>
					<div id="search-params">
						<Loading />
					</div>
				</div>
			);
		}

		return (
			<div id="search-params-container" className={this.props.displayed ? 'show' : 'hide'}>
				<div id="search-params">
					<div className="display-on-mobile">
						{this.renderSearchPanelTitle()}

						{this.renderActivityTypeSelection()}

						{this.renderCategorySelector()}

						{this.renderLocationSelector()}

						{this.renderChildrenOption()}

						{this.renderSearchValidationMobile()}
					</div>

					<div className="display-on-desktop">
						{this.renderActivityTypeSelection()}

						<div className="search-param-desktop">
							{this.renderCategorySelector()}

							<div className="desktop-middle-selectors">
								<div className="other-options">
									<div className="other-options__title">Autres options :</div>

									{this.renderChildrenOption()}
								</div>
							</div>

							<div className="desktop-right-selectors">
								{this.renderLocationSelector()}
							</div>
						</div>

						<div className="desktop-validation flex-row">
							<div className="desktop-validation__left flex-1/flex-2>1500" />

							<div className="desktop-validation__middle flex-1" />

							{this.renderSearchValidationDesktop()}
						</div>
					</div>
				</div>

				<div id="params-blurring" onClick={this.props.onClose} />
			</div>
		);
	}
}
