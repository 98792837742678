import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Loading from 'atoms/Loading';
import { DataContext } from 'utils/DataStore';
import { decodeHTMLEntities } from 'utils/TextUtils';
import URLTools from 'utils/URL';

import './Widget.scss';

export interface Props {
	history: History<any>;
}

type State = {
	error: boolean;
	loading: boolean;
	settings?: any;
	widgetPage?: Array<any>;
};

export default class Widget extends Component<Props, State> {
	static contextType = DataContext;

	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
		};
	}

	public componentDidMount() {
		this.setState({ settings: this.context.settings });
		this.fetchWidgetPage();

		ReactGA.pageview(`/Widget`);
	}

	/**
	 * text to /widget
	 */
	private fetchWidgetPage = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(URLTools.getApiURL(`/wp-json/cornouaille/v1/pages?slug=widget`), {
				method: 'GET',
			})
				.then((response: any) => response.json())
				.then(this.onWidgetPageFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	private onWidgetPageFetched = (data: any) => {
		this.setState({ loading: false, widgetPage: data });
	};

	public render() {
		let title: string =
			(this.state.settings && this.state.settings.titre) ||
			"L'agenda des événements festifs et culturels en Cornouaille";
		const description = (this.state.settings && this.state.settings.description) || '';
		// const urlIframe = `https://api.${window.location.hostname}/?widget=creer`;
		const urlIframe = `${URLTools.getApiURL('/?widget=creer')}`;
		const style = {
			display: 'block',
			minHeight: '600px',
			minWidth: '500px',
			height: '99%',
			width: '100%',
			margin: '50px auto',
			border: '0',
			overflow: 'scroll',
		};

		return (
			<div id="widget">
				<Helmet>
					<title>{decodeHTMLEntities(title)}</title>
					<meta property="og:title" content="Cornouaille Animations" />
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
					<meta name="description" content={description} />
				</Helmet>

				<div id="location">Acceuil / Les animations sur votre site</div>

				<div id="widget-header">
					<img
						alt=""
						src="/right-arrow.svg"
						className="header-arrow"
						aria-hidden="true"
						role="img"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>MON WIDGET</span>

						<img alt="" src="/filled-star.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="description">
					<div id="description-title">
						<img alt="" src="/picto-lettrine.svg" aria-hidden="true" role="img"></img>

						<span>Comment ça marche ?</span>
					</div>

					<div id="description-content">
						{this.state.loading && <Loading></Loading>}

						{!this.state.loading && this.state.widgetPage && (
							<div id="market-list">
								{this.state.widgetPage.map((item, index) => (
									<div
										key={index}
										dangerouslySetInnerHTML={{
											__html: item.content.rendered,
										}}></div>
								))}
							</div>
						)}
					</div>
				</div>

				<div className="widget-selector">
					<iframe src={urlIframe} style={style} title="widget"></iframe>
				</div>
			</div>
		);
	}
}
