import React, { Component } from 'react';

type Props = {
	currentSelection: any[];
	onDeleteSelection?(e: any): void;
	suggestions: any[];
};

class MultipleSelection extends Component<Props> {
	render() {
		const { currentSelection, onDeleteSelection, suggestions } = this.props;

		const currentSelectionType =
			!!currentSelection && !!currentSelection.length ? typeof currentSelection[0] : '';
		let currentSelectionList = currentSelection;
		if (!!currentSelection && !!currentSelection.length && currentSelectionType === 'number') {
			currentSelectionList = suggestions.reduce((acc, item) => {
				// ! @ todo => voir dans le cas où currentSelectionList retourne des strings
				if (typeof item === 'object' && !!currentSelection.includes(item.id)) {
					const newAcc = [...acc, item];

					return (acc = newAcc);
				}

				return acc;
			}, []);
		}

		return (
			<>
				<div className="selection_list">
					{currentSelectionList.map((item) => {
						// ! @ todo => voir dans le cas où currentSelectionList retourne des strings
						if (typeof item === 'object') {
							return (
								<div key={item.id} className="selection_item">
									{item.nom}
									<img
										src="/picto-suppr.svg"
										alt=""
										className="delete-icon"
										aria-hidden="true"
										role="img"
										data-index={item.id}
										data-item={item.nom}
										onClick={onDeleteSelection}
									/>
								</div>
							);
						}
					})}
				</div>
			</>
		);
	}
}

export default MultipleSelection;
