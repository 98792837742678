// npm imports
import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// local imports
import Loading from 'atoms/Loading';
import AnimationMap from 'components/AnimationMap';
import Carousel from 'components/Carousel';
import SingleItemContent from 'components/SingleItemContent';
import Suggestion from 'components/Suggestions';
import UsefulInfos from 'components/UsefulInfos';
import { fetchCities } from 'utils/DataStore';
import URLTools from 'utils/URL';
import { getActivitySelection, setActivitySelection } from 'utils/Selection';

import './activity.scss';

// types
type Props = {
	history: History<any>;
	match: any;
};

type State = {
	activity?: any;
	isSelected: boolean;
	loading: boolean;
	activityError: boolean;

	activitiesSuggestion?: Array<any>;
	suggestionLoading: boolean;
	suggestionError: boolean;
};

const HILIGHTS_ID = 0;
const CHILDREN_FRIENDLY_ID = 583;

// component
export default class Activity extends Component<Props, State> {
    private toPrint!: any;

	public constructor(props: Props) {
        super(props);
        
		this.state = {
			activityError: false,
			suggestionError: false,
			isSelected: false,
			loading: false,
			suggestionLoading: false,
        };
    };

    // life cycle
	public componentDidMount() {
		this.fetchActivity();

		ReactGA.pageview(`/activites/${this.props.match.params.id}`);
	};

	public componentDidUpdate(prevProps: Props) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.fetchActivity();

			ReactGA.pageview(`/activites/${this.props.match.params.id}`);
		};
	};
    
    // data
	private fetchSuggestions = (data: any) => {
		const onDataFetched = (activitiesSuggestion: any) =>
			this.setState({
				suggestionLoading: false,
				activitiesSuggestion,
			});

		fetchCities().then((cities) => {
			this.setState({ suggestionLoading: true, suggestionError: false }, () => {
				let citiesToSearch;
				try {
					citiesToSearch = cities.pays.find((elem) =>
						elem.communes.includes(data.commune),
					).communes;
				} catch (e) {
					citiesToSearch = [data.commune];
				};

				fetch(
					URLTools.getApiURL(
						`/wp-json/cornouaille/v1/activites?commune=${citiesToSearch.join(
							',',
						)}&per_page=4&ordre=ASC`,
					),
					{ method: 'GET' },
				)
					.then((response: any) => response.json())
					.then(onDataFetched)
					.catch((e: any) => {
						this.setState({ suggestionError: true });
					});
			});
		});
	};

	private fetchActivity = () => {
		const idParam = this.props.match.params.id;
		let id;

		if (typeof idParam === 'number') {
			id = idParam;
		} else if (typeof idParam === 'string' && idParam.indexOf('-') !== -1) {
			id = parseInt(idParam.substr(idParam.lastIndexOf('-') + 1), 10);
		} else {
			id = parseInt(idParam, 10);
		};

		const onDataFetched = (data: any) => {
			let selected = false;
			const stored = getActivitySelection();
			if (stored) {
				for (const elem of stored) {
					if (elem === data.id) {
						selected = true;
						break;
					};
				};
			};

			this.setState({ loading: false, activity: data, isSelected: selected }, () => {
				this.fetchSuggestions(data);
				window.scrollTo(0, 0);
			});
		};

		this.setState({ loading: true }, () => {
			fetch(URLTools.getApiURL(`/wp-json/cornouaille/v1/activites/${id}`), { method: 'GET' })
				.then((response: any) => response.json())
				.then(onDataFetched)
				.catch((e: any) => this.setState({ activityError: true }));
		});
	};
    
    // useful infos
	private selectionOnClick = () => {
        const storedArray: Array<number> = getActivitySelection() || [];

		if (!this.state.isSelected) {
			storedArray.push(this.state.activity.id);
		} else {
			const index = storedArray.indexOf(this.state.activity.id);
			storedArray.splice(index, index + 1);
		};

		setActivitySelection(storedArray);
		this.setState({ isSelected: !this.state.isSelected });
	};

    // map
	private goToGPS = () => () => {
		const { latitude, longitude } = this.state.activity;
		window.open(`https://maps.google.com/?q=${latitude},${longitude}&z=8`);
    };
    
    // suggestions
	private getGoToActivity = (id: number) => () => this.props.history.push(`/activities/${id}`);

    // component render
	public render() {
		const { history } = this.props;
		const { activity, activitiesSuggestion, isSelected, loading, suggestionLoading } = this.state;

		if (loading || !activity) {
			return <Loading />;
		};

		const { latitude, longitude } = activity;

		return (
			<div id="activity-page" ref={(elem) => (this.toPrint = elem)}>
				<Helmet>
					<title>Cornouaille Activités {activity && `- ${activity.nom_offre}`}</title>
					<meta name="description" content={activity && activity.description} />
					<meta property="og:title" content={activity && activity.nom_offre} />
					<meta property="og:description" content={activity && activity.description} />
					<meta property="og:image" content={activity && activity.featured_media.src} />
				</Helmet>

                <SingleItemContent
                    goBackOnHistory={history.goBack}
                    hilightsId={HILIGHTS_ID}
                    item={activity}
                />

                <Carousel item={activity}/>

				<div id="activity-bottom-container">
                    <UsefulInfos
                        childFriendlyId={CHILDREN_FRIENDLY_ID}
                        isSelected={isSelected}
                        item={activity}
                        itemName={'activité'}
                        selectionOnClick={this.selectionOnClick}
                        toPrint={this.toPrint}
                    />

					<div id="animation-page-map">
						<div id="animation-map-title">
							<span>LOCALISER L'ACTIVITE</span>
							<img alt="" src="/picto-pin_black.svg" aria-hidden="true" role="img" />
						</div>

						<AnimationMap
							center={[parseFloat(latitude), parseFloat(longitude)]}
							animations={[activity]}
							zoom={14}
							animationPopUpMessage="S'Y RENDRE"
							animationPopUpOnClick={this.goToGPS}
						/>
					</div>
				</div>

                {/* Suggestion */}
				{suggestionLoading && <Loading />}

                {
                    !suggestionLoading &&
                    activity &&
                    activitiesSuggestion &&
                    activitiesSuggestion.length > 0 &&
                    (
                        <Suggestion
                            item={activity}
                        	itemType={'activité'}
                            suggestion={activitiesSuggestion}
                            getGoToItem={this.getGoToActivity}
                        />
                    )
                }
			</div>
		);
	};
};
