import React from 'react';

import Autocomplete from 'atoms/Autocomplete';
import type { SearchParams } from 'types/searchParamsType';

// import './locationSelector.scss';

interface Props {
	searchParams: Partial<SearchParams>;
	suggestedCities: Array<{ nom: string; communes: Array<string>; id: number }>;

	cityOnChange: (e: any) => void;
	cityOnChangeRadio: (e: any) => void;
	onErrorPosition: (error: any) => void;
	onGetPosition: ({ coords }: any) => void;
	selectNotGeolocalize: () => void;
}

type State = {};

function autocompleteFilter(
	search: string,
	suggestions: Array<{ nom: string; communes: Array<string> }>,
): Array<{ nom: string; communes: Array<string> }> {
	let newSuggestions = suggestions;

	if (search && search.length > 0) {
		newSuggestions = suggestions.filter((elem) =>
			elem.nom.toLowerCase().startsWith(search.toLowerCase()),
		);
	}

	return newSuggestions;
}

function suggestionToString(suggestion: {
	nom: string;
	communes: Array<string>;
	id: number;
}): string {
	return suggestion.nom;
}

export default class LocationSelector extends React.Component<Props, State> {
	private selectGeolocalize = () => {
		const { onErrorPosition, onGetPosition } = this.props;

		if (window.navigator && window.navigator.geolocation) {
			const location = window.navigator.geolocation;

			if (location) {
				location.getCurrentPosition(onGetPosition, onErrorPosition);
			}
		}
	};

	render() {
		const {
			cityOnChange,
			cityOnChangeRadio,
			searchParams,
			searchParams: { selectedCityIndex, selectedCitiesIndex },
			selectNotGeolocalize,
			suggestedCities,
		} = this.props;

		return (
			<div className="other-options">
				<div className="other-options__title display-on-desktop">Localisation</div>
				<div id="location-param" className="search-params-section">
					<div className="title">ME GÉOLOCALISER ?</div>

					{window.navigator && window.navigator.geolocation && (
						<div className="select">
							<div
								onClick={this.selectGeolocalize}
								className={!!searchParams.isGeolocalized ? 'selected' : ''}>
								OUI
							</div>

							<div
								onClick={selectNotGeolocalize}
								className={!searchParams.isGeolocalized ? 'selected' : ''}>
								NON
							</div>
						</div>
					)}

					{!searchParams.isGeolocalized && (
						<>
							<Autocomplete
								name="city"
								type="text"
								label="Territoire ou commune..."
								onDeleteSelection={cityOnChange}
								onResult={cityOnChange}
								values={searchParams.selectedCitiesIndex}
								suggestions={suggestedCities}
								filterSearch={autocompleteFilter}
								suggestionToString={suggestionToString}
								className="display-on-mobile"
								isMultiple
							/>

							<div className="display-on-desktop title-cities-container">
								<div className="title">Territoire ou commune</div>
								<div className="cities-container">
									{suggestedCities.map((city) => (
										<div
											key={city.id}
											className="city-selection"
											data-index={city.id}>
											<input
												id={`input-city-${city.id}`}
												type="checkbox"
												checked={
													selectedCityIndex === city.id || // ! sera à supprimer quand plus que tableau
													selectedCitiesIndex?.includes(city.id)
												}
												onChange={cityOnChangeRadio}
												data-index={city.id}
												data-city={city.nom}
											/>

											<label htmlFor={`input-city-${city.id}`}>
												{city.nom}
											</label>
										</div>
									))}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}
