import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Animations from 'components/Animations';
import Highlights from 'components/Highlights';
import MaCornouaille from 'components/MaCornouaille';
import WithToggleSearch from 'pages/HocPages/withToggleSearch';
import { DataConsumer, DataContext } from 'utils/DataStore';
import { decodeHTMLEntities } from 'utils/TextUtils';
import URLTools from 'utils/URL';

import './Home.scss';

export interface Props {
	displaySearch: boolean
    history: History<any>;

    closeSearch: () => void;
    openSearch: () => void;
    toggleSearch: () => void;
}

type State = {
	settings?: any;
};

class HomePage extends Component<Props, State> {
	static contextType = DataContext;
	
	public constructor(props: Props) {
		super(props);

		this.state = {};
	}

	public componentDidMount() {
		this.setState({ settings: this.context.settings });

		ReactGA.pageview(`/homePage`);
 	};

	public render() {
		const { settings } = this.state;
		let title: string =
			(this.state.settings && this.state.settings.titre) ||
			"L'agenda des événements festifs et culturels en Cornouaille";
		const description = (this.state.settings && this.state.settings.description) || 'L’agenda des événements festifs et culturels en Cornouaille';

		return (
			<div id="home" className={ this.props.displaySearch ? 'open' : 'close' }>
				<Helmet>
					<title>{decodeHTMLEntities(title)}</title>
					<meta property="og:title" content="Cornouaille Animations" />
					<meta
						property="og:description"
						content={description}
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
					<meta name="description" content={description} />
				</Helmet>

				<div id="home-content">
					<div id="home-content-container">
						<div id="title-container">
							<div id="title">
								<div id="title-text">
									<span>
										<img
											src="/picto-lettrine.svg"
											className="lettrine"
											alt=""
											aria-hidden="true"
											role="img"
										/>

										<span dangerouslySetInnerHTML={{ __html: title }}></span>
									</span>
								</div>

								<img
									src="/logo-DQCB-carte.svg"
									className="logo"
									alt=""
									aria-hidden="true"
									role="img"
								/>
							</div>

							<div id="subtitle" dangerouslySetInnerHTML={{ __html: description }} />
						</div>

						<DataConsumer>
							{(store) => (
								<>
									{
										settings && settings.menu_temps_forts && (
											<Highlights
												highlights={store.highlights}
												history={this.props.history}
												url={URLTools.getApiURL(
													`/wp-json/cornouaille/v1/animations?temps_forts&per_page=6&ordre=ASC`,
												)}
											/>
										)
									}
									<Animations
										parentScrollContainer="#home-content-container"
										history={this.props.history}
										url={URLTools.getApiURL(
											`/wp-json/cornouaille/v1/animations`,
										)}
										animations={store.animations}
										displaySearchParams={this.props.displaySearch}
										toggleSearch={this.props.toggleSearch}
										openSearch={this.props.openSearch}
										closeSearch={this.props.closeSearch}
									/>
								</>
							)}
						</DataConsumer>
					</div>

					<MaCornouaille history={this.props.history} />
				</div>
			</div>
		);
	}
}

export default WithToggleSearch(HomePage);