import React, { Component } from 'react';

const VALUE_TO_GO_TO_LAST_SCROLL_LINE = 1000;
const MAX_HEIGHT = 300;
const ACTION_HEIGHT = 60;

export interface IDropDownProps {
	actionElements: Array<JSX.Element>;
	elementHeight?: number;
	elements: Array<string>;
	focusedResult: number;
	isLoading: boolean;
	maxHeight?: number;
	remainingResultsNumber?: number;
	width?: number;

	filterByCategory?(
		data: Array<{ wrapped: JSX.Element; result: string }>,
	): { height: number; results: Array<JSX.Element> };
	onElementSelected(e: any): void;
	suggestionToString(suggestion: any): string;
}

type State = {
	elementHeight: number;
	lastNbResults: number;
	height: number;
};

const RESULT_SIZE = 60;

export default class DropDown extends Component<IDropDownProps, State> {
	private scrollList?: HTMLDivElement | null = null;

	constructor(props: IDropDownProps) {
		super(props);

		this.state = {
			elementHeight: props.elementHeight || RESULT_SIZE,
			height: 0,
			lastNbResults: 0,
		};
	}

	private getHeight(height: number) {
		const maxHeight = this.props.maxHeight ? this.props.maxHeight : MAX_HEIGHT;

		return isNaN(height) ? maxHeight : height > maxHeight ? maxHeight : height;
	}

	private scrollTo(resultIndex: number) {
		if (!this.scrollList) {
			return;
		}

		const elementPosition = this.scrollList.children[resultIndex].getBoundingClientRect();
		const dropdownPosition = this.scrollList.getBoundingClientRect();
		const currentTop = this.scrollList.scrollTop;
		const maxHeight = this.props.maxHeight ? this.props.maxHeight : MAX_HEIGHT;

		if (elementPosition.bottom - currentTop > dropdownPosition.bottom) {
			this.scrollList.scrollTop = elementPosition.top - currentTop - dropdownPosition.top;
		} else if (elementPosition.top < dropdownPosition.top) {
			this.scrollList.scrollTop = Math.max(0, currentTop - maxHeight);
		}
	}

	public componentDidUpdate() {
		if (this.scrollList && this.scrollList.children[this.props.focusedResult]) {
			if (this.props.focusedResult > -1) {
				this.scrollTo(this.props.focusedResult);
			}

			// Scroll down if we are at the last line of list (to show the remaining results line)
			if (this.props.focusedResult === this.props.elements.length - 1) {
				this.scrollList.scrollTop += VALUE_TO_GO_TO_LAST_SCROLL_LINE;
			}
		}

		if (this.props.elements.length !== this.state.lastNbResults) {
			this.setState({
				height: this.state.elementHeight * this.props.elements.length,
				lastNbResults: this.props.elements.length,
			});
		}
	}

	public render() {
		const {
			isLoading,
			elements,
			remainingResultsNumber,
			onElementSelected,
			actionElements,
		} = this.props;
		let loadingElement: JSX.Element | null = null;

		if (isLoading) {
			loadingElement = <div className="autocomplete-loading" />;
		}

		if (!elements.length && !actionElements.length) {
			return <div />;
		}

		let resultsDisplayed = elements.map((result, i) => (
			<div
				style={{ height: this.props.elementHeight || ACTION_HEIGHT }}
				onMouseDown={onElementSelected}
				className={`click-handler ${
					i + actionElements.length === this.props.focusedResult ? 'focused' : ''
				}`}
				data-index={i + actionElements.length}
				key={i + actionElements.length}>
				<div className="autocomplete-result">{this.props.suggestionToString(result)}</div>
			</div>
		));

		let { height } = this.state;

		if (this.props.filterByCategory) {
			const filteredData = this.props.filterByCategory(
				elements.map((e, i) => ({ wrapped: resultsDisplayed[i], result: e })),
			);

			height = filteredData.height;
			resultsDisplayed = filteredData.results;
		}

		height = this.getHeight(height + actionElements.length * ACTION_HEIGHT);

		return (
			<div style={{ position: 'relative' }}>
				<div className="autocomplete-list" style={{ height }}>
					<div
						className="scroll-container full-height"
						ref={(scrollDiv) => {
							this.scrollList = scrollDiv;
						}}>
						{actionElements.map((e, i) => (
							<div
								key={i}
								data-index={i}
								onMouseDown={onElementSelected}
								className={`click-handler ${
									this.props.focusedResult === i ? 'focused' : ''
								}`}>
								{e}
							</div>
						))}
						{resultsDisplayed}
						{remainingResultsNumber && remainingResultsNumber > 0 && (
							<div className="nb-results">
								{`${remainingResultsNumber} résultats restants`}
							</div>
						)}
					</div>
					{loadingElement}
				</div>
			</div>
		);
	}
}

interface CategoryProps {
	children: JSX.Element | string;
}

export function Category(props: CategoryProps) {
	return <div className="autocomplete-list-category">{props.children}</div>;
}
