import React, { Component } from 'react';
import { History } from 'history';

import Loading from 'atoms/Loading';
import ItemPreview from 'components/ItemPreview';
import { FULL_SELECTION } from 'utils/Constants';
import { fetchHighlights } from 'utils/DataStore';

import './highlights.scss';
import Button from 'atoms/Button';

type Props = {
	history: History<any>;
	highlights: any;
	url: string;
};

type State = {
	loading: boolean;
	posts: Array<any>;
	scrollPosition: number;
	fullSelection: boolean;
};

export default class Hilights extends Component<Props, State> {
	private scroller?: HTMLDivElement | null;

	public constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			posts: [],
			scrollPosition: 0,
			fullSelection: false,
		};
	}

	private onDataFetched = (data: Array<any>) =>
		this.setState({ posts: this.state.posts.concat(data), loading: false });

	private fetchData = () => {
		this.setState({ loading: true }, () => {
			fetchHighlights(this.props.url, { method: 'GET', withCache: true }).then(
				this.onDataFetched,
			);
		});
	};

	private getAnimationOnClick = (id: number) => () =>
		this.props.history.push(`/animations/${id}`);

	private gotToHilights = () => this.props.history.push('/highlights');

	private onScroll = (e: any) => {
		const maxScroll = e.target.scrollWidth - e.target.clientWidth;

		this.setState({ scrollPosition: e.target.scrollLeft / maxScroll });
	};

	private getGoToScroll = (target: 'beginning' | 'middle' | 'end') => () => {
		if (this.scroller) {
			let left;

			switch (target) {
				case 'beginning':
					left = 0;
					break;
				case 'middle':
					left = this.scroller.scrollWidth / 2 - this.scroller.clientWidth / 2;
					break;
				case 'end':
					left = this.scroller.scrollWidth;
					break;
			}

			this.scroller.scrollTo({ left, behavior: 'smooth' });
		}
	};

	public componentDidMount() {
		setTimeout(() => {
			if (this.props.highlights) {
				return this.onDataFetched(this.props.highlights);
			}

			this.fetchData();
			this.setFullSelection();
		}, 0);
	}

	private setFullSelection = () => {
		let storedArray: any = getSelection() || [];

		if (storedArray.length < FULL_SELECTION) {
			this.setState({ fullSelection: false });
			return false;
		} else {
			this.setState({ fullSelection: true });
			return true;
		}
	};

	public render() {
		let { loading, posts, fullSelection } = this.state;
		posts = posts.filter((elem) => !!elem);

		if (posts.length < 1 && !loading) {
			return (
				<div id="hilights">
					<div className="hilights-animations-container">
						<span>AUCUN TEMPS FORTS</span>
					</div>
				</div>
			);
		}

		return (
			<div id="hilights">
				<div className="hilights-animations-container">
					{loading && <Loading />}

					<div className="title-container">
						<div className="title">
							<span style={{ marginTop: '2px' }}>TEMPS FORTS</span>
							<img alt="" src="/heart.svg" aria-hidden="true" role="img" />
						</div>
					</div>

					<div
						className="hilights-animations-scroll-container"
						onScroll={this.onScroll}
						ref={(elem) => (this.scroller = elem)}>
						{posts.map(
							(elem, index) =>
								elem && (
									<ItemPreview
										data={elem}
										key={index}
										onClick={this.getAnimationOnClick(elem.id)}
										fullSelection={fullSelection}
										setFullSelection={this.setFullSelection}
									/>
								),
						)}
					</div>

					<div className="center-container">
						<div className="dots-container">
							<div
								className="dot-one dot"
								onClick={this.getGoToScroll('beginning')}
								style={{ opacity: 1 - 0.8 * this.state.scrollPosition }}
							/>
							<div
								className="dot-two dot"
								onClick={this.getGoToScroll('middle')}
								style={{
									opacity: 1 - Math.abs(this.state.scrollPosition - 0.5) * 0.8,
								}}
							/>
							<div
								className="dot-three dot"
								onClick={this.getGoToScroll('end')}
								style={{
									opacity: Math.min(0.2 + this.state.scrollPosition * 0.8, 1),
								}}
							/>
						</div>

						<Button
							className="hilights-button"
							color="orange"
							onClick={this.gotToHilights}>
							<span>TOUS LES TEMPS FORTS</span>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
