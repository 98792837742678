import React from 'react';

import URL from 'utils/URL';

const Store: any = {};
export const DataContext = React.createContext(Store);

const keys = [
	{
		name: 'activities',
		duration: 6 * 60 * 60 * 1000,
	},
	{
		name: 'activityTypeCategories',
		duration: 6 * 60 * 60 * 1000,
	},
	{
		name: 'animations',
		duration: 6 * 60 * 60 * 1000,
	},
	{
		name: 'categories',
		duration: 24 * 60 * 60 * 1000,
	},
	{
		name: 'cities',
		duration: 6 * 60 * 60 * 1000,
	},
	{
		name: 'highlights',
		duration: 6 * 60 * 60 * 1000,
	},
	{
		name: 'settings',
		duration: 6 * 60 * 60 * 1000,
	},
];

keys.forEach((keyObject) => {
	const date = parseInt(sessionStorage.getItem(`${keyObject.name}-date`) || '0', 10);

	if (date && !isNaN(date) && Date.now() < date + keyObject.duration) {
		try {
			const obj = JSON.parse(sessionStorage.getItem(keyObject.name) || '{}');

			Store[keyObject.name] = obj;
		} catch (e) {
			return;
		}
	}
});

function _fetch(url: string, options: any, storeKey: string) {
	return fetch(url, options)
		.then((response: any) => response.text())
		.then((r) => {
			let obj = JSON.parse(r);

			if (!options.withCache) {
				return obj;
			}

			if (options.concatWithCache) {
				if (Array.isArray(Store[storeKey])) {
					Store[storeKey] = Store[storeKey].concat(obj);

					r = JSON.stringify(Store[storeKey]);
				}
			} else {
				Store[storeKey] = obj;
			}

			sessionStorage.setItem(storeKey, r);
			sessionStorage.setItem(`${storeKey}-date`, String(Date.now()));

			if (options.returnAllData) {
				return Store[storeKey];
			}

			return obj;
		})
		.catch((e: any) => console.error(e));
}

export const DataConsumer = DataContext.Consumer;
export const DataProvider = (props: any) => (
	<DataContext.Provider value={Store}>{props.children}</DataContext.Provider>
);

export const fetchActivities = (url: string, options: any) => _fetch(url, options, 'activities');
export const fetchAnimations = (url: string, options: any) => _fetch(url, options, 'animations');
export const fetchCategories = (url: string, options: any) => _fetch(url, options, 'categories');
export const fetchActivityTypeCategories = (url: string, options: any) => _fetch(url, options, `activityTypeCategories`);
export const fetchCities = () =>
	_fetch(URL.getApiURL('/wp-json/cornouaille/v1/settings'), { withCache: true }, 'cities');
export const fetchHighlights = (url: string, options: any) => _fetch(url, options, 'highlights');
export const fetchAppSettings = (url: string, options: any) => _fetch(url, options, 'settings');

