import React from 'react';

import './maCornouaillePreview.scss';

type Props = {
	data: any;
};

function getGoTo(link: string) {
	return () => window.open(link);
}

const MaCornouaillePreview: React.FC<Props> = ({ data }) => {
	let imageSrc;
	try {
		imageSrc = data._embedded['wp:featuredmedia'][0].source_url || '/logo-bleu.png';
	} catch (e) {
		imageSrc = '/logo-bleu.png';
	}

	return (
		<div className="macornouaille-preview" onClick={getGoTo(data.link)}>
			<div className="item-hover-plus">
				<div className="circle-plus">
					<img alt="" src="/picto-plus.svg" aria-hidden="true" role="img" />
				</div>
			</div>

			<div
				className="macornouaille-preview-image"
				style={{ backgroundImage: `url(${imageSrc})` }}
			/>

			<div className="info-container">
				<div className="title" dangerouslySetInnerHTML={{ __html: data.title.rendered }} />

				<div className="authors">
					{data._embedded.author.map((elem: any, index: number) => (
						<div className="author-container" key={index}>
							<img
								src="/picto-auteur.svg"
								alt=""
								aria-hidden="true"
								role="img"
								className="author-image"
							/>

							<span key={index} className="author" onClick={getGoTo(elem.link)}>
								{elem.name}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default MaCornouaillePreview;
