export function getSelection(): Array<number> | void {
	const now = Date.now();
	const timeStr = localStorage.getItem('myAnimationSelectionTime');
	if (!timeStr) {
		return;
	}

	const time = parseInt(timeStr, 10);

	if (now - time > 100 * 60 * 60 * 24 * 2) {
		setSelection([]);

		return;
	}

	const selection = localStorage.getItem('myAnimationSelection');
	return selection && JSON.parse(selection);
}

export function setSelection(selection: Array<number>, setTime = true) {
	localStorage.setItem('myAnimationSelection', JSON.stringify(selection));

	if (setTime) {
		localStorage.setItem('myAnimationSelectionTime', Date.now().toString());
	}

	const event: any = new Event('selectionChanged');
	event.value = selection;
	document.dispatchEvent(event);
}

export function getActivitySelection(): Array<number> | void {
	const now = Date.now();
	const timeStr = localStorage.getItem('myActivitySelectionTime');
	if (!timeStr) {
		return;
	}

	const time = parseInt(timeStr, 10);

	if (now - time > 100 * 60 * 60 * 24 * 2) {
		setActivitySelection([]);

		return;
	}

	const selection = localStorage.getItem('myActivitySelection');

	return selection && JSON.parse(selection);
}

export function setActivitySelection(selection: Array<number>, setTime = true) {
	localStorage.setItem('myActivitySelection', JSON.stringify(selection));

	if (setTime) {
		localStorage.setItem('myActivitySelectionTime', Date.now().toString());
	}

	const event: any = new Event('selectionChanged');
	event.value = selection;
	document.dispatchEvent(event);
}
