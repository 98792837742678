let currentDomain = `https://api.${window.location.hostname}`;

if (currentDomain === 'https://api.localhost' || currentDomain === 'https://api.cornouaille-animations--fr.apsulis.fr') {
	currentDomain = 'https://api--cornouaille-animations--fr.apsulis.fr';
}

if (currentDomain.charAt(currentDomain.length - 1) !== '/') {
	currentDomain += '/';
}

export default {
	getApiURL: (route: string) => {
		if (route.charAt(0) === '/') {
			return `${currentDomain}${route.substring(1)}`;
		}

		return `${currentDomain}${route}`;
	},
};
