// npm imports
import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';

// local imports
import Button from 'atoms/Button';
import { getCalendar } from 'utils/DateUtils';
import { validateEmail } from 'utils/TextUtils';

import './usefulInfos.scss';

// types
type Props = {
    childFriendlyId: number;
    isSelected: boolean;
    item?: any;
    itemName:string,
    selectionOnClick: () => void;
    toPrint: any;
};

type State = {};

// component
export default class usefulInfos extends Component<Props, State> {
    private getDate(date: any): string {
        let res = '';
        const sauf = date.sauf && date.sauf.filter((elem: string) => !!elem);
    
        if (date.date_debut) {
            if (date.date_fin) {
                res = `du ${date.date_debut} au ${date.date_fin}`;
            } else {
                res = `le ${date.date_debut}`;
            };
        } else {
            res = `jusqu'au ${date.date_fin}`;
        };
    
        if (sauf && sauf.length > 0) {
            res = `${res} sauf ${date.sauf.length > 1 ? ' les' : ' le'}`;
    
            date.sauf.forEach((elem: any, index: number) => {
                res = `${res} ${elem}${index === date.sauf.length - 1 ? '' : ','}`;
            });
        };
    
        return res;
    };

    private getHours(date: any) {
        if (date.heure_debut) {
            if (date.heure_fin) {
                return `de ${date.heure_debut} à ${date.heure_fin}`;
            };
    
            return `à partir de ${date.heure_debut}`;
        };
    
        return `jusqu'à ${date.heure_fin}`;
    };

    private getOpenPhone = (number: string) => () => window.open(`tel:${number}`);

    private openMail = () => window.open(`mailto:${this.props.item.email}`);
    
    private isFree(data: any) {
        const isEntryFree = data.entree && data.entree.match(/(gratuit(e)?(s)?)|(free)/i);
    
        return isEntryFree && isEntryFree.length > 0;
    };

    private isChildFriendly(data: any) {
        if (!data || !data.categorie_id) {
            return false;
        };
    
        for (const catId of data.categorie_id) {
            if (catId === this.props.childFriendlyId) {
                return true;
            };
        };
    
        return false;
    };

    private isAccessible(data: any) {
        return data.mobilite && data.mobilite.toLowerCase() === 'oui';
    };
    
    private getGoTo = (link: string) => () => window.open(link);

	private downloadCalendar = () => {
		const { item, itemName} = this.props;

		const element = document.createElement('a');
		const file = new Blob([getCalendar(item)], { type: 'text/calendar' });
		element.href = URL.createObjectURL(file);
		element.download = `${itemName}-${item.id}.ics`;
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};

    render() {
        const { isSelected, item, selectionOnClick, toPrint } = this.props;
        const lieu_principal = item.lieu_principal ? item.lieu_principal.toUpperCase() : item.commune.toUpperCase();
        const date = item.dates_valides && item.dates_valides[0];
        
        return (
            <div id="item-infos">
                <div id="item-infos-title">INFOS PRATIQUES</div>

                <div id="item-info-content-container">
                    <div id="info-container">
                        <div className="item-info-content">
                            <img
                                alt=""
                                src="/picto-pin_white.svg"
                                aria-hidden="true"
                                role="img"
                            />

                            <div>
                                {(item.adresse1 || item.adresse1suite) && (
                                    <>
                                        {item.adresse1 || ''}{' '}
                                        {item.adresse1suite || ''}
                                        <br />
                                    </>
                                )}
                                {(item.adresse2 || item.adresse2suite) && (
                                    <>
                                        {item.adresse2 || ''}{' '}
                                        {item.adresse2suite || ''}
                                        <br />
                                    </>
                                )}
                                {lieu_principal} {item.code_postal ? `(${item.code_postal})` : ''}
                            </div>
                        </div>

                        {date && (date.date_debut || date.date_fin) && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-dates-white.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <div>{this.getDate(date)}</div>
                            </div>
                        )}

                        {date && (date.heure_debut || date.heure_fin) && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-horaires.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <div>{this.getHours(date)}</div>
                            </div>
                        )}

                        {item.telephone && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-tel.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <div
                                    className="link phone-number"
                                    onClick={this.getOpenPhone(item.telephone)}>
                                    {item.telephone}
                                </div>
                            </div>
                        )}

                        {item.portable && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-tel.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <div
                                    className="link phone-number"
                                    onClick={this.getOpenPhone(item.portable)}>
                                    {item.portable}
                                </div>
                            </div>
                        )}

                        {item.email && validateEmail(item.email) && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-mail.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <span className="link" onClick={this.openMail}>
                                    {item.email}
                                </span>
                            </div>
                        )}

                        {item.entree && (
                            <div className="item-info-content">
                                <img
                                    alt={this.isFree(item) ? 'Gratuit' : 'Payant'}
                                    src={
                                        this.isFree(item)
                                            ? '/picto-gratuit.svg'
                                            : '/picto-tarifs.svg'
                                    }
                                />

                                <div>
                                    {!this.isFree(item)
                                        ? item.entree
                                        : item.tarifs || 'Tarifs non disponible'}
                                </div>
                            </div>
                        )}

                        {this.isChildFriendly(item) && (
                            <div className="item-info-content">
                                <img
                                    alt="Pour les enfants"
                                    src="/picto-enfants.svg"
                                />

                                <div>POUR LES ENFANTS</div>
                            </div>
                        )}

                        {this.isAccessible(item) && (
                            <div className="item-info-content">
                                <img
                                    alt="Accessible PMR"
                                    src="/picto-accessibilite.svg"
                                />

                                <div>ACCESSIBLE PMR</div>
                            </div>
                        )}

                        {item.site_web && (
                            <div className="item-info-content">
                                <img
                                    alt=""
                                    src="/picto-lien.svg"
                                    aria-hidden="true"
                                    role="img"
                                />

                                <div>
                                    <span
                                        className="link"
                                        onClick={this.getGoTo(item.site_web)}>
                                        {item.site_web}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="button-container">
                        <Button
                            color={isSelected ? 'orange' : 'blue'}
                            onClick={selectionOnClick}>
                            <img
                                alt=""
                                src={
                                    isSelected
                                        ? '/filled-star.svg'
                                        : '/empty-star.svg'
                                }
                                aria-hidden="true"
                                role="img"
                            />
                            <span>
                                {isSelected
                                    ? 'SUPPRIMER DE MA SÉLECTION'
                                    : 'AJOUTER À MA SÉLECTION'}
                            </span>
                        </Button>

                        <Button onClick={this.downloadCalendar} color="blue">
                            <span>AJOUTER À MON AGENDA (.ICS)</span>
                        </Button>

                        <ReactToPrint
                            trigger={() => (
                                <Button className="print-button" color="orange">
                                    <span>IMPRIMER</span>

                                    <img
                                        alt=""
                                        src="/picto-imprimer.svg"
                                        aria-hidden="true"
                                        role="img"></img>
                                </Button>
                            )}
                            content={() => toPrint}
                        />
                    </div>
                </div>
            </div>
        );
    };
};