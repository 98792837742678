import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const ScrollToTop = withRouter(
	class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<any>, any> {
		componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>) {
			if (this.props.location !== prevProps.location) {
				window.scrollTo(0, 0);
			}
		}

		render(): JSX.Element {
			return <React.Fragment />;
		}
	},
);

export default ScrollToTop;
