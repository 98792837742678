import React, { Component } from 'react';
import { History } from 'history';

import { FlexColumn } from 'atoms/Flex';
import { DataContext } from 'utils/DataStore';
import { getActivitySelection, getSelection } from 'utils/Selection';

import './Header.scss';

export interface Props {
	history: History<any>;
}

type State = {
	displayBar: boolean;
};

const headerOptions = [
	{ icon: '/search.svg', key: 'search', title: 'RECHERCHER UNE ANIMATION' },
	{ icon: '/picto-rechercher.png', key: 'activities', title: 'RECHERCHER UNE ACTIVITE' },
	{ icon: '/empty-star.svg', key: 'selection', title: 'MA SÉLECTION' },
	{ icon: '/heart.svg', key: 'highlights', title: 'TEMPS FORTS' },
	{ icon: '/lightbulb.svg', key: 'macornouaille', title: 'LES EXPÉRIENCES #MACORNOUAILLE' },
	{ icon: '/stand.svg', key: 'market', title: 'LES MARCHÉS' },
	// { icon: '/infos.svg', key: 'contact', title: 'CONTACT' },
	// { icon: '/flyer.svg', key: 'flyer', title: 'BROCHURES ET CARTES' },
];

export default class Header extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			displayBar: false,
		};
	}

	static contextType = DataContext;

	private isSelected = (key: string) =>
		this.props.history.location.pathname.startsWith(`/${key}`);

	private hideBar = () => this.setState({ displayBar: false });
	private displayBar = () => this.setState({ displayBar: true });

	private getItemOnClick = (key: string) => () => {
		if (key === 'macornouaille') {
			window.open('https://www.macornouaille.bzh');
			return;
		}

		this.props.history.push(`/${key}`);
		this.hideBar();
	};

	private getOptionItem = (item: any, index: number) => {
		let icon;
		let selectedNb = 0;

		if (item.key === 'selection') {
			const storedAnimations = getSelection();
			const storedActivities = getActivitySelection();

			let stored: Array<number> = [];
			if (storedAnimations && storedAnimations.length > 0) {
				stored = stored.concat(storedAnimations);
			}
			if (storedActivities && storedActivities.length > 0) {
				stored = stored.concat(storedActivities);
			}

			if (stored) {
				selectedNb = stored.length;
			}

			icon = selectedNb > 0 ? '/filled-star.svg' : item.icon;
		} else {
			icon = item.icon;
		}

		// conditional highlights item
		if (item.key === 'highlights' && !this.context.settings?.menu_temps_forts) {
			return null;
		}

		return (
			<div
				key={index}
				className={`header-option ${this.isSelected(item.key) ? 'selected' : ''}`}
				onClick={this.getItemOnClick(item.key)}>
				{item.icon && (
					<div className="icon-container">
						{!!selectedNb && <div className="selected-nb">{selectedNb}</div>}
						<img
							src={icon}
							className="header-option-icon"
							alt=""
							aria-hidden="true"
							role="img"
						/>
					</div>
				)}

				<div className="header-option-title">{item.title}</div>
			</div>
		);
	};

	private selectionUpdated = () => {
		this.forceUpdate();
	};

	public componentDidMount() {
		document.addEventListener('selectionChanged', this.selectionUpdated);
	}

	public componentWillUnmount() {
		document.removeEventListener('selectionChanged', this.selectionUpdated);
	}

	public render() {
		const listCn = `${this.state.displayBar ? 'showing' : 'hiding'}`;
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search.slice(1));

		return (
			<div id="header">
				<img
					src="/logo.svg"
					className={`logo ${listCn}`}
					alt=""
					aria-hidden="true"
					role="img"
					onClick={() => this.props.history.push('/')}
				/>

				<div id="header-options-list-container" className={listCn}>
					<div id="header-options-list" className={listCn}>
						{headerOptions.map(this.getOptionItem)}

						<FlexColumn className="footer-content">
							<div>UNE RÉALISATION SOUTENUE</div>

							<img
								src="/footer-content.svg"
								alt="Destination Quimper Cornouaille Bretagne - Quimper Cornouaille Developpement - Union européenne - Région Bretagne"
							/>
						</FlexColumn>
					</div>
				</div>

				{(() => {
					if (params.has('widget')) {
						return '';
					} else {
						return (
							<div
								id="display-icon"
								className={!this.state.displayBar ? 'showing' : 'hiding'}>
								<img src="/menu-burger.svg" height={40} width={40} alt="Menu" />

								<div onClick={this.displayBar} className="clickable-for-svg" />
							</div>
						);
					}
				})()}

				<div id="hide-icon" className={this.state.displayBar ? 'showing' : 'hiding'}>
					<img src="/right-arrow.svg" height={40} width={40} alt="Ferler" />

					<div onClick={this.hideBar} className="clickable-for-svg" />
				</div>
			</div>
		);
	}
}
