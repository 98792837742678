import React from 'react';

import './Button.scss';

export interface ButtonProps {
	children: any;
	className?: string;
	color: 'orange' | 'blue';

	onClick?(e: React.MouseEvent): void;
}

const Button: React.FC<ButtonProps> = (props) => {
	return (
		<button
			className={`custom-button ${props.className || ''} ${props.color}`}
			onClick={props.onClick}>
			<div className="button-content-wrapper">{props.children}</div>
		</button>
	);
};

export default Button;
