// npm imports
import React, { Component } from 'react';
import { History, UnregisterCallback } from 'history';

// local imports
import AnimationMap from 'components/AnimationMap';
import ItemSearchResults from 'components/ItemSearchResults';
import SearchPanel from 'components/Activities/ActivitiesSearchPanel';
import ResearchSummary from 'components/ResearchSummary';
import { DataConsumer, fetchActivities } from 'utils/DataStore';

import { ActivitySearchParams } from 'types/searchParamsType';

// ! TODO check css when activities is in place (for now it is a copy of Animations.scss * class has been modified into activities or activity when animation was singular)
import './Activities.scss';

// types
export interface Props {
	activities: any;
	activityTypeCategories?: {
		description: string;
		hierarchical: boolean;
		labels: object;
		liste: Array<{ term_id: number; count: number; name: string; slug: string }>;
		name: string;
		rewrite: object;
		slug: object;
		types: Array<any>;
	};
	displaySearchParams: boolean;
	history: History<any>;
	parentScrollContainer?: string;
	url: string;

	closeSearch(): void;
	openSearch(): void;
	toggleSearch(): void;
}

type State = {
	activities: Array<any>;
	activityTypeCategories: {
		description: string;
		hierarchical: boolean;
		labels: object;
		liste: Array<{ term_id: number; count: number; name: string; slug: string }>;
		name: string;
		rewrite: object;
		slug: object;
		types: Array<any>;
	};
	currentActivityType: string;
	loading: boolean;
	page: number;
	mapMode: boolean;
	bounds?: L.LatLngBounds;
	mapAndListSynchronized: boolean;

	activitySearchParams: Partial<ActivitySearchParams>;
	searchParamsHaveChanged: boolean;
	mounting: boolean;

	position?: {
		latitude: number;
		longitude: number;
	};
};

// component constants
const PER_PAGE = 100;

// component
export default class Activities extends Component<Props, State> {
	private map?: AnimationMap | null;
	private historyListener?: UnregisterCallback;
	private self?: HTMLDivElement | null;

	public constructor(props: Props) {
		super(props);

		//* get previous data
		let previousSearch;

		if (window.sessionStorage && this.props.history.action === 'POP') {
			const searchStr = window.sessionStorage.getItem('activitySearch');

			if (searchStr) {
				previousSearch = JSON.parse(searchStr);
			}
		}

		//* get previous activity type or set it into storage for its default value
		let previousActivityType;

		if (!window.sessionStorage.getItem('currentActivityType')) {
			window.sessionStorage.setItem('currentActivityType', 'deg');
		}

		if (window.sessionStorage) {
			const searchStr = window.sessionStorage.getItem('currentActivityType');

			if (searchStr) {
				previousActivityType = searchStr;
			}
		}

		//* get previous activity type categories
		let previousActivityTypeCategories;

		if (window.sessionStorage) {
			const searchStr = window.sessionStorage.getItem('activityTypeCategories');

			if (searchStr) {
				previousActivityTypeCategories = JSON.parse(searchStr);
			}
		}

		// * init state
		this.state = {
			activities: [],
			activityTypeCategories:
				previousActivityTypeCategories ?? this.props.activityTypeCategories,
			currentActivityType: previousActivityType ?? 'deg',
			loading: false,
			mapAndListSynchronized: false,
			mapMode: false,
			page: 1,
			activitySearchParams: previousSearch ?? {
				selectedCategories: {
					asc: [],
					deg: [],
					loi: [],
					pcu: [],
				},
			},
			searchParamsHaveChanged: false,
			mounting: true,
		};
	}

	// LIFE CYCLE
	componentDidMount() {
		//* update the state with the previous search when it exists in sessionStorage
		let previousSearch;
		const searchStr = window.sessionStorage.getItem('activitySearch');

		if (searchStr) {
			previousSearch = JSON.parse(searchStr);

			this.setState({ activitySearchParams: previousSearch });
		}

		//* fetch activity data unless there are activities in props but no activitySearchParams into state
		setTimeout(() => {
			if (this.props.activities && !this.hasParams()) {
				return this.onActivitiesDataFetched(this.props.activities, true);
			}

			this.getActivities(true);
		}, 0);

		//* To reopen the search panel when click on "Rechercher une activité" whereas we are already on this page
		if (this.props.history.location.pathname.startsWith(`/activities`)) {
			this.props.openSearch();
		}

		this.historyListener = this.props.history.listen((location, action) => {
			if (this.props.history.location.pathname.startsWith(`/activities`)) {
				this.props.openSearch();
			}
		});

		//* synchronize the map on screen having a width higher than 600px
		if (window.innerWidth >= 600) {
			this.setState({
				mapAndListSynchronized: true,
			});
		}
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const { currentActivityType, activitySearchParams, searchParamsHaveChanged } = this.state;
		const {
			currentActivityType: previousActivityType,
			activitySearchParams: prevActivitySearchParams,
		} = prevState;

		//* when we change the activityType, we have to reset page and fetch data
		if (currentActivityType !== previousActivityType) {
			this.setState({ page: 1 }, () => this.getActivities(true));
		}

		if (!!searchParamsHaveChanged && activitySearchParams === prevActivitySearchParams) {
			this.setState({ searchParamsHaveChanged: false });
		}
	}

	public componentWillUnmount() {
		//* update data for lazy scroll
		if (this.historyListener) {
			this.historyListener();
		}

		if (window.sessionStorage && this.props.history.action !== 'POP') {
			//* save location
			const lastPositionActivityPageStr = window.sessionStorage.getItem(
				'lastPositionActivityPage',
			);
			const lastPositionActivityPageStack = lastPositionActivityPageStr
				? JSON.parse(lastPositionActivityPageStr)
				: [];

			lastPositionActivityPageStack.push(window.scrollY);
			window.sessionStorage.setItem(
				'lastPositionActivityPage',
				JSON.stringify(lastPositionActivityPageStack),
			);

			//* save parent scroll container
			if (this.props.parentScrollContainer) {
				const scrollContainer = document.querySelector(this.props.parentScrollContainer);

				if (scrollContainer) {
					const lastActivityPositionStr =
						window.sessionStorage.getItem('lastActivityPosition');
					const lastActivityPositionStack = lastActivityPositionStr
						? JSON.parse(lastActivityPositionStr)
						: [];
					lastActivityPositionStack.push(scrollContainer.scrollTop);

					window.sessionStorage.setItem(
						'lastActivityPosition',
						JSON.stringify(lastActivityPositionStack),
					);
				}
			}
		}
	}

	//* DATA
	/**
	 * create url thanks to the data included into activitySearchParams state
	 * and fetch data according to those search parameters
	 *
	 * @param {boolean} withCache
	 */
	private getActivities = (withCache = false) => {
		const {
			activitySearchParams: {
				childrenFriendly,
				city,
				cities,
				isGeolocalized,
				position,
				selectedCategories,
			},
			currentActivityType,
		} = this.state;

		//* url creation according to the search parameters
		let url = `${this.props.url}?per_page=${PER_PAGE}&page=${this.state.page}`;

		if (childrenFriendly) {
			url += `&enfants`;
		}

		if (!!currentActivityType) {
			url += `&type_activite=${currentActivityType}`;
		}

		if (isGeolocalized) {
			if (position) {
				url += `&latitude=${position.latitude}&longitude=${position.longitude}&radius=20`;
			}
		} else if (cities) {
			let separator = '';
			let communes = '&commune=';

			// Le nombre de villes est ok lorsqu'on console log l'url par rapport à ce qu'on a dans cities => ok

			try {
				cities?.forEach((city) => {
					city.communes.forEach((elem) => {
						communes += separator + elem;

						if (separator === '') {
							separator = ',';
						}
					});
					if (city.nom !== '' && !city.nom.includes('Autour')) {
						communes += separator + city.nom;
						if (separator === '') {
							separator = ',';
						}
					}
				});

				url += communes;
			} catch (e) {}
		} else if (city) {
			let separator = '';
			let communes = '&commune=';

			try {
				city.communes.forEach((elem) => {
					communes += separator + elem;

					if (separator === '') {
						separator = ',';
					}
				});

				url += communes;
			} catch (e) {}
		}

		// the selected categories depends on which activity type we are
		// since we keep them all into the object selected categories
		// we have to return only those related to the currentActivityType in this state
		if (selectedCategories) {
			let currentActivitySelectedCategories = this.handleCurrentActivitySelectedCategories();

			if (!!currentActivitySelectedCategories.length) {
				url += `${url}&categorie_${currentActivityType}=${currentActivitySelectedCategories.join(
					',',
				)}`;
			}
		}

		//* launch loader + fetch data
		this.setState({ loading: true }, () => {
			fetchActivities(url, {
				method: 'GET',
				concatWithCache: this.state.page !== 1 && !this.hasParams(),
				withCache: withCache && !this.hasParams(),
			}).then((data) => this.onActivitiesDataFetched(data, withCache));
		});
	};

	/**
	 * allows us to get the selected categories only for the current activity type
	 * since we have an object into selectedCategories with the 4 activity types as key
	 */
	private handleCurrentActivitySelectedCategories = () => {
		let selection: any = [];

		selection = Object.entries(this.state.activitySearchParams.selectedCategories)
			.map((entry) => {
				return { id: entry[0], value: entry[1] };
			})
			.find((item) => {
				return item.id === this.state.currentActivityType;
			});

		selection = selection.value;

		return selection;
	};

	/**
	 * when data are fetched :
	 * loader is stopped
	 * we add a page to the request
	 * and if we have as much data as per page it means that all data are not fetched
	 * then we fetch the next data that's why we concat the data into activities
	 *
	 * @param data
	 * @param withCache
	 */
	private onActivitiesDataFetched = (data: any, withCache: boolean) => {
		// https://developer.mozilla.org/fr/docs/Web/API/Window/requestAnimationFrame
		// indicate to the browser a callback to execute before refreshing after an animation
		// here before displaying the activities we want to update the state
		requestAnimationFrame(() => {
			this.setState(
				{
					loading: false,
					page: this.state.page + 1,
					activities: this.state.activities.concat(data),
				},
				() => {
					// fetch when there are still data into API
					if (data && data.length === PER_PAGE) {
						this.getActivities(withCache);
					}

					// update necessary data for the lazy scroll
					if (this.state.mounting && this.props.history.action === 'POP') {
						let lastPositionActivityPage;
						let lastActivityPosition;

						if (window.sessionStorage) {
							const lastPositionActivityPageStr = window.sessionStorage.getItem(
								'lastPositionActivityPage',
							);
							const lastActivityPositionStr =
								window.sessionStorage.getItem('lastActivityPosition');

							if (lastPositionActivityPageStr) {
								const lastPositionActivityPageStack = JSON.parse(
									lastPositionActivityPageStr,
								);
								lastPositionActivityPage = lastPositionActivityPageStack.pop() || 0;
								window.sessionStorage.setItem(
									'lastPositionActivityPage',
									JSON.stringify(lastPositionActivityPageStack),
								);
							}

							if (lastActivityPositionStr) {
								const lastActivityPositionStack =
									JSON.parse(lastActivityPositionStr);
								lastActivityPosition = lastActivityPositionStack.pop() || 0;
								window.sessionStorage.setItem(
									'lastActivityPosition',
									JSON.stringify(lastActivityPositionStack),
								);
							}
						}

						if (lastPositionActivityPage || lastActivityPosition) {
							if (this.self && this.props.parentScrollContainer) {
								const scrollContainer = document.querySelector(
									this.props.parentScrollContainer,
								);

								if (scrollContainer) {
									scrollContainer.scrollTo(0, lastActivityPosition || 0);
								}
							}

							window.scrollTo(0, lastPositionActivityPage || 0);
						}

						this.setState({ mounting: false });
					}
				},
			);
		});
	};

	//* RESEARCH
	private hasParams = () => !!Object.keys(this.state.activitySearchParams).length;

	private hasActivitySearchSessionStorage = () =>
		!!window.sessionStorage.getItem('activitySearch');

	/**
	 * used into ActivitiesSearchPanel to update the session storage when a search is validated
	 *
	 * @param activitySearchParams
	 * @param currentActivityType
	 */
	private setSearchState = (
		activitySearchParams: Partial<ActivitySearchParams>,
		currentActivityType: string,
	) => {
		this.setState(
			{
				activitySearchParams,
				currentActivityType: currentActivityType,
				searchParamsHaveChanged: true,
			},
			this.updateSessionStorage,
		);
	};

	private updateSessionStorage = () => {
		const { activitySearchParams, currentActivityType } = this.state;

		if (window.sessionStorage) {
			window.sessionStorage.setItem('activitySearch', JSON.stringify(activitySearchParams));
			window.sessionStorage.setItem('currentActivityType', currentActivityType);
		}

		if (
			!activitySearchParams.childrenFriendly &&
			!activitySearchParams.city &&
			!activitySearchParams.selectedCategories.asc.length &&
			!activitySearchParams.selectedCategories.deg.length &&
			!activitySearchParams.selectedCategories.loi.length &&
			!activitySearchParams.selectedCategories.pcu.length
		) {
			window.sessionStorage.removeItem('activitySearch');
		}

		this.onActivitySearchParamsValidate();
	};

	private onActivitySearchParamsValidate = () => {
		this.setState(
			{
				activities: [],
				page: 1,
			},
			() => {
				this.getActivities();
			},
		);
	};

	//* MAP & LIST
	// on selection of "Liste" or "Carte" on mobile
	private selectListMode = () => this.setState({ mapMode: false });

	private selectMapMode = () =>
		this.setState({ mapMode: true }, () => {
			if (window.navigator && window.navigator.geolocation && window.innerWidth < 600) {
				const location = window.navigator.geolocation;

				if (location) {
					location.getCurrentPosition(this.saveUserCurrentPosition);
				}
			}

			this.map && this.map.invalidateSize();
		});

	// map and list other functions
	private toggleMapAndListSynchronization = () => {
		this.setState({ mapAndListSynchronized: !this.state.mapAndListSynchronized });
	};

	private saveUserCurrentPosition = ({ coords }) => {
		this.setState({ position: { latitude: coords.latitude, longitude: coords.longitude } });
	};

	private onMoveEnd = (bounds: L.LatLngBounds) => {
		this.setState({ bounds });
	};

	//* REDIRECT
	private getActivityOnClick = (id?: number) => () => {
		this.props.history.push(`/activities/${id || ''}`);
	};

	public render() {
		const {
			activities,
			bounds,
			currentActivityType,
			loading,
			mapAndListSynchronized,
			mapMode,
			activitySearchParams,
			searchParamsHaveChanged,
		} = this.state;
		const { displaySearchParams, history, parentScrollContainer, closeSearch, toggleSearch } =
			this.props;

		return (
			<div id="activities" ref={(item) => (this.self = item)}>
				<DataConsumer>
					{(store) => (
						<SearchPanel
							categories={store.activityTypeCategories}
							cities={store.cities}
							currentActivityType={currentActivityType}
							displayed={displaySearchParams}
							searchParams={activitySearchParams}
							onClose={closeSearch}
							setSearchState={this.setSearchState}
						/>
					)}
				</DataConsumer>

				<div className="activities-body">
					<div className="activities-body-left">
						<div className={mapMode ? 'shown' : 'hidden'} id="activity-map-container">
							<AnimationMap
								onMoveEnd={this.onMoveEnd}
								ref={(elem) => (this.map = elem)}
								animations={activities}
								animationPopUpOnClick={this.getActivityOnClick}
								currentPosition={this.state.position}
							/>

							<div className="map-parameters">
								{this.hasActivitySearchSessionStorage() && (
									<DataConsumer>
										{(store) => (
											<ResearchSummary
												categories={
													store.activityTypeCategories ??
													this.state.activityTypeCategories
												}
												closeSearch={closeSearch}
												searchParams={activitySearchParams}
												currentActivityType={currentActivityType}
												setSearchState={this.setSearchState}
											/>
										)}
									</DataConsumer>
								)}
								<div className="map-parameters__map-mouse">
									<input
										type="checkbox"
										checked={this.state.mapAndListSynchronized}
										onChange={this.toggleMapAndListSynchronization}
									/>
									<span>Rechercher quand je déplace la souris</span>
								</div>
							</div>
						</div>
					</div>

					<ItemSearchResults
						bounds={bounds}
						displaySearchParams={displaySearchParams}
						items={activities}
						itemType={`activité`}
						getItemOnClick={this.getActivityOnClick}
						history={history}
						loading={loading}
						mapAndListSynchronized={mapAndListSynchronized}
						mapMode={mapMode}
						parentScrollContainer={parentScrollContainer}
						searchParamsHaveChanged={searchParamsHaveChanged}
						searchParamsOnClick={toggleSearch}
						selectListMode={this.selectListMode}
						selectMapMode={this.selectMapMode}
						title={`Les activités`}
					/>
				</div>
			</div>
		);
	}
}
