import React from 'react';
import { History, UnregisterCallback } from 'history';

import Loading from 'atoms/Loading';
import ItemPreview from 'components/ItemPreview';
import { FULL_SELECTION } from 'utils/Constants';
import { ITEMS_PER_PAGE } from 'utils/Constants';
import {
	getActivitySelection,
	getSelection,
	setActivitySelection,
	setSelection,
} from 'utils/Selection';

import './ItemSearchResults.scss';

interface Props {
	bounds?: L.LatLngBounds;
	displaySearchParams: boolean;
	history: History<any>;
	items: Array<any>;
	itemType: string;
	loading: boolean;
	mapAndListSynchronized: boolean;
	mapMode: boolean;
	parentScrollContainer?: string;
	searchParamsHaveChanged: boolean;
	title: string;

	getItemOnClick: (id?: number) => () => void;
	searchParamsOnClick: () => void;
	selectListMode: () => void;
	selectMapMode: () => void;
}

type State = {
	allItemsSelected: boolean;
	currentSelection: number[];
	itemsToDisplay: Array<any>;
	fullSelection: boolean;
	selectionNumber: number;
	lazyLoadingReady: boolean;
	maxItems: number;
};

export default class ItemSearchResults extends React.Component<Props, State> {
	// https://www.npmjs.com/package/history
	private historyListener?: UnregisterCallback;
	private observer!: IntersectionObserver;

	public constructor(props: Props) {
		super(props);

		//* handle how many items to display when at the page construction
		let maxItems = ITEMS_PER_PAGE;

		if (window.sessionStorage && this.props.history.action === 'POP') {
			const pmaStr = window.sessionStorage.getItem('maxItems');

			if (pmaStr) {
				const pmaStack = JSON.parse(pmaStr);
				maxItems = pmaStack.pop() || ITEMS_PER_PAGE;
				window.sessionStorage.setItem('maxItems', JSON.stringify(pmaStack));
			}
		}

		this.state = {
			allItemsSelected: false,
			currentSelection: this.getCurrentSelection(),
			itemsToDisplay: this.props.items,
			lazyLoadingReady: false,
			maxItems,
			fullSelection: false,
			selectionNumber: 0,
		};
	}

	setSelectionNumber = () => {};

	public componentDidMount() {
		window.scrollTo(0, 0);

		setTimeout(() => {
			this.createLazyObserver();
		});

		// deal with all items already selected
		const { items } = this.props;
		const { currentSelection } = this.state;
		if (!!items.length && !!currentSelection.length) {
			this.handleAllItemsAlreadySelected();
		}

		// deal with full selection
		this.setFullSelection();
	}

	setFullSelection = () => {
		const { itemType } = this.props;

		let storedArray: number[] = [];
		if (!!itemType && itemType === 'activité') {
			storedArray = getActivitySelection() || [];
		} else {
			storedArray = getSelection() || [];
		}

		if (storedArray.length < FULL_SELECTION) {
			this.setState({ fullSelection: false, selectionNumber: storedArray.length });
			return false;
		} else {
			this.setState({ fullSelection: true, selectionNumber: storedArray.length });
			return true;
		}
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { items, searchParamsHaveChanged } = this.props;
		const { items: prevItems } = prevProps;
		const { currentSelection } = this.state;
		const { currentSelection: prevCurrentSelection } = prevState;

		if (items !== prevItems) {
			this.setState({ maxItems: ITEMS_PER_PAGE });

			if (!!currentSelection && currentSelection.length) {
				this.handleAllItemsAlreadySelected();
				this.setFullSelection();
			}

			if (!!searchParamsHaveChanged) {
				// deal with all items already selected
				this.handleAllItemsAlreadySelected();
				this.setFullSelection();
			}
		}

		// ! @todo si la sélection change on va vouloir repasser sur le bouton tout sélectionner

		if (currentSelection !== prevCurrentSelection) {
			// deal with all items already selected
			this.handleAllItemsAlreadySelected();
			this.setFullSelection();
		}
	}

	public componentWillUnmount() {
		if (this.historyListener) {
			this.historyListener();
		}

		//* Save the number max of items into session storage
		if (window.sessionStorage && this.props.history.action !== 'POP') {
			const maxItems = window.sessionStorage.getItem('maxItems');
			const maxItemsStack = maxItems ? JSON.parse(maxItems) : [];
			maxItemsStack.push(this.state.maxItems);
			window.sessionStorage.setItem('maxItems', JSON.stringify(maxItemsStack));
		}
	}

	private createLazyObserver = () => {
		if (this.props.parentScrollContainer && IntersectionObserver) {
			if (!this.observer) {
				const scrollContainer = document.querySelector(this.props.parentScrollContainer);

				this.observer = new IntersectionObserver(
					(entries) => {
						if (entries[0].intersectionRatio <= 0) {
							return;
						}

						this.displayMore();
					},
					{
						root: scrollContainer,
						rootMargin: '0px 0px 0px 0px',
						threshold: 0,
					},
				);

				const loaderToggle = document.querySelector('.item-lazy-load-enabled');

				if (loaderToggle) {
					this.observer.observe(loaderToggle);
				}

				this.setState({
					lazyLoadingReady: true,
				});
			}
		}
	};

	private handleAllItemsAlreadySelected = () => {
		// deal with all items already selected
		const { items } = this.props;
		const { currentSelection } = this.state;

		if (!!currentSelection && !!currentSelection.length) {
			const allItemsAlreadySelected = items.every((item) =>
				currentSelection.includes(item.id),
			);

			if (allItemsAlreadySelected) {
				this.setState({ allItemsSelected: true });
			} else {
				this.setState({ allItemsSelected: false });
			}
		}
	};

	private getCurrentSelection = () => {
		const { itemType } = this.props;
		const currentSelection: any =
			itemType === 'animation'
				? getSelection()
				: itemType === 'activité'
				? getActivitySelection()
				: [];

		return currentSelection;
	};

	private setNewSelection = (newSelection: number[]) => {
		const { itemType } = this.props;

		if (itemType === 'animation') {
			setSelection(newSelection);
		} else if (itemType === 'activité') {
			setActivitySelection(newSelection);
		}
	};

	/**
	 * change the number of items displayed per page
	 */
	private displayMore = () => this.setState({ maxItems: this.state.maxItems + ITEMS_PER_PAGE });

	/**
	 * @return [Object] { hasMoreToDisplay, items }
	 */
	private itemsToDisplay = () => {
		const { bounds, items, mapAndListSynchronized } = this.props;

		let itemsToDisplay = items.filter((elem) => !!elem && elem.distance !== -1);

		if (mapAndListSynchronized && window.innerWidth > 1095) {
			itemsToDisplay = itemsToDisplay.filter(
				(elem) =>
					!bounds ||
					(bounds.getNorth() > elem.latitude &&
						bounds.getSouth() < elem.latitude &&
						bounds.getEast() > elem.longitude &&
						bounds.getWest() < elem.longitude),
			);
		}
		const hasMoreToDisplay =
			!!itemsToDisplay &&
			itemsToDisplay.length > 0 &&
			itemsToDisplay.length > this.state.maxItems;

		itemsToDisplay = itemsToDisplay.slice(0, this.state.maxItems);

		return { hasMoreToDisplay, items: itemsToDisplay };
	};

	/**
	 * add all current items to selection
	 */
	private addAllItemsToSelection = () => {
		const { items } = this.props;
		const { currentSelection } = this.state;

		let newSelection: any = [];

		if (
			typeof currentSelection === undefined ||
			!currentSelection ||
			!currentSelection.length
		) {
			newSelection = items.map((item) => item.id);
		} else {
			const addToSelection = items
				.map((item) => {
					if (!currentSelection.includes(item.id)) {
						return item.id;
					}
				})
				.filter((item) => item !== undefined);
			newSelection = currentSelection.concat(addToSelection);
		}

		this.setNewSelection(newSelection);
		this.forceUpdate();
		this.setState({ allItemsSelected: true, currentSelection: newSelection });
	};

	/**
	 * remove all current items from selection
	 */
	private deleteAllItemsFromSelection = () => {
		const { items } = this.props;
		const currentSelection = this.getCurrentSelection();

		const newSelection = currentSelection
			.map((selection) => {
				const isAnItem = items.find((item) => item.id === selection);

				if (!isAnItem) {
					return selection;
				}
			})
			.filter((selection) => selection !== undefined);

		this.setNewSelection(newSelection);
		this.forceUpdate();
		this.setState({ allItemsSelected: false, currentSelection: newSelection });
	};

	private onItemPreviewAddition = () => {
		this.forceUpdate();
		this.updateCurrentSelection();
	};

	private onItemPreviewDeletion = () => {
		this.forceUpdate();
		this.updateCurrentSelection();
	};

	private updateCurrentSelection = () => {
		const currentSelection = this.getCurrentSelection();

		this.setState({ currentSelection });
	};

	render() {
		const {
			displaySearchParams,
			items,
			itemType,
			loading,
			mapMode,
			title,
			getItemOnClick,
			searchParamsOnClick,
			selectListMode,
			selectMapMode,
		} = this.props;
		const { allItemsSelected, fullSelection, selectionNumber } = this.state;

		//* items
		const itemsToDisplay = this.itemsToDisplay().items;
		const hasMoreToDisplay = this.itemsToDisplay().hasMoreToDisplay;

		//* picto
		const picto = itemType === 'animation' ? 'picto-dates.svg' : 'picto-drapeau-noir.png';

		//* render
		return (
			<div className="items-body-right">
				<div id="items-title">
					{`${title} `}
					<img src={`/${picto}`} alt="" aria-hidden="true" role="img" />
				</div>
				<div id="items-header">
					<span onClick={searchParamsOnClick}>
						{!displaySearchParams
							? 'Faire une recherche'
							: 'Cacher les filtres de recherches'}
					</span>

					{!!items.length &&
					!allItemsSelected &&
					FULL_SELECTION - selectionNumber > items.length ? (
						<button
							className="selection selection--add-to"
							title={`Ajouter toutes les ${itemType}s à ma sélection`}
							onClick={this.addAllItemsToSelection}>
							Sélectionner tout
						</button>
					) : !!items.length && !!allItemsSelected ? (
						<button
							className="selection selection--delete-from"
							title={`Supprimer toutes les ${itemType}s à ma sélection`}
							onClick={this.deleteAllItemsFromSelection}>
							Désélectionner tout
						</button>
					) : (
						''
					)}

					<div className="select">
						<div onClick={selectMapMode} className={mapMode ? 'selected' : ''}>
							CARTE
						</div>

						<div onClick={selectListMode} className={!mapMode ? 'selected' : ''}>
							LISTE
						</div>
					</div>
				</div>
				<div id="items-preview-container" className={!mapMode ? 'shown' : 'hidden'}>
					<div id="items-preview-scroll-container">
						{itemsToDisplay && itemsToDisplay.length > 0
							? itemsToDisplay.map(
									(elem, index) =>
										elem && (
											<ItemPreview
												data={elem}
												itemType={itemType}
												key={index}
												onClick={getItemOnClick(elem.id)}
												onAddition={() => this.onItemPreviewAddition()}
												onDeletion={() => this.onItemPreviewDeletion()}
												deleteIcon
												fullSelection={fullSelection}
												setFullSelection={this.setFullSelection}
											/>
										),
							  )
							: !loading && (
									<div style={{ textAlign: 'center', flexGrow: 1 }}>
										{`Aucune ${itemType} trouvée`}
									</div>
							  )}
						<div className="item-lazy-load-enabled" />
					</div>
				</div>
				{!mapMode && loading && (
					<div className="loading-container">
						<Loading />
					</div>
				)}
				{hasMoreToDisplay && (
					<div id="bottom-button" className={!mapMode ? 'shown' : 'hidden'}>
						<span onClick={this.displayMore}>AFFICHER PLUS</span>
					</div>
				)}
			</div>
		);
	}
}
