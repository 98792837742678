import React, { Component } from 'react';

import DropDown from './dropdown';
import MultipleSelection from './MultipleSelection';

import './autocomplete.scss';

const KEY_ENTER = 13;
const KEY_ESCAPE = 27;
const KEY_ARROW_UP = 38;
const KEY_ARROW_DOWN = 40;

export interface IAutoCompleteProps {
	dropdownElementHeight?: number;
	forcedResult?: Array<unknown>;
	hideFieldValidity?: boolean;
	hiddenValue?: unknown;
	dropdownMaxHeight?: number;
	name: string;
	selectedResult?: any;
	autoComplete?: string; // HTML5 Property
	autoCompleteList?: boolean;
	autoFocus?: boolean;
	bottomLabel?: string;
	checked?: boolean;
	className?: string;
	containerStyle?: any;
	controlled?: boolean;
	debounce?: number;
	disabled?: boolean;
	focus?: boolean;
	forceValue?: boolean;
	icon?: React.FunctionComponent<any>;
	id?: string;
	isMandatory?: boolean;
	isMultiple?: boolean;
	label?: string;
	max?: number;
	maxLength?: number;
	min?: number;
	minLength?: number;
	pattern?: string;
	placeholder?: string;
	readOnly?: boolean;
	required?: boolean;
	secondLabel?: string;
	size?: number;
	step?: number;
	style?: any;
	tabIndex?: number;
	type: string;
	value?: string | number;
	// ! @todo => check type
	values?: any[];
	suggestions: Array<any>;

	onBlur?(): void;
	onChange?(e: any): void;
	onDeleteSelection?(e: any): void;
	onResult?(e: any): void;
	onFocus?(): void;
	onInput?(): void;
	onInputClick?(): void;
	onKeyDown?(e: any): void;
	onKeyPress?(e: any): void;
	onClick?(value: string | number): void;

	createActionElements?(search: string, data: Array<string>): Array<JSX.Element>;
	filterByCategory?(data: Array<{ wrapped: JSX.Element; result: string }>): {
		height: number;
		results: Array<JSX.Element>;
	};
	filterSearch(search: string, suggestions: Array<any>): Array<any>;
	suggestionToString(suggestion: any): string;
}

type State = {
	actionElements: Array<JSX.Element>;
	currentSearch: string;
	data?: Array<unknown>;
	error?: string;
	focusedResult: number;
	ignoreNextChange: boolean;
	loading: boolean;
	listShown: boolean;
	remainingResults: number;
	results: Array<string>;
	selectedResult?: any;
	updateId: number;
};

export default class Autocomplete extends Component<IAutoCompleteProps, State> {
	private input?: HTMLInputElement | null = null;

	constructor(props: IAutoCompleteProps) {
		super(props);

		this.state = {
			actionElements: [],
			currentSearch: (props.value && props.value.toString()) || '',
			focusedResult: 0,
			ignoreNextChange: false,
			listShown: false,
			loading: false,
			remainingResults: 0,
			results: [],
			updateId: 0,
		};
	}

	private doSearch = (search: string, callback?: () => void) => {
		const newElements = this.props.filterSearch(search, this.props.suggestions);
		let newActionElements = this.state.actionElements;

		if (this.props.createActionElements) {
			newActionElements = this.props.createActionElements(search, newElements);
		}

		const nbElements = newActionElements.length + newElements.length;

		this.setState(
			{
				actionElements: newActionElements,
				focusedResult:
					this.state.focusedResult >= nbElements
						? nbElements - 1
						: this.state.focusedResult,
				results: newElements,
			},
			() => {
				if (callback) {
					callback();
				}
			},
		);
	};

	private acceptResultFromKeyboard() {
		const { results, actionElements } = this.state;
		let { focusedResult } = this.state;

		// Call the first action/result if enter is pressed on text field
		if (focusedResult === -1) {
			focusedResult = 0;
		}

		if (focusedResult < actionElements.length) {
			actionElements[focusedResult].props.onClick({
				name: 'action',
				value: this.state.currentSearch,
			});

			this.blur();

			return;
		}

		const selectedResult = results && results[focusedResult - actionElements.length];

		this.setState(
			{
				currentSearch:
					this.props.suggestionToString(selectedResult) || this.state.currentSearch,
				selectedResult,
			},
			() => {
				this.blur();
				if (this.props.onResult) {
					this.props.onResult({
						name: this.props.name,
						value: selectedResult,
					});
				}
			},
		);
	}

	private onElementClick = (e: any) => {
		const { isMultiple } = this.props;
		const { results, actionElements } = this.state;
		const index = e.currentTarget.dataset.index;

		if (index < actionElements.length) {
			actionElements[index].props.onClick({ value: this.state.currentSearch });
			return;
		}

		const selectedResult = results && results[index - actionElements.length];
		// ici que se crée l'objet city contenant les communes et le nom de la ville selectionnée

		this.setState(
			{
				currentSearch: isMultiple ? '' : this.props.suggestionToString(selectedResult),
				selectedResult,
			},
			() => {
				this.blur();

				if (this.props.onResult) {
					this.props.onResult({
						name: this.props.name,
						value: selectedResult,
					});
				}
			},
		);
	};

	private blur = () => {
		// Blur when the result is selected
		if (this.input) {
			const inputHTML = this.input;

			if (inputHTML) {
				inputHTML.blur();
			}
		}
	};

	private getActiveClass = () => (this.state.listShown ? 'active' : 'inactive');

	private getMultipleAutocompletClass = () =>
		this.props.isMultiple ? 'autocomplete-multiple' : '';

	private getEmptyClass = () => (this.state.currentSearch ? 'not-empty' : '');

	public onInputChange = (e: any) => {
		if (this.state.ignoreNextChange) {
			return this.setState({
				ignoreNextChange: false,
			});
		}

		if (this.props.onChange) {
			this.props.onChange({ value: e.currentTarget.value, name: this.props.name });
		}

		this.setState(
			{
				currentSearch: e.currentTarget.value,
				/* Remove any selected result when input change to avoid to accept
				 *   false informations. */
				selectedResult: undefined,
			},
			() => {
				this.doSearch(this.state.currentSearch);
			},
		);
	};

	public onInputBlur = () => {
		this.setState(
			{
				listShown: false,
			},
			() => {
				if (this.props.onBlur) {
					this.props.onBlur();
				}
			},
		);
	};

	public onInputFocus = () => {
		this.setState(
			{
				listShown: true,
			},
			() => {
				this.doSearch(this.state.currentSearch);

				if (this.props.onFocus) {
					this.props.onFocus();
				}
			},
		);
	};

	public onInternalInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const key = e.keyCode || e.charCode;
		const { focusedResult, results, actionElements } = this.state;

		if (key === KEY_ARROW_UP && focusedResult >= 0) {
			e.preventDefault();
			this.setState({
				focusedResult: this.state.focusedResult - 1,
			});
		} else if (
			key === KEY_ARROW_DOWN &&
			focusedResult < results.length + actionElements.length - 1
		) {
			e.preventDefault();
			this.setState({
				focusedResult: this.state.focusedResult + 1,
			});
		} else if (key === KEY_ENTER) {
			this.acceptResultFromKeyboard();
		} else if (key === KEY_ESCAPE) {
			this.blur();
		}
	};

	public getFieldValidity() {
		const { selectedResult } = this.state;

		// TODO: Add a check of current entry instead of accepting value without verification
		return !!selectedResult;
	}

	public focus = () => {
		if (this.input) {
			this.input.focus();
		}
	};

	public deleteContent = () => {
		if (this.props.disabled) {
			return;
		}

		this.setState(
			{
				currentSearch: '',
				selectedResult: null,
			},
			() => {
				if (this.props.onResult) {
					this.props.onResult({
						name: this.props.name,
						value: null,
					});
				}

				if (this.props.onChange) {
					this.props.onChange({
						name: this.props.name,
						value: '',
					});
				}
			},
		);
	};

	public focusAtNextTick = () => {
		setTimeout(this.focus);
	};

	public componentDidUpdate(prevProps: IAutoCompleteProps) {
		if (prevProps.value !== this.props.value) {
			this.setState(
				{
					currentSearch: (this.props.value && this.props.value.toString()) || '',
					selectedResult: undefined,
				},
				() => {
					if (prevProps.selectedResult !== this.props.selectedResult) {
						this.setState({ selectedResult: this.props.selectedResult });
					}
				},
			);
		}
	}

	public render() {
		const {
			onChange,
			onResult,
			onFocus,
			onBlur,
			forcedResult,
			hiddenValue,
			hideFieldValidity,
			onClick,
			icon,
			placeholder,
			className,
			filterByCategory,
			dropdownElementHeight,
			createActionElements,
			label,
			onKeyDown,
			onKeyPress,
			dropdownMaxHeight,
			selectedResult,
			secondLabel,
			bottomLabel,
			suggestionToString,
			filterSearch,
			suggestions,
			isMultiple,
			values,
			onDeleteSelection,
			...attributes
		} = this.props;

		let inputClassName = className || '';
		let labelDiv;
		let placeholderDiv;

		if (label) {
			labelDiv = (
				<label className="autocomplete-label display-on-mobile" onClick={this.focus}>
					{label}
				</label>
			);
		}

		if (placeholder && !this.state.currentSearch) {
			inputClassName += ' with-placeholder';

			placeholderDiv = (
				<span
					className={`placeholder v-center-container ${icon ? 'with-icon' : ''} ${
						label ? 'with-label' : ''
					}`}
					onClick={this.focus}>
					{placeholder}
				</span>
			);
		}

		return (
			<div
				className={`autocomplete ${this.getMultipleAutocompletClass()} ${this.getActiveClass()} ${inputClassName}`}>
				{label && labelDiv}

				{secondLabel && <div className="second-label">{secondLabel}</div>}

				<div style={{ position: 'relative' }}>
					<div className={`input-component ${this.getEmptyClass()}`}>
						{icon && (
							<div className="input-icon" onMouseDown={this.focusAtNextTick}>
								{React.createElement(icon, { className: 'input-icon-internal' })}
							</div>
						)}

						<input
							ref={(input) => {
								this.input = input;
							}}
							onChange={this.onInputChange}
							onBlur={this.onInputBlur}
							onFocus={this.onInputFocus}
							onKeyDown={this.onInternalInputKeyDown}
							autoComplete="off"
							{...attributes}
							className={`${inputClassName} ${icon ? 'with-icon' : ''} ${
								this.state.results.length ? 'with-dropdown' : ''
							} ${this.state.listShown ? 'active' : 'inactive'} ${
								!this.props.hideFieldValidity &&
								(this.getFieldValidity() ? 'valid' : 'error')
							}`}
							value={this.state.currentSearch}
						/>

						{isMultiple &&
							!!values &&
							!!values.length &&
							!!suggestions &&
							!!suggestions.length && (
								<>
									<MultipleSelection
										currentSelection={values}
										onDeleteSelection={onDeleteSelection}
										suggestions={suggestions}
									/>
								</>
							)}

						{placeholder && placeholderDiv}
					</div>

					{bottomLabel && <div className="bottom-label">{bottomLabel}</div>}
				</div>

				<DropDown
					elementHeight={dropdownElementHeight}
					filterByCategory={filterByCategory}
					focusedResult={this.state.focusedResult}
					elements={this.state.results}
					isLoading={this.state.loading}
					onElementSelected={this.onElementClick}
					actionElements={this.state.actionElements}
					maxHeight={this.props.dropdownMaxHeight}
					suggestionToString={suggestionToString}
				/>
			</div>
		);
	}
}
