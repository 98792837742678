import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Loading from 'atoms/Loading';
import ItemPreview from 'components/ItemPreview';
import AnimationSuggestion from 'components/AnimationSuggestion';
import { FULL_SELECTION } from 'utils/Constants';
import URLTools from 'utils/URL';

import './highlights.scss';
import AnimationMap from 'components/AnimationMap';

type Props = {
	history: History<any>;
};

type State = {
	animations: Array<any>;
	error: boolean;
	hovered?: any;
	loading: boolean;
	page: number;
	displayMore: boolean;
	fullSelection: boolean;
};

export default class highlights extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			animations: [],
			displayMore: false,
			error: false,
			loading: false,
			page: 1,
			fullSelection: false,
		};
	}

	private setFullSelection = () => {
		let storedArray: any = getSelection() || [];

		if (storedArray.length < FULL_SELECTION) {
			this.setState({ fullSelection: false });
			return false;
		} else {
			this.setState({ fullSelection: true });
			return true;
		}
	};

	private getGoToAnimation = (id: number) => () => this.props.history.push(`/animations/${id}`);

	private onAnimationsFetched = (data: Array<any>) =>
		this.setState({
			animations: data ? this.state.animations.concat(data) : this.state.animations,
			loading: false,
			page: this.state.page + 1,
			displayMore: !!data && data.length > 0,
		});

	private fetchAnimation = () => {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true, error: false, displayMore: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/animations?temps_forts&per_page=12&page=${this.state.page}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => {
					if (response.ok) {
						return response.json();
					}

					return response.text().then((text) => {
						throw new Error(text);
					});
				})
				.then(this.onAnimationsFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	private onAnimationHoverIn = (animation: any) => () =>
		setTimeout(() => this.setState({ hovered: animation }));

	private onAnimationHoverOut = (elem: any) => () =>
		setTimeout(() => {
			if (!this.state.hovered || elem.id !== this.state.hovered.id) {
				return;
			}

			this.setState({ hovered: undefined });
		});

	public componentDidMount() {
		this.fetchAnimation();

		this.setFullSelection();

		ReactGA.pageview(`/highlights`);
	}

	public render() {
		const { loading, animations, fullSelection } = this.state;

		return (
			<div id="highlights-page">
				<Helmet>
					<title>Cornouaille Animations - Les temps forts</title>
					<meta property="og:title" content="Cornouaille Animations - Les temps forts" />
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Temps forts</div>

				<div id="highlights-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>TEMPS FORTS</span>

						<img alt="" src="/heart.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="description">
					<div id="description-title">
						<img alt="" src="/picto-lettrine.svg" aria-hidden="true" role="img"></img>

						<span>Les animations à ne pas manquer !</span>
					</div>

					<div id="description-content">
						Ici, retrouvez les temps forts de vos évènements sur vos communes.
					</div>
				</div>

				<div id="list-and-map-container">
					<div id="list-container">
						<div id="highlights-list">
							{animations && animations.length > 0 ? (
								animations.map((elem, index) => (
									<ItemPreview
										key={index}
										data={elem}
										onClick={this.getGoToAnimation(elem.id)}
										deleteIcon={true}
										onDeletion={() => this.forceUpdate()}
										onMouseEnter={this.onAnimationHoverIn(elem)}
										onMouseLeave={this.onAnimationHoverOut(elem)}
										fullSelection={fullSelection}
										setFullSelection={this.setFullSelection}
									/>
								))
							) : !loading && animations && !animations.length ? (
								<div style={{ textAlign: 'center', flexGrow: 1 }}>
									Aucun temps fort en ce moment... mais n'hésitez pas à consulter
									cette page à l'avenir.
								</div>
							) : (
								<div />
							)}
						</div>

						{loading ? (
							<Loading />
						) : (
							this.state.displayMore && (
								<div id="display-more">
									<span onClick={this.fetchAnimation}>Afficher plus</span>
								</div>
							)
						)}
					</div>

					<div id="map-container">
						<AnimationMap
							animations={this.state.animations}
							zoom={9}
							highlighted={this.state.hovered}
							animationPopUpOnClick={this.getGoToAnimation}
						/>
					</div>
				</div>

				<AnimationSuggestion history={this.props.history} />
			</div>
		);
	}
}
