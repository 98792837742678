import React, { useState } from "react";

/**
 * this hoc manages the opening and closing of the search panels.
 * by using it into a component as export default withToggleSearch(MyComponentName) 
 * its functions and variables are usable into this component by calling them as props
 * 
 * @param Component
 */
const withToggleSearch = (Component) => ({ ...props }) => {
    const [displaySearch, setDisplaySearch] = useState(false);

    const closeSearch = () => setDisplaySearch(false);

    const openSearch = () => setDisplaySearch(true);

    const toggleSearch = () => {
        setDisplaySearch((prevDisplaySearch) => !prevDisplaySearch);
    };

    return <Component
        displaySearch={displaySearch}
        closeSearch={closeSearch}
        openSearch={openSearch}
        toggleSearch={toggleSearch}
        {...props}
    />
};

export default withToggleSearch;
