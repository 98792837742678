import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import URLTools from 'utils/URL';

import './plan.scss';
import Loading from 'atoms/Loading';

type Props = {
	history: History<any>;
};

type State = {
	plan?: Array<any>;
	error: boolean;
	loading: boolean;
	page: number;
};

const PER_PAGE = 100;
const PLAN_SLUG = 'plan-du-site';

export default class Plan extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			page: 1,
		};
	}

	private onPlanFetched = (data: any) => {
		this.setState({ loading: false, plan: data, page: this.state.page + 1 }, () => {
			if (data && data.length === PER_PAGE) {
				this.fetchPlan();
			}
		});
	};

	private fetchPlan = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/pages?per_page=${PER_PAGE}&page=${this.state.page}&slug=${PLAN_SLUG}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onPlanFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	private getPlan = () => {
		const plan = this.state.plan;

		return (
			plan &&
			plan.map((item: any, index: number) => (
				<div key={index} className="plan-item">
					{Object.keys(item.plan).map((sectionName, index) => (
						<div className="plan-section" key={index}>
							<h1 className="plan-section-title">{sectionName}</h1>

							<ul>
								{item.plan[sectionName].map((elem: any, index: number) => (
									<li key={index} className="plan-sction-item">
										{elem.nom}
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			))
		);
	};

	public componentDidMount() {
		this.fetchPlan();

		ReactGA.pageview(`/selection`);
	}

	public render() {
		const { error, loading } = this.state;

		return (
			<div id="plan-page">
				<Helmet>
					<title>Cornouaille Animations - Le plan</title>
					<meta property="og:title" content="Cornouaille Animations - Le plan" />
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Plan</div>
				<div id="plan-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>PLAN DU SITE</span>
					</div>
				</div>

				<div id="plan-container">
					{loading ? (
						<Loading></Loading>
					) : error ? (
						<div>Une erreur a eu lieu, veuillez ré-essayer plus tard</div>
					) : (
						this.getPlan()
					)}
				</div>
			</div>
		);
	}
}
