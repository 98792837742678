import React from 'react';

import './dates.scss';

export interface Props {
	beginDate: Date;
	endDate: Date;
}

const Dates: React.FC<Props> = ({ beginDate, endDate }) => {
	return (
		<div className="date-container">
			<div className="begin-date">{beginDate}</div>

			<img src="/separator.svg" alt="" aria-hidden="true" role="img"/>

			<div className="end-date">{endDate}</div>
		</div>
	);
};

export default Dates;
