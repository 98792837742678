import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Route, Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import Button from 'atoms/Button';
import { FlexColumn } from 'atoms/Flex';
import Loading from 'atoms/Loading';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';
import ActivityPage from 'pages/Activity';
import AnimationPage from 'pages/Animation';
import ActivitiesPage from 'pages/ActivitiesPage';
import ContactPage from 'pages/Contact';
import FlyerPage from 'pages/Flyer';
import HighlightsPage from 'pages/Highlights';
import LegalPage from 'pages/Legal';
import HomePage from 'pages/Home';
import MarketsPage from 'pages/Market';
import MySelectionPage from 'pages/MySelection';
import PlanPage from 'pages/Plan';
import PrivacyPage from 'pages/Privacy';
import { DataProvider, fetchAppSettings } from 'utils/DataStore';
import URLTools from 'utils/URL';

import './styles/constants.scss';
import './styles/base.scss';
import './styles/fonts.scss';

import './App.scss';
import Widget from 'pages/Widget';

const history = createBrowserHistory();
ReactGA.initialize('UA-112242-33');

const App: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);

	const fetchSettings = () => {
		setIsLoading(true);
		fetchAppSettings(URLTools.getApiURL(`/wp-json/cornouaille/v1/settings`), {
			method: 'GET',
			withCache: true,
		}).then(onSettingsFetched);
	};

	useEffect(fetchSettings, []);

	const onSettingsFetched = (data: any) => {
		setIsLoading(false);
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<DataProvider>
				<FlexColumn className="app">
					<Router history={history}>
						<Route component={Header} />
					</Router>

					<div className="content-container">
						<Router history={history}>
							<ScrollToTop />
							<Route path="/activities" component={ActivitiesPage} exact={true} />

							<Route path="/activities/:id" component={ActivityPage} exact={true} />

							<Route path="/animations/:id" component={AnimationPage} exact={true} />

							<Route path="/selection" component={MySelectionPage} exact={true} />

							<Route path="/highlights" component={HighlightsPage} exact={true} />

							<Route path="/market" component={MarketsPage} exact={true} />

							<Route path="/flyer" component={FlyerPage} exact={true} />

							<Route path="/mentions" component={LegalPage} exact={true} />

							<Route path="/contact" component={ContactPage} exact={true} />

							<Route path="/plan" component={PlanPage} exact={true} />

							<Route path="/widget" component={Widget} exact={true} />
							<Route path="/privacy" component={PrivacyPage} exact={true} />
							<Route path="/widget" component={Widget} exact={true} />

							<Route path={['/', '/search']} component={HomePage} exact={true} />
						</Router>
					</div>

					<Footer history={history} />
					<CookieConsent
						location="bottom"
						cookieName="cornAnimAccepted"
						buttonText="J'ai compris"
						ButtonComponent={(props) => (
							<Button color="blue" onClick={props.onClick}>
								{props.children}
							</Button>
						)}
						style={{ zIndex: 10000, padding: '16px 32px', boxSizing: 'border-box' }}>
						Ce site utilise des cookies pour fonctionner correctement.{' '}
						<a href="/privacy" className="lien">
							Plus d'informations
						</a>
					</CookieConsent>
				</FlexColumn>
			</DataProvider>
		</>
	);
};

export default App;
