import React, { Component } from 'react';
import { History } from 'history';

import Loading from 'atoms/Loading';
import ItemPreview from 'components/ItemPreview';
import { FULL_SELECTION } from 'utils/Constants';
import URLTools from 'utils/URL';
import { getSelection } from 'utils/Selection';

import './animationSuggestion.scss';

type Props = {
	history: History<any>;
};

type State = {
	suggestions: Array<any>;
	suggestionsLoading: boolean;
	error: boolean;
	fullSelection: boolean;
};

export default class AnimationSuggestion extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			suggestions: [],
			suggestionsLoading: false,
			error: false,
			fullSelection: this.setFullSelection(),
		};
	}

	private setFullSelection = () => {
		let storedArray: number[] = getSelection() || [];

		if (storedArray.length < FULL_SELECTION) {
			this.setState({ fullSelection: false });
			return false;
		} else {
			this.setState({ fullSelection: true });
			return true;
		}
	};

	private getGoToAnimation = (data: any) => () =>
		this.props.history.push(`/animations/${data.id}`);

	private onSuggestionFetched = (data: Array<any>) =>
		this.setState({ suggestions: data, suggestionsLoading: false });

	private getSuggestion = () => {
		let url = URLTools.getApiURL('/wp-json/cornouaille/v1/animations?temps_forts&per_page=4');
		const selectedIds = getSelection();

		if (selectedIds) {
			if (selectedIds && selectedIds.length > 0) {
				url += `&exclude=${selectedIds.map((id, index) =>
					index === selectedIds.length - 1 ? id : `${id},`,
				)}`;
			}
		}

		this.setState({ suggestionsLoading: true, error: false }, () => {
			fetch(url, { method: 'GET' })
				.then((response: any) => response.json())
				.then(this.onSuggestionFetched)
				.catch((e: any) => this.setState({ error: true }));
		});
	};

	public componentDidMount() {
		this.getSuggestion();
	}

	public render() {
		const { fullSelection, suggestions, suggestionsLoading } = this.state;

		return (
			<div id="suggestions">
				<div id="suggestions-title">
					<span>SORTIES QUI POURRAIENT VOUS INTÉRESSER</span>

					<img alt="" src="/picto-pin_black.svg" aria-hidden="true" role="img"></img>
				</div>

				<div id="animation-container">
					{suggestionsLoading ? (
						<Loading />
					) : suggestions && suggestions.length > 0 ? (
						suggestions.map((elem, index) => (
							<ItemPreview
								data={elem}
								key={index}
								onClick={this.getGoToAnimation(elem)}
								fullSelection={fullSelection}
								setFullSelection={this.setFullSelection}
							/>
						))
					) : (
						<div>
							{this.state.error
								? 'Impossible de récupérer les animations, veuillez réessayer plus tard'
								: 'Aucune animation disponible'}
						</div>
					)}
				</div>
			</div>
		);
	}
}
