import React from 'react';

import './loading.scss';

export default (props: any) => (
	<div className="loading-container">
		<img
			src="/picto-loading.svg"
			className="loading"
			alt=""
			aria-hidden="true"
			role="img"
			{...props}
		/>
	</div>
);
