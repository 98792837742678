import React, { Component } from 'react';

import './researchSummary.scss';

import { ActivitySearchParams, SearchParams } from 'types/searchParamsType';
import { convertDate } from 'utils/DateUtils';

interface Props {
	categories: {
		description: string;
		hierarchical: boolean;
		labels: object;
		liste: Array<{ term_id: number; count: number; name: string; slug: string }>;
		name: string;
		rewrite: object;
		slug: object;
		types: Array<any>;
	};
	currentActivityType?: string;
	searchParams: Partial<SearchParams> | Partial<ActivitySearchParams>;

	closeSearch(): void;
	setSearchState(
		searchState: Partial<SearchParams> | Partial<ActivitySearchParams>,
		currentActivityType?: string,
	): void;
}

type State = {
	searchParams: Partial<SearchParams>;
};

class ResearchSummary extends Component<Props, State> {
	constructor(props) {
		super(props);

		//* init state
		this.state = {
			searchParams: this.props.searchParams,
		};
	}

	componentDidUpdate(prevProps) {
		const { searchParams } = this.props;
		const { searchParams: oldSearchParams } = prevProps;

		// update this.state.searchParams when this.props.searchParams has changed
		// it means it has been changed from ResearchSummary
		if (searchParams !== oldSearchParams) {
			this.setState({ searchParams: this.props.searchParams });
		}
	}

	//* ACTIONS ON CROSS TO RESET A CRITERIA
	private changeChildrenFriendlyParam = () => {
		this.setState(
			{ searchParams: { ...this.state.searchParams, childrenFriendly: false } },
			() =>
				this.props.setSearchState(
					this.state.searchParams,
					this.props.currentActivityType ?? '',
				),
		);
	};

	private changeCityParam = () => {
		this.setState(
			{
				searchParams: {
					...this.state.searchParams,
					city: undefined,
					selectedCityIndex: undefined,
				},
			},
			() =>
				this.props.setSearchState(
					this.state.searchParams,
					this.props.currentActivityType ?? '',
				),
		);
	};

	private changeDateParam = () => {
		this.setState(
			{
				searchParams: {
					...this.state.searchParams,
					beginDate: undefined,
					endDate: undefined,
				},
			},
			() =>
				this.props.setSearchState(
					this.state.searchParams,
					this.props.currentActivityType ?? '',
				),
		);
	};

	private changeGeolocalizationParam = () => {
		this.setState(
			{
				searchParams: {
					...this.state.searchParams,
					isGeolocalized: false,
					position: undefined,
				},
			},
			() =>
				this.props.setSearchState(
					this.state.searchParams,
					this.props.currentActivityType ?? '',
				),
		);
	};

	private changeSelectedCategoryParam = (categoryId: number) => {
		const {
			currentActivityType,
			searchParams: { selectedCategories },
		} = this.props;

		//* create state according to its parent (Animations = {} - Activities need selectedCategories object)
		let currentActivitySelectedCategories =
			currentActivityType && this.state.searchParams
				? this.handleCurrentActivitySelectedCategories()
				: '';
		let newState = {};

		let selection: Array<number> = [];
		// I want a new selectedCategories Array which no longer contains the id of the unset category
		let newSelectedCategories: Array<any> = [];

		if (selectedCategories) {
			if (selectedCategories.length > 0) {
				selection = selectedCategories;
			} else if (
				!!currentActivityType &&
				currentActivitySelectedCategories &&
				!!currentActivitySelectedCategories.length
			) {
				selection = currentActivitySelectedCategories;
			}

			newSelectedCategories = selection.filter((category) => {
				if (category !== categoryId) {
					return categoryId;
				}

				return false;
			});

			if (selectedCategories.length > 0) {
				newState = {
					...this.state.searchParams,
					selectedCategories: newSelectedCategories,
				};
			} else if (
				!!currentActivityType &&
				currentActivitySelectedCategories &&
				!!currentActivitySelectedCategories.length
			) {
				newState = {
					...this.state.searchParams,
					selectedCategories: {
						...this.state.searchParams.selectedCategories,
						[currentActivityType]: newSelectedCategories,
					},
				};
			}
		}

		// I change the state with this new array
		this.setState({ searchParams: newState }, () =>
			this.props.setSearchState(
				this.state.searchParams,
				this.props.currentActivityType ?? '',
			),
		);
	};

	/**
	 * allows us to get the selected categories only for the current activity type
	 * since we have an object into selectedCategories with the 4 activity types as key
	 */
	private handleCurrentActivitySelectedCategories = () => {
		let selection: any = [];

		if (this.state.searchParams && this.state.searchParams.selectedCategories) {
			selection = Object.entries(this.state.searchParams.selectedCategories)
				.map((entry) => {
					return { id: entry[0], value: entry[1] };
				})
				.find((item) => {
					return item.id === this.props.currentActivityType;
				});

			selection = selection.value;
		}

		return selection;
	};

	//* Reset all searchParams
	private searchReset = () => {
		const { currentActivityType } = this.props;

		//* create the new state according to Animations ({}) or Activities (which has a particular way to deal with selectedCategories)
		let newSearchParamsState = {};

		if (!!currentActivityType) {
			newSearchParamsState = {
				selectedCategories: {
					asc: [],
					deg: [],
					loi: [],
					pcu: [],
				},
			};
		}

		//* update the state into ResearchSummary and its current parent and close the research
		this.setState({ searchParams: newSearchParamsState }, () => {
			this.props.setSearchState(
				this.state.searchParams,
				this.props.currentActivityType ?? '',
			);
			this.props.closeSearch();
		});
	};

	//* Partial Renders
	private renderResearchSummaryCriteria = () => {
		const { searchParams } = this.props;

		const params = Object.keys(searchParams).map((key) => {
			const label = key;
			const value = searchParams[key];

			const beginDate = searchParams?.beginDate
				? convertDate(searchParams.beginDate)
				: convertDate(new Date());
			const endDate = searchParams?.endDate
				? convertDate(searchParams.endDate)
				: convertDate(new Date());

			if (
				(label === 'beginDate' && value && searchParams?.endDate) ||
				(label === 'endDate' && value && !searchParams?.beginDate)
			) {
				return (
					<div key={label} className="research-summary__criteria">
						{`du ${beginDate} au ${endDate}`}
						<img src="/close.svg" alt="Fermer" onClick={this.changeDateParam} />
					</div>
				);
			} else if (label === 'childrenFriendly' && value) {
				return (
					<div key={label} className="research-summary__criteria">
						Adapté aux enfants
						<img
							src="/close.svg"
							alt="Fermer"
							onClick={this.changeChildrenFriendlyParam}
						/>
					</div>
				);
			} else if (label === 'city' && value) {
				return (
					<div key={label} className="research-summary__criteria">
						{value.nom}
						<img src="/close.svg" alt="Fermer" onClick={this.changeCityParam} />
					</div>
				);
			} else if (label === 'isGeolocalized' && value) {
				return (
					<div key={label} className="research-summary__criteria">
						Géolicalisation activée
						<img
							src="/close.svg"
							alt="Fermer"
							onClick={this.changeGeolocalizationParam}
						/>
					</div>
				);
			} else if (label === 'selectedCategories' && value) {
				return this.renderSelectedCategories(value);
			}

			return null;
		});

		return params;
	};

	private renderSelectedCategories = (selectedCategories: Array<number>) => {
		const { categories, currentActivityType } = this.props;

		//* activity list according to its parent
		let currentActivitySelectedCategories =
			currentActivityType && this.state.searchParams
				? this.handleCurrentActivitySelectedCategories()
				: '';
		let selection: Array<number> = [];
		// I look for the selectedCategories names
		// !categoryNames = [] === Array<Never>
		let categoryNames: Array<{ name: string; id: number }> = [];

		// if we are on Animations, the selected categories are an array into searchParams.selectedCategories while they are into currentActivitySelectedCategories if we are on Activities (which is a variable created into Activities from searchParams.selectedCategories.[currentActivityType] convert thanks to handleCurrentActivitySelectedCategories function into Activities)
		if (selectedCategories) {
			if (selectedCategories.length > 0) {
				selection = selectedCategories;
			} else if (
				!!currentActivityType &&
				currentActivitySelectedCategories &&
				!!currentActivitySelectedCategories.length
			) {
				// we want to find which selectedCategories prop has an array
				selection = currentActivitySelectedCategories;
			}

			// so for each selected category that has been copied into selection
			categoryNames = selection.reduce<Array<{ name: string; id: number }>>(
				(categoryNames, currCategory) => {
					// i look for it into the category list thanks to its id
					const category = categories.liste.find(
						({ term_id }) => term_id === currCategory,
					);

					// if it exists, I want to add its name into categoryNames array
					if (category) {
						return categoryNames.concat({ name: category.name, id: category.term_id });
					}

					// otherwise I keep it the same
					return categoryNames;
				},
				[],
			);
		}

		// I render the selectedCategories critera
		return categoryNames.map((category) => {
			return (
				<div key={category.id} className="research-summary__criteria">
					{category.name}
					<img
						src="/close.svg"
						alt="Fermer"
						onClick={() => this.changeSelectedCategoryParam(category.id)}
					/>
				</div>
			);
		});
	};

	//* Component Render
	public render() {
		return (
			<div className="research-summary">
				<div className="research-summary__title">Votre Recherche</div>
				<div className="research-summary__criteria-list">
					{this.renderResearchSummaryCriteria()}
				</div>
				<div className="research-summary__reset" onClick={this.searchReset}>
					Réinitialiser la recherche
				</div>
			</div>
		);
	}
}

export default ResearchSummary;
