import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import AnimationSuggestion from 'components/AnimationSuggestion';
import URLTools from 'utils/URL';

import './flyer.scss';
import Loading from 'atoms/Loading';

type Props = {
	history: History<any>;
};

type State = {
	flyers?: Array<any>;
	error: boolean;
	loading: boolean;
	page: number;
};

const PER_PAGE = 100;
const FLYER_ID = 76;

export default class Flyers extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			page: 1,
		};
	}

	private onFlyersFetched = (data: any) => {
		this.setState({ loading: false, flyers: data, page: this.state.page + 1 }, () => {
			if (data && data.length === PER_PAGE) {
				this.fetchFlyers();
			}
		});
	};

	private fetchFlyers = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/pages?per_page=${PER_PAGE}&page=${this.state.page}&categories=${FLYER_ID}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onFlyersFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	public componentDidMount() {
		this.fetchFlyers();

		ReactGA.pageview(`/flyer`);
	}

	public render() {
		const { error, flyers, loading } = this.state;

		return (
			<div id="flyers-page">
				<Helmet>
					<title>Cornouaille Animations - Brochures et cartes</title>
					<meta property="og:title" content="Cornouaille Animations - Brochures et cartes" />
    			<meta property="og:description" content="L’agenda des événements festifs et culturels en Cornouaille" />
    			<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Brochures et cartes</div>

				<div id="flyers-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>BROCHURES ET CARTES</span>

						<img alt="" src="/flyer.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="documents-container">
					{loading ? (
						<Loading />
					) : error ? (
						<div>Une erreur a eu lieu, veuillez ré-essayer plus tard</div>
					) : (
						flyers &&
						flyers.map((flyer, index) => (
							<div className="document-item">
								<div
									key={index}
									dangerouslySetInnerHTML={{
										__html: flyer.content.rendered as string,
									}}
								/>
							</div>
						))
					)}
				</div>

				<AnimationSuggestion history={this.props.history} />
			</div>
		);
	}
}
