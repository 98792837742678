// npm imports
import React, { Component } from 'react';

// local imports
import ItemPreview from 'components/ItemPreview';
import { FULL_SELECTION } from 'utils/Constants';

import './suggestions.scss';

// types
type Props = {
	item?: any;
	itemType?: string;
	suggestion?: Array<any>;

	getGoToItem: (id: number) => () => void;
};

type State = { fullSelection: boolean };

// component
export default class Suggestion extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			fullSelection: false,
		};
	}

	componentDidMount() {
		this.setFullSelection();
	}

	private setFullSelection = () => {
		let storedArray: any = getSelection() || [];

		if (storedArray.length < FULL_SELECTION) {
			this.setState({ fullSelection: false });
			return false;
		} else {
			this.setState({ fullSelection: true });
			return true;
		}
	};

	render() {
		const { fullSelection } = this.state;
		const { item, suggestion } = this.props;
		const lieu_principal = item.lieu_principal
			? item.lieu_principal.toUpperCase()
			: item.commune.toUpperCase();

		return (
			<>
				{suggestion && suggestion.length > 0 && (
					<div id="item-suggestions">
						<div className="title">
							<span>D'AUTRES SORTIES PRÈS DE {lieu_principal}</span>
							<img alt="" src="/picto-pin_black.svg" aria-hidden="true" role="img" />
						</div>

						<div id="suggestions-container">
							{suggestion.map((elem, index) => (
								<ItemPreview
									key={index}
									data={elem}
									itemType={'activité'}
									onClick={this.props.getGoToItem(elem.id)}
									fullSelection={fullSelection}
									setFullSelection={this.setFullSelection}
								/>
							))}
						</div>
					</div>
				)}
			</>
		);
	}
}
