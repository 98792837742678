import React from 'react';
import { History } from 'history';

import AnimationPage from 'pages/Animation';
import ActivityPage from 'pages/Activity';
import MySelectionArray from './MySelectionArray';

type Props = {
	history: History;
	selectionArrayTitle: string;
	selectedAnimations: Array<any>;
	selectedActivities: Array<any>;
};

const MySelectionContent = ({
	history,
	selectionArrayTitle,
	selectedActivities,
	selectedAnimations,
}: Props) => {
	const selectionCount =
		!!selectedAnimations || !!selectedAnimations
			? selectedAnimations.length + selectedActivities.length
			: 0;

	if (selectionCount === 1) {
		return (
			<>
				{!!selectedAnimations &&
					!!selectedAnimations.length &&
					selectedAnimations.map((elem, index) => (
						<AnimationPage
							key={index}
							history={history}
							match={{ params: { id: elem.id } }}
						/>
					))}
				{!!selectedActivities &&
					!!selectedActivities.length &&
					selectedActivities.map((elem, index) => (
						<ActivityPage
							key={index}
							history={history}
							match={{ params: { id: elem.id } }}
						/>
					))}
			</>
		);
	}

	if (selectionCount > 1) {
		return (
			<MySelectionArray
				selectionArrayTitle={selectionArrayTitle}
				selectedActivities={selectedActivities}
				selectedAnimations={selectedAnimations}
			/>
		);
	}

	return null;
};

export default MySelectionContent;
