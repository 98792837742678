import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import Dates from 'atoms/Dates';
import { getSelection } from 'utils/Selection';

import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import './map.scss';

type Props = {
	animationPopUpMessage?: string;
	animations: Array<any>;
	center?: [number, number];
	currentPosition?: {
		latitude: number;
		longitude: number;
	};
	highlighted?: any;
	scrollWheelZoom?: boolean;
	zoom?: number;

	animationPopUpOnClick?(id: number): () => void;
	onMoveEnd?(bounds: L.LatLngBounds): void;
};

const DEFAULT_ZOOM = 10;
const HILIGHTS_ID = 73;
const MARKET_ID = 26;

function isValidCenter(center?: [number, number]) {
	return (
		center &&
		center.length === 2 &&
		center[0] !== undefined &&
		center[0] !== null &&
		!isNaN(center[0]) &&
		center[1] !== undefined &&
		center[1] !== null &&
		!isNaN(center[1])
	);
}

const hilightsIcon = (highlighted: boolean) =>
	L.divIcon({
		html: `<div class="custom-marker">
	  <div class="custom-marker-label"><img src="/heart.svg" width="10" height="10" aria-hidden="true" role="img"></div>
	  <div class="custom-marker-pointer"/>
	</div>`,
		className: `custom-marker-icon ${highlighted ? 'highlighted' : ''}`,
	});

const marketIcon = (highlighted: boolean) =>
	L.divIcon({
		html: `<div class="custom-marker">
		  <div class="custom-marker-label"><img src="/stand.svg" width="10" height="10" aria-hidden="true" role="img"></div>
		  <div class="custom-marker-pointer"/>
		</div>`,
		className: `custom-marker-icon ${highlighted ? 'highlighted' : ''}`,
	});

const selectedIcon = (highlighted: boolean) =>
	L.divIcon({
		html: `<div class="custom-marker selected">
	  <div class="custom-marker-label"><img src="/blue-star.svg" width="10" height="10" aria-hidden="true" role="img"></div>
	  <div class="custom-marker-pointer"/>
	</div>`,
		className: `custom-marker-icon ${highlighted ? 'highlighted' : ''}`,
	});

const defaultIcon = (highlighted: boolean) =>
	L.divIcon({
		html: `<div class="custom-marker">
	  <div class="custom-marker-label"><img src="/picto-plus.svg" width="10" height="10" aria-hidden="true" role="img"></div>
	  <div class="custom-marker-pointer"/>
	</div>`,
		className: `custom-marker-icon ${highlighted ? 'highlighted' : ''}`,
	});

const currentPositionIcon = (highlighted: boolean) =>
	L.divIcon({
		html: `<div class="custom-marker current-position-marker">
	  <div class="custom-marker-label"><img src="/picto-user.svg" width="10" height="10" aria-hidden="true" role="img"></div>
	  <div class="custom-marker-pointer"/>
	</div>`,
		className: `custom-marker-icon ${highlighted ? 'highlighted' : ''}`,
	});

export default class AnimationMap extends Component<Props> {
	private map?: Map | null;

	public constructor(props: Props) {
		super(props);

		L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
	}

	private createClusterCustomIcon = (cluster: any) => {
		const count = cluster.getChildCount();
		const children = cluster.getAllChildMarkers();
		let isHighlighted = false;

		if (this.props.highlighted) {
			const title = this.props.highlighted.nom_offre;

			for (const elem of children) {
				if (elem.options.title === title) {
					isHighlighted = true;
					break;
				}
			}
		}

		return L.divIcon({
			html: `<div class="custom-marker">
			  <div class="custom-marker-label">${count}</div>
			  <div class="custom-marker-pointer"/>
			</div>`,
			className: `custom-marker-icon ${isHighlighted ? 'highlighted' : ''}`,
		});
	};

	private getIcon = (data: any) => {
		const highlighted = !!this.props.highlighted && this.props.highlighted.id === data.id;

		const stored = getSelection();
		if (stored) {
			for (const elem of stored) {
				if (elem === data.id) {
					return selectedIcon(highlighted);
				}
			}
		}

		if (data.categorie_id) {
			for (const catId of data.categorie_id) {
				if (catId === HILIGHTS_ID) {
					return hilightsIcon(highlighted);
				}
			}

			for (const catId of data.categorie_id) {
				if (catId === MARKET_ID) {
					return marketIcon(highlighted);
				}
			}
		}

		return defaultIcon(highlighted);
	};

	private getPopUp = (data: any) => {
		const src =
			(data.featured_media &&
				data.featured_media.sizes &&
				data.featured_media.sizes.thumbnail &&
				data.featured_media.sizes.thumbnail.src) ||
			(data.featured_media && data.featured_media.src) ||
			'/logo-DQCB-carte.svg';

		let selected = false;
		const stored = getSelection();
		if (stored) {
			for (const elem of stored) {
				if (elem === data.id) {
					selected = true;
					break;
				}
			}
		}

		return (
			<Popup id="animation-pop-up">
				<div>
					<div id="pop-up-title">
						{selected && <img alt="" src="/blue-star.svg"></img>}

						<span>{data.nom_offre}</span>
					</div>

					<img id="pop-up-image" src={src} alt="événement" />

					<div id="pop-up-footer">
						<Dates beginDate={data.date_debut} endDate={data.date_fin} />

						<div
							onClick={
								this.props.animationPopUpOnClick &&
								this.props.animationPopUpOnClick(data.id)
							}
							className="pop-up-link">
							{this.props.animationPopUpMessage || 'EN SAVOIR PLUS'}
						</div>
					</div>
				</div>
			</Popup>
		);
	};

	private onMoveEnd = () => {
		if (this.map && this.props.onMoveEnd) {
			this.props.onMoveEnd(this.map.leafletElement.getBounds());
		}
	};

	public invalidateSize = () => {
		this.map && this.map.leafletElement.invalidateSize(true);
	};

	public render() {
		const center: any = isValidCenter(this.props.center)
			? this.props.center
			: [47.997542, -4.097899];

		const animationsToDisplay = this.props.animations.filter(
			(elem) =>
				!!elem &&
				elem.latitude &&
				elem.latitude !== isNaN &&
				elem.longitude &&
				elem.longitude !== isNaN,
		);

		return (
			<div id="animation-map">
				<link
					href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
					rel="stylesheet"
					integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
					crossOrigin=""
				/>
				<script
					src="https://unpkg.com/leaflet@1.5.1/dist/leaflet.js"
					integrity="sha512-GffPMF3RvMeYyc1LWMHtK8EbPv0iNZ8/oTtHPx9/cc2ILxQ+u905qIwdpULaqDkyBKgOaB57QTMg7ztg8Jm2Og=="
					crossOrigin=""
				/>

				<div className="shadow-container" />

				<Map
					gestureHandling={true}
					onMoveEnd={this.onMoveEnd}
					scrollWheelZoom={this.props.scrollWheelZoom}
					ref={(elem) => (this.map = elem)}
					center={center}
					zoom={this.props.zoom || DEFAULT_ZOOM}
					zoomControl={false}>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<ZoomControl position="bottomleft"></ZoomControl>

					<MarkerClusterGroup iconCreateFunction={this.createClusterCustomIcon}>
						{animationsToDisplay.map((elem, index) => (
							<Marker
								icon={this.getIcon(elem)}
								position={[parseFloat(elem.latitude), parseFloat(elem.longitude)]}
								key={index}
								title={elem.nom_offre}>
								{this.getPopUp(elem)}
							</Marker>
						))}
					</MarkerClusterGroup>

					{this.props.currentPosition && (
						<Marker
							icon={currentPositionIcon(false)}
							position={[
								this.props.currentPosition.latitude,
								this.props.currentPosition.longitude,
							]}
							title="Votre position"
						/>
					)}
				</Map>
			</div>
		);
	}
}
