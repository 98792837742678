import React from 'react';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import './PdfGenerator.scss';

const ANIM_CHILDREN_FRIENDLY_ID = 11;
const ACT_CHILDREN_FRIENDLY_ID = 583;

function PdfGenerator({ selectionArrayTitle, selectedAnimations, selectedActivities }) {
	const isFree = (data) => {
		const isEntryFree = data.entree && data.entree.match(/(gratuit(e)?(s)?)|(free)/i);

		return isEntryFree && isEntryFree.length > 0;
	};

	const isChildFriendly = (data) => {
		if (!data) {
			return false;
		}

		if (
			(!!data.categorie_id && data.categorie_id.includes(ANIM_CHILDREN_FRIENDLY_ID)) ||
			(!!data.categorie_deg_id && data.categorie_deg_id.includes(ACT_CHILDREN_FRIENDLY_ID)) ||
			(!!data.categorie_loi_id && data.categorie_loi_id.includes(ACT_CHILDREN_FRIENDLY_ID)) ||
			(!!data.categorie_asc_id && data.categorie_asc_id.includes(ACT_CHILDREN_FRIENDLY_ID)) ||
			(!!data.categorie_pcu_id && data.categorie_pcu_id.includes(ACT_CHILDREN_FRIENDLY_ID))
		) {
			return true;
		}

		return false;
	};

	const isAccessible = (data) => {
		return data.mobilite && data.mobilite.toLowerCase() === 'oui';
	};

	//	RENDER
	let selection = !!selectedAnimations && !!selectedAnimations.length ? selectedAnimations : [];
	if (!!selectedActivities && !!selectedActivities.length) {
		selection = selection.concat(selectedActivities);
	}

	const selectionCount = selection.length;

	if (selectionCount === 0) {
		return null;
	}

	if (selectionCount > 1) {
		const styles = StyleSheet.create({
			viewer: {
				width: window.innerWidth / 2,
				height: window.innerHeight / 1.5,
			},
			page: {
				flexDirection: 'row',
			},
			section: {
				margin: 10,
				padding: 10,
				flexGrow: 1,
			},
			header: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			},
			logo: {
				width: 50,
				height: 50,
			},
			title: {
				fontSize: 12,
				textAlign: 'center',
				marginBottom: 12,
			},
			trow: {
				flexDirection: 'row',
				borderTop: '1px solid #0096ae',
				backgroundColor: '#e5e5e5',
			},
			trowbody: {
				flexDirection: 'row',
				borderTop: '1px solid #0096ae',
			},
			tlastrow: {
				flexDirection: 'row',
				borderTop: '1px solid #0096ae',
				borderBottom: '1px solid #0096ae',
			},
			th1: {
				fontSize: 9,
				padding: 5,
				borderLeft: '1px solid #0096ae',
				textAlign: 'left',
				width: 250,
			},
			// th2: {
			// 	fontSize: 9,
			// 	padding: 5,
			// 	borderLeft: '1px solid #0096ae',
			// 	textAlign: 'left',
			// 	width: 55,
			// },
			th3: {
				fontSize: 9,
				padding: 5,
				borderLeft: '1px solid #0096ae',
				textAlign: 'left',
				width: 200,
			},
			th4: {
				fontSize: 9,
				padding: 5,
				borderLeft: '1px solid #0096ae',
				textAlign: 'left',
				width: 200,
			},
			th5: {
				fontSize: 9,
				padding: 5,
				borderLeft: '1px solid #0096ae',
				textAlign: 'left',
				width: 120,
			},
			// th6: {
			// 	fontSize: 9,
			// 	padding: 5,
			// 	borderLeft: '1px solid #0096ae',
			// 	textAlign: 'left',
			// 	width: 140,
			// },
			th7: {
				fontSize: 9,
				padding: 5,
				borderLeft: '1px solid #0096ae',
				borderRight: '1px solid #0096ae',
				textAlign: 'center',
				width: 40,
			},
			// th8: {
			// 	fontSize: 9,
			// 	padding: 5,
			// 	borderLeft: '1px solid #0096ae',
			// 	borderRight: '1px solid #0096ae',
			// 	textAlign: 'left',
			// 	width: 180,
			// },
		});

		return (
			<>
				<Document file="cornouaille-ma-selection">
					<Page size="A4" orientation="landscape" style={styles.page}>
						<View style={styles.section}>
							<View style={styles.header}>
								<Text style={styles.title}>{selectionArrayTitle}</Text>
								<Image style={styles.logo} src="/logo-bleu.png" />
							</View>
							<View style={styles.trow}>
								<Text style={styles.th1}>Nom</Text>
								<Text style={styles.th4}>Dates</Text>
								<Text style={styles.th3}>Adresse</Text>
								<Text style={styles.th5}>Téléphone</Text>
								<Text style={styles.th7}>Payant</Text>
							</View>
							{selection &&
								selection.length &&
								selection.map((item, index) => {
									let address = `${item.adresse1 ? `${item.adresse1} -` : ''} ${
										item.adresse1suite ? `${item.adresse1suite} -` : ''
									} ${item.adresse2 ? `${item.adresse2} -` : ''} ${
										item.adresse2suite ? `${item.adresse2suite} -` : ''
									} ${item.code_postal ? `${item.code_postal} -` : ''} ${
										item.lieu_principal
											? `${item.lieu_principal}`
											: item.commune
											? `${item.commune}`
											: ''
									}`;
									{
										/* address = address.replaceAll('-', '\n'); */
									}
									const dateValides = item.dates_valides && item.dates_valides[0];
									let date = '';
									if (dateValides) {
										if (dateValides.date_debut !== dateValides.date_fin) {
											date = `${dateValides.date_debut} au ${dateValides.date_fin}`;
										} else {
											date = `${dateValides.date_debut} ${
												dateValides.heure_debut
													? ` ${dateValides.heure_debut}`
													: ''
											} ${
												dateValides.heure_fin
													? ` à ${dateValides.heure_fin}`
													: ''
											}`;
										}
									}
									const telephone =
										item.telephone && item.portable
											? `${item.telephone} - ${item.portable}`
											: item.telephone ?? item.portable ?? '';

									return (
										<View
											style={
												selectionCount - 1 === index
													? styles.tlastrow
													: styles.trowbody
											}>
											<Text style={styles.th1}>{item.nom_offre ?? ''}</Text>
											<Text style={styles.th4}>{date ?? ''}</Text>
											<Text style={styles.th3}>{address ?? ''}</Text>
											<Text style={styles.th5}>{telephone}</Text>
											<Text style={styles.th7}>{item.entree ? 'X' : ''}</Text>
										</View>
									);
								})}
						</View>
					</Page>
				</Document>
			</>
		);
	}

	const item = (selection = selection[0]);
	const styles = StyleSheet.create({
		viewer: {
			width: window.innerWidth / 2,
			height: window.innerHeight / 1.5,
		},
		page: {
			flexDirection: 'row',
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1,
		},
		flexSection: {
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		title: {
			fontSize: 10,
			textAlign: 'center',
			marginBottom: 12,
			backgroundColor: '#0096ae',
			color: '#ffffff',
			width: '100%',
			padding: 3,
		},
		image: {
			width: '45%',
			height: 'auto',
			marginBottom: 10,
		},
		picto: {
			width: 8,
			height: 8,
			marginRight: 5,
		},
		description: {
			width: '45%',
			fontSize: 8,
			margin: 'auto',
		},
		usefullInfosContainer: {
			width: '100%',
			color: '#0096ae',
			padding: 5,
		},
		usefullInfosTitle: {
			textAlign: 'center',
			fontSize: 8,
			padding: 5,
			borderBottom: '1px dotted black',
		},
		usefullInfosItem: {
			fontSize: 6,
			flexDirection: 'row',
			alignContent: 'center',
			width: '30%',
			padding: 10,
		},
	});

	const imageSource = item.featured_media.src;

	const origin = window.location.origin;

	const description = item.description ? item.description.replaceAll('<br />', '\n') : '';

	let address = `${item.adresse1 ? `${item.adresse1} -` : ''} ${
		item.adresse1suite ? `${item.adresse1suite} -` : ''
	} ${item.adresse2 ? `${item.adresse2} -` : ''} ${
		item.adresse2suite ? `${item.adresse2suite} -` : ''
	} ${item.code_postal ? `${item.code_postal} -` : ''} ${
		item.lieu_principal ? `${item.lieu_principal}` : item.commune ? `${item.commune}` : ''
	}`;
	address = address.replaceAll('-', '\n');

	const dateValides = item.dates_valides && item.dates_valides[0];
	let date = '';
	let horaire = '';
	if (dateValides) {
		if (dateValides.date_debut !== dateValides.date_fin) {
			date = `du ${dateValides.date_debut} au ${dateValides.date_fin}`;
		} else {
			date = `le ${dateValides.date_debut}`;
		}

		if (dateValides.heure_debut) {
			if (dateValides.heure_fin) {
				horaire = `de ${dateValides.heure_debut} à ${dateValides.heure_fin}`;
			} else {
				horaire = `à ${dateValides.heure_debut}`;
			}
		}
	}

	return (
		<Document file="cornouaille-ma-selection">
			<Page size="A4" orientation="portrait" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>{item.nom_offre}</Text>
					<View style={styles.flexSection}>
						{!!imageSource && (
							<Image
								// debug
								// cache
								style={styles.image}
								// https://github.com/diegomura/react-pdf/issues/1736
								src={{
									uri: imageSource,
									method: 'GET',
									headers: {
										'Access-Control-Allow-Origin': origin,
										// 'https://cornouaille-animations--fr.apsulis.fr',
										// 'http://localhost:3000',
										// '*',
										'Access-Control-Allow-Methods': 'GET, OPTIONS',
										'Access-Control-Allow-Headers': 'Content - Type',
										'Cache-Control': 'no-cache',
									},
									body: '',
								}}
							/>
						)}
						{!!description && (
							<View style={styles.description}>
								<Text>{description}</Text>
							</View>
						)}
						<View style={styles.usefullInfosContainer}>
							<Text style={styles.usefullInfosTitle}>INFOS PRATIQUES</Text>
							<View style={styles.flexSection}>
								{!!address && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-pin_white.png" style={styles.picto} />
										<Text>{address}</Text>
									</View>
								)}

								{!!date && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-dates-white.png" style={styles.picto} />
										<Text>{date}</Text>
									</View>
								)}

								{!!horaire && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-horaires.png" style={styles.picto} />
										<Text>{horaire}</Text>
									</View>
								)}

								{!!item.telephone && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-tel.png" style={styles.picto} />
										<Text>{item.telephone}</Text>
									</View>
								)}

								{!!item.portable && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-tel.png" style={styles.picto} />
										<Text>{item.portable}</Text>
									</View>
								)}

								{!!item.email && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-mail.png" style={styles.picto} />
										<Text>{item.email}</Text>
									</View>
								)}

								{!!item.entree && (
									<View style={styles.usefullInfosItem}>
										<Image
											src={
												isFree(item)
													? '/picto-gratuit.png'
													: '/picto-tarifs.png'
											}
											style={styles.picto}
										/>
										<Text>{isFree(item) ? 'Gratuit' : 'Payant'}</Text>
									</View>
								)}

								{!!isChildFriendly(item) && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-enfants.png" style={styles.picto} />
										<Text>POUR LES ENFANTS</Text>
									</View>
								)}

								{!!isAccessible(item) && (
									<View style={styles.usefullInfosItem}>
										<Image
											src="/picto-accessibilite.png"
											style={styles.picto}
										/>
										<Text>ACCESSIBLE PMR</Text>
									</View>
								)}

								{!!item.site_web && (
									<View style={styles.usefullInfosItem}>
										<Image src="/picto-lien.png" style={styles.picto} />
										<Text>{item.site_web}</Text>
									</View>
								)}
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
}

export default PdfGenerator;
