import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { PDFDownloadLink } from '@react-pdf/renderer';

import Button from 'atoms/Button';
import Loading from 'atoms/Loading';
import ItemPreview from 'components/ItemPreview';
import MySelectionContent from 'pages/MySelection/MySelectionContent';
import AnimationSuggestion from 'components/AnimationSuggestion';
import URLTools from 'utils/URL';
import {
	getActivitySelection,
	getSelection,
	setActivitySelection,
	setSelection,
} from 'utils/Selection';

import './mySelection.scss';
import PdfGenerator from 'pages/PdfGenerator';

type Props = {
	history: History<any>;
};

type State = {
	animationError: boolean;
	animationLoading: boolean;
	selectedAnimations: Array<any>;
	sharedAnimations: Array<any>;

	activityError: boolean;
	activityLoading: boolean;
	selectedActivities: Array<any>;
	sharedActivities: Array<any>;
};

const SELECTION_ARRAY_TITLE = 'CORNOUAILLE ANIMATIONS – votre sélection';

export default class MySelection extends Component<Props, State> {
	private toPrint: any;

	public constructor(props: Props) {
		super(props);

		this.state = {
			animationError: false,
			animationLoading: false,
			selectedAnimations: [],
			sharedAnimations: [],

			activityError: false,
			activityLoading: false,
			selectedActivities: [],
			sharedActivities: [],
		};
	}

	// Animation functions
	private getGoToAnimation = (data: any) => () =>
		this.props.history.push(`/animations/${data.id}`);

	private onSelectionFetched = (data: Array<any>) => {
		this.setState({ selectedAnimations: data, animationLoading: false }, () => {
			const ids = data.map((elem) => elem.id);
			setSelection(ids, false);
		});
	};

	private getSelection = () => {
		const selectedIds = getSelection();
		if (!selectedIds || selectedIds.length < 1) {
			return;
		}

		this.setState({ animationLoading: true, animationError: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/animations?include=${selectedIds.map((id, index) =>
						index === selectedIds.length - 1 ? id : `${id},`,
					)}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onSelectionFetched)
				.catch((e: any) =>
					this.setState({ animationError: true, animationLoading: false }),
				);
		});
	};

	private onAnimationDeleted = (id: number) => {
		const { selectedAnimations } = this.state;
		const index = selectedAnimations.findIndex((elem) => elem.id === id);

		if (index > -1) {
			selectedAnimations.splice(index, index + 1);
			this.setState({ selectedAnimations });
		}
	};

	// Activity functions
	private getGoToActivity = (data: any) => () =>
		this.props.history.push(`/activities/${data.id}`);

	private onActivityDeleted = (id: number) => {
		const { selectedActivities } = this.state;
		const index = selectedActivities.findIndex((elem) => elem.id === id);

		if (index > -1) {
			selectedActivities.splice(index, index + 1);
			this.setState({ selectedActivities });
		}
	};

	private getActivitySelection = () => {
		const selectedIds = getActivitySelection();
		if (!selectedIds || selectedIds.length < 1) {
			return;
		}

		this.setState({ activityLoading: true, activityError: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/activites?include=${selectedIds.map((id, index) =>
						index === selectedIds.length - 1 ? id : `${id},`,
					)}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onActivitySelectionFetched)
				.catch((e: any) => this.setState({ activityError: true, activityLoading: false }));
		});
	};

	private onActivitySelectionFetched = (data: Array<any>) => {
		this.setState({ selectedActivities: data, activityLoading: false }, () => {
			const ids = data.map((elem) => elem.id);
			setActivitySelection(ids, false);
		});
	};

	// deletion function
	private deleteAllSelection = () => {
		// storage
		localStorage.removeItem('myAnimationSelectionTime');
		localStorage.removeItem('myAnimationSelection');
		localStorage.removeItem('myActivitySelectionTime');
		localStorage.removeItem('myActivitySelection');

		// state
		this.setState({
			selectedAnimations: [],
			animationLoading: false,
			selectedActivities: [],
			activityLoading: false,
		});

		// event management
		const event: any = new Event('selectionChanged');
		event.value = [];
		document.dispatchEvent(event);
		this.getSelection();
		this.forceUpdate();
	};

	// share function
	private shareSelection = () => {
		const selectedIds = getSelection(); // animations
		const selectedActivitiesId = getActivitySelection();

		if (
			(!selectedIds || selectedIds.length < 1) &&
			(!selectedActivitiesId || selectedActivitiesId.length < 1)
		) {
			return;
		}

		// retrieve the base uri if it already has params
		const shareUrl = new URL(window.location.href.split('?')[0]);

		// create the new params
		let params = new URLSearchParams(shareUrl.search.slice(1));
		if (selectedIds && selectedIds.length > 0) {
			params.append('partage', selectedIds.join());
		}
		if (selectedActivitiesId && selectedActivitiesId.length > 0) {
			params.append('activites', selectedActivitiesId.join());
		}

		const subject = 'Cornouaille animations - Partage de ma sélection';
		// https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/encodeURIComponent
		// encodes special char from uri ( ? ans & )
		const urlParams = encodeURIComponent(params.toString());
		const body = `Pour consulter ma sélection, il suffit de se rendre ici : ${shareUrl}?${urlParams}`;

		window.open(`mailto:votre@destinataire.fr?subject=${subject}&body=${body}`);
	};

	private getShared = () => {
		const shareUrl = new URL(window.location.href);
		let params = new URLSearchParams(shareUrl.search.slice(1));

		// animations
		let partage =
			params.has('partage') && params.get('partage')
				? params.get('partage')!.split(',')
				: null;
		const selectedIds =
			partage !== null
				? partage.map(function (x) {
						return parseInt(x, 10);
				  })
				: null;

		if (!selectedIds || selectedIds.length < 1) {
			return;
		}

		this.setState({ animationLoading: true, animationError: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/animations?include=${selectedIds.map((id, index) =>
						index === selectedIds.length - 1 ? id : `${id},`,
					)}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onSharedFetched)
				.catch((e: any) =>
					this.setState({ animationError: true, animationLoading: false }),
				);
		});
	};

	private onSharedFetched = (data: Array<any>) => {
		this.setState({ sharedAnimations: data, animationLoading: false }, () => {
			data.map((elem) => elem.id);
		});
	};

	private getSharedActivities = () => {
		const shareUrl = new URL(window.location.href);
		let params = new URLSearchParams(shareUrl.search.slice(1));

		// animations
		let partage =
			params.has('activites') && params.get('activites')
				? params.get('activites')!.split(',')
				: null;
		const selectedIds =
			partage !== null
				? partage.map(function (x) {
						return parseInt(x, 10);
				  })
				: null;

		if (!selectedIds || selectedIds.length < 1) {
			return;
		}

		this.setState({ activityLoading: true, activityError: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/activites?include=${selectedIds.map((id, index) =>
						index === selectedIds.length - 1 ? id : `${id},`,
					)}`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onSharedActivitiesFetched)
				.catch((e: any) => this.setState({ activityError: true, activityLoading: false }));
		});
	};

	private onSharedActivitiesFetched = (data: Array<any>) => {
		this.setState({ sharedActivities: data, activityLoading: false }, () => {
			data.map((elem) => elem.id);
		});
	};

	// LIFE CYCLE
	public componentDidMount() {
		this.getSelection();
		this.getActivitySelection();
		this.getShared();
		this.getSharedActivities();
		ReactGA.pageview(`/selection`);
		this.forceUpdate();
	}

	// PRINCIPAL RENDER
	public render() {
		const {
			animationLoading,
			selectedAnimations,
			sharedAnimations,
			activityLoading,
			selectedActivities,
			sharedActivities,
		} = this.state;
		const { history } = this.props;

		return (
			<div id="my-selection">
				<Helmet>
					<title>Cornouaille Animations - Ma séléction d'événements</title>
					<meta
						property="og:title"
						content="Cornouaille Animations - Ma sélection d'événements"
					/>
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Ma sélection d'animations et d'activités</div>

				<div id="selection-header">
					<img
						alt=""
						src="/right-arrow.svg"
						className="header-arrow"
						aria-hidden="true"
						role="img"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>MA SÉLECTION</span>

						<img alt="" src="/filled-star.svg" aria-hidden="true" role="img"></img>
					</div>
				</div>

				<div id="description">
					<div id="description-title">
						<img alt="" src="/picto-lettrine.svg" aria-hidden="true" role="img"></img>

						<span>Comment ça marche ?</span>
					</div>

					<div id="description-content">
						<p>
							Cette rubrique vous permet de sélectionner les animation et activités
							qui vous intéressent pour pouvoir ensuite les imprimer.
						</p>

						<p>
							Cliquez sur l’étoile en haut de l’animation ou de l'activité pour
							composer votre propre carnet de voyage. Cette animation ou activité sera
							automatiquement ajoutée à votre panier.
						</p>

						<p>Votre sélection sera conservée pendant 2 jours.</p>
					</div>
				</div>

				<div className="selection">
					{animationLoading && <Loading></Loading>}

					{selectedAnimations && selectedAnimations.length > 0 && (
						<>
							<div className="selection__title">Votre sélection d'animations</div>

							<div className="selected-animations">
								{selectedAnimations.map((elem, index) => (
									<ItemPreview
										key={index}
										data={elem}
										onClick={this.getGoToAnimation(elem)}
										deleteIcon={true}
										onDeletion={this.onAnimationDeleted}
									/>
								))}
							</div>
						</>
					)}

					{(!selectedAnimations || selectedAnimations.length < 1) &&
						this.state.animationError && (
							<div style={{ textAlign: 'center', flexGrow: 1 }}>
								Impossible de récupérer votre séléction
							</div>
						)}
				</div>

				<div className="selection">
					{activityLoading && <Loading></Loading>}

					{selectedActivities && selectedActivities.length > 0 && (
						<>
							<div className="selection__title">Votre sélection d'activités</div>

							<div className="selected-animations">
								{selectedActivities.map((elem, index) => (
									<ItemPreview
										key={index}
										itemType={'activité'}
										data={elem}
										onClick={this.getGoToActivity(elem)}
										deleteIcon={true}
										onDeletion={this.onActivityDeleted}
									/>
								))}
							</div>
						</>
					)}

					{(!selectedAnimations || selectedAnimations.length < 1) &&
						this.state.animationError && (
							<div style={{ textAlign: 'center', flexGrow: 1 }}>
								Impossible de récupérer votre séléction
							</div>
						)}
				</div>

				<div className="line"></div>

				{((selectedAnimations && selectedAnimations.length > 0) ||
					(selectedActivities && selectedActivities.length > 0)) && (
					<div id="button-container">
						<ReactToPrint
							trigger={() => (
								<Button className="print-button" color="orange">
									<span>IMPRIMER MA SÉLECTION</span>

									<img
										alt=""
										src="/picto-imprimer.svg"
										aria-hidden="true"
										role="img"></img>
								</Button>
							)}
							content={() => this.toPrint}
						/>
						<div style={{ display: 'none' }}>
							<div ref={(elem) => (this.toPrint = elem)}>
								<MySelectionContent
									history={this.props.history}
									selectionArrayTitle={SELECTION_ARRAY_TITLE}
									selectedAnimations={this.state.selectedAnimations}
									selectedActivities={this.state.selectedActivities}
								/>
							</div>
						</div>
						&nbsp;
						<PDFDownloadLink
							document={
								<PdfGenerator
									selectionArrayTitle={SELECTION_ARRAY_TITLE}
									selectedAnimations={this.state.selectedAnimations}
									selectedActivities={this.state.selectedActivities}
								/>
							}
							fileName="cornouaille-ma-selection">
							<Button className="selected-icon delete-icon" color="orange">
								<span>ENREGISTER MA SÉLECTION AU FORMAT PDF</span>

								<img alt="" src="/flyer.svg" aria-hidden="true" role="img"></img>
							</Button>
						</PDFDownloadLink>
						&nbsp;
						<Button
							className="selected-icon delete-icon"
							color="orange"
							onClick={this.shareSelection}>
							<span>PARTAGER MA SÉLECTION PAR MAIL</span>

							<img alt="" src="/picto-lien.svg" aria-hidden="true" role="img"></img>
						</Button>
						&nbsp;
						<Button
							className="selected-icon delete-icon"
							color="orange"
							onClick={this.deleteAllSelection}>
							<span>EFFACER MA SÉLECTION</span>

							<img alt="" src="/picto-suppr.svg" aria-hidden="true" role="img"></img>
						</Button>
					</div>
				)}

				{sharedAnimations && sharedAnimations.length > 0 && (
					<div id="shared-header">
						<img
							alt=""
							src="/right-arrow.svg"
							className="header-arrow"
							aria-hidden="true"
							role="img"
							onClick={this.props.history.goBack}></img>

						<div>
							<span>MES RECOMMANDATIONS</span>

							<img alt="" src="/filled-star.svg" aria-hidden="true" role="img"></img>
						</div>
					</div>
				)}

				{sharedAnimations && sharedAnimations.length > 0 && (
					<div id="description-shared">
						<div id="description-shared-title">
							<img
								alt=""
								src="/picto-lettrine.svg"
								aria-hidden="true"
								role="img"></img>

							<span>Animations recommandées</span>
						</div>

						<div id="description-shared-content">
							<p></p>
						</div>
					</div>
				)}

				<div id="shared-animations">
					{animationLoading && <Loading></Loading>}

					{sharedAnimations &&
						sharedAnimations.map((elem, index) => (
							<ItemPreview
								key={index}
								data={elem}
								onClick={this.getGoToAnimation(elem)}
								deleteIcon={false}
								onDeletion={this.onAnimationDeleted}
							/>
						))}

					{(!sharedAnimations || sharedAnimations.length < 1) &&
						this.state.animationError && (
							<div style={{ textAlign: 'center', flexGrow: 1 }}>
								Impossible de récupérer votre séléction
							</div>
						)}
				</div>

				{sharedActivities && sharedActivities.length > 0 && (
					<div id="description-shared">
						<div id="description-shared-title">
							<img
								alt=""
								src="/picto-lettrine.svg"
								aria-hidden="true"
								role="img"></img>

							<span>Activités recommandées</span>
						</div>

						<div id="description-shared-content">
							<p></p>
						</div>
					</div>
				)}

				<div id="shared-animations">
					{activityLoading && <Loading></Loading>}

					{sharedActivities &&
						sharedActivities.map((elem, index) => (
							<ItemPreview
								key={index}
								data={elem}
								itemType={'activité'}
								onClick={this.getGoToActivity(elem)}
								deleteIcon={false}
								onDeletion={this.onActivityDeleted}
							/>
						))}

					{(!sharedActivities || sharedActivities.length < 1) &&
						this.state.activityError && (
							<div style={{ textAlign: 'center', flexGrow: 1 }}>
								Impossible de récupérer votre séléction
							</div>
						)}
				</div>

				<AnimationSuggestion history={this.props.history} />
			</div>
		);
	}
}
