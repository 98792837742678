import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import URLTools from 'utils/URL';

import './contact.scss';
import Loading from 'atoms/Loading';

type Props = {
	history: History<any>;
};

type State = {
	contacts?: Array<any>;
	error: boolean;
	loading: boolean;
	page: number;
};

const PER_PAGE = 100;

export default class Contact extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			error: false,
			loading: false,
			page: 1,
		};
	}

	private onContactsFetched = (data: any) => {
		this.setState({ loading: false, contacts: data, page: this.state.page + 1 }, () => {
			if (data && data.length === PER_PAGE) {
				this.fetchContacts();
			}
		});
	};

	private fetchContacts = () => {
		this.setState({ loading: true, error: false }, () => {
			fetch(
				URLTools.getApiURL(
					`/wp-json/cornouaille/v1/pages?per_page=${PER_PAGE}&page=${this.state.page}&categories=75`,
				),
				{ method: 'GET' },
			)
				.then((response: any) => response.json())
				.then(this.onContactsFetched)
				.catch((e: any) => this.setState({ error: true, loading: false }));
		});
	};

	public componentDidMount() {
		this.fetchContacts();

		ReactGA.pageview(`/contact`);
	}

	public render() {
		const { contacts, error, loading } = this.state;

		return (
			<div id="contact-page">
				<Helmet>
					<title>Cornouaille Animations - Les offices de tourisme</title>
					<meta
						property="og:title"
						content="Cornouaille Animations - Les offices du tourisme"
					/>
					<meta
						property="og:description"
						content="L’agenda des événements festifs et culturels en Cornouaille"
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
				</Helmet>

				<div id="location">Acceuil / Contact</div>
				<div id="contact-header">
					<img
						alt=""
						src="/right-arrow.svg"
						aria-hidden="true"
						role="img"
						className="header-arrow"
						onClick={this.props.history.goBack}></img>

					<div>
						<span>CONTACT</span>
					</div>
				</div>
				<div id="description">
					<div id="description-title">
						<img alt="" src="/picto-lettrine.svg" aria-hidden="true" role="img"></img>

						<span>
							{(contacts && contacts[0].title.rendered) || 'Les offices de tourisme'}
						</span>
					</div>
				</div>

				<div id="contacts-container">
					<link
						href={`https://api--cornouaille-animations--fr.apsulis.fr/wp-admin/load-styles.php?c=0&dir=ltr&load%5B%5D=dashicons,admin-bar,buttons,media-views,editor-buttons,wp-components,wp-block-editor,wp-nux,wp-editor,wp-block-library,wp-block-&load%5B%5D=library-theme,wp-edit-blocks,wp-edit-post,wp-format-library,common,forms,admin-menu,dashboard,list-tables,edit,revisions,media,t&load%5B%5D=hemes,about,nav-menus,wp-pointer,widgets,site-icon,l10n,wp-auth-check&ver=5.2.4`}
						rel="stylesheet"
					/>
					<link
						href={`https://api--cornouaille-animations--fr.apsulis.fr/wp-includes/css/dist/block-library/style.min.css?ver=5.2.4`}
						rel="stylesheet"
					/>
					{loading ? (
						<Loading></Loading>
					) : error ? (
						<div>Une erreur a eu lieu, veuillez ré-essayer plus tard</div>
					) : (
						contacts &&
						contacts.map((contact, index) => (
							<div
								key={index}
								className="contact-item"
								dangerouslySetInnerHTML={{
									__html: contact.content.rendered as string,
								}}
							/>
						))
					)}
				</div>
			</div>
		);
	}
}
