// npm imports
import React, { Component } from 'react';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// local imports
import Activities from 'components/Activities';
import WithToggleSearch from 'pages/HocPages/withToggleSearch';
import { DataConsumer, DataContext } from 'utils/DataStore';
import { decodeHTMLEntities } from 'utils/TextUtils';
import URLTools from 'utils/URL';

import './ActivitiesPage.scss';

// types
export interface Props {
	displaySearch: boolean;
    history: History<any>;

    closeSearch: () => void;
    openSearch: () => void;
    toggleSearch: () => void;
};

type State = {
	settings?: any;
};

// component
class ActivitiesPage extends Component<Props, State> {
	static contextType = DataContext;

	public constructor(props: Props) {
		super(props);

		this.state = {};
	};

	public componentDidMount() {
		this.setState({ settings: this.context.settings });

        ReactGA.pageview(`/activities`);
        
        // open search panel
        this.props.openSearch();
	};

    // COMPONENT RENDER
	public render() {
		let title: string =
			"Rechercher une activité - L'agenda des événements festifs et culturels en Cornouaille";
        const description = (this.state.settings && this.state.settings.description) || 'L’agenda des événements festifs et culturels en Cornouaille';
        
		return (
			<div id="activities-page" className={ this.props.displaySearch ? 'open' : 'close' }>
				<Helmet>
					<title>{decodeHTMLEntities(title)}</title>
					<meta property="og:title" content="Cornouaille Activités" />
					<meta
						property="og:description"
						content={description}
					/>
					<meta property="og:image" content="%PUBLIC_URL%/logo-blue.png" />
					<meta name="description" content={description} />
				</Helmet>

				<div id="activities-content">
					<div id="activities-content-container">
                         {/* we are using DataContext so we have a DataProvider (App) and DataConsumer (here Activities) */}
                        <DataConsumer>
                            {(store) => (
                                <Activities
                                    activities={store.activities}
                                    activityTypeCategories={store.activityTypeCategories}
                                    displaySearchParams={this.props.displaySearch}
                                    history={this.props.history}
                                    parentScrollContainer="#activities-content-container"
                                    url={URLTools.getApiURL(
                                        `/wp-json/cornouaille/v1/activites`,
                                    )}
                                    closeSearch={this.props.closeSearch}
                                    openSearch={this.props.openSearch}
                                    toggleSearch={this.props.toggleSearch}
                                />
                            )}
                        </DataConsumer>
                    </div>
				</div>
			</div>
		);
	};
};

export default WithToggleSearch(ActivitiesPage);
