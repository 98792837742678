// npm imports
import React, { Component } from 'react';

// local imports
import './carousel.scss';

// types
type Props = {
	item?: any;
};

const DOT_SCROLL_SIZE = 300;
const INTERVAL = 400;

function getDotNumberFromScroller({ scrollWidth, clientWidth }) {
	const scrollableWidth = scrollWidth - clientWidth; // surface scrollable hors écran à droite

	const dotNumber = Math.max(Math.trunc(scrollableWidth / DOT_SCROLL_SIZE), 2);
	const width = scrollableWidth / dotNumber; // LA LARGEUR A SCROLLE PAR DOT.

	return { dotNumber, width };
}

// component
export default class Carousel extends Component<Props> {
	private scroller?: HTMLDivElement | null;
	private resizeInterval?: number;

	public componentDidMount() {
		window.addEventListener('resize', this.resizeWithDebounce);
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.resizeWithDebounce);
	}

	// le compteur resizeInterval permet d'éviter de forcer le rendu à chaque resize (qui se fait avec le this.forceUpdate)
	// ce compteur est remis à 0 à chaque fois que l'utilisateur resize l'écran
	// de ce fait quand le compteur atteint INTERVAL -> l'utilisateur n'a pas resize l'écran depuis le temps imparti -> le forceUpdate peut se faire
	private resizeWithDebounce = () => {
		window.clearTimeout(this.resizeInterval);

		this.resizeInterval = window.setTimeout(() => {
			this.forceUpdate();
		}, INTERVAL);
	};

	private getGoToScroll = (position: number) => () => {
		if (this.scroller) {
			this.scroller.scrollTo({ left: position, behavior: 'smooth' });
		}
	};

	private getImages = () => {
		const { item } = this.props;

		return (
			item.images &&
			item.images.filter((elem: any) => !!elem && (!!elem.src || elem.medium?.src))
		);
	};

	private scrollPosition = () => {
		if (this.scroller) {
			const { dotNumber, width } = getDotNumberFromScroller(this.scroller);

			return Math.min(Math.trunc(this.scroller.scrollLeft / width) + 1, dotNumber);
		}

		return 0;
	};

	// ici on dynamise la classe css
	// on n'utilise pas de state pour mettre à jour la classe ce qui évite un render
	private updateIndex = () => {
		const currentIndex = this.scrollPosition() || 1;

		// on réinitialise la classe de tous les dot pour qu'aucun ne soit selected
		const dots = document.getElementsByClassName('dot');
		for (let i = 0; i < dots.length; i++) {
			dots[i].className = 'dot';
		}

		// on ajoute la classe selected à l'enfant correspondant à l'index courant
		const selectedDot = document.querySelector(`.dot:nth-child(${currentIndex})`);
		if (selectedDot) {
			selectedDot.className = 'dot selected';
		}
	};

	private renderDot = () => {
		if (!this.scroller) {
			return null; // pas retourner void mais null car un compo renvoi ou null ou false si il ne doit rien afficher
		}

		const scrollableWidth = this.scroller.scrollWidth - this.scroller.clientWidth; // surface scrollable hors écran à droite
		if (scrollableWidth === 0) {
			return null;
		}

		const { dotNumber, width: dotScrollWidth } = getDotNumberFromScroller(this.scroller);
		const dots: Array<JSX.Element> = [];

		for (let index = 0; index < dotNumber; index++) {
			dots.push(
				<div
					key={index}
					className="dot"
					onClick={this.getGoToScroll(
						(dotScrollWidth + dotScrollWidth / (dotNumber - 1)) * index,
					)} // ON DECALLE CHACUN DES POINTS DE LA TAILLE D UNE DOT / LE NOMBRE DE DOT SANS LA PREMIERE AFIN QUE LA 1ER DOT AMENE AU 1ER ET LE DERNIER DOT AMENE AU DERNIER EN GARDANT LE MEME ECART ENTRE CHAQUE DOT
				/>,
			);
		}

		return <>{dots}</>;
	};

	private setRef = (elem) => {
		this.scroller = elem;
	};

	render() {
		const { item } = this.props;
		const images = this.getImages();
		this.updateIndex(); // pour que le 1er dot soit selected au 1er rendu

		return (
			<>
				{item.images && item.images.length > 0 && (
					<div id="item-medias">
						<div className="line"></div>

						<div
							id="images-container-scroller"
							ref={this.setRef}
							onScroll={this.updateIndex}>
							<div id="images-container">
								{images.map((image: any, index: number) => {
									let src = (image.medium && image.medium.src) || image.src;

									return <img alt="événement" src={src} key={index} />;
								})}
							</div>
						</div>

						<div className="line"></div>

						<div id="dots-container">{this.renderDot()}</div>
					</div>
				)}
			</>
		);
	}
}
