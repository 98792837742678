import React from 'react';

import './MySelectionArray.scss';

type Props = {
	selectionArrayTitle: string;
	selectedActivities: any[];
	selectedAnimations: any[];
};

const MySelectionArray = ({
	selectionArrayTitle,
	selectedActivities,
	selectedAnimations,
}: Props) => {
	if (!selectedActivities.length && !selectedAnimations.length) {
		return null;
	}

	let selection: any[] = [];
	selection = selectedActivities ? selection.concat(selectedActivities) : selection;
	selection = selectedAnimations ? selection.concat(selectedAnimations) : selection;

	return (
		<>
			<div className="my-selection-array__header">
				<h2 className="my-selection-pdf-title">{selectionArrayTitle}</h2>
				<img src="/logo-bleu.png" alt="logo" />
			</div>
			<table className="my-selection-array">
				<thead>
					<tr>
						<th>Nom</th>
						<th>Dates</th>
						<th>Adresse</th>
						<th>Téléphone</th>
						<th>Payant</th>
					</tr>
				</thead>

				<tbody>
					{!!selection &&
						!!selection.length &&
						selection.map((item) => {
							const address = `${item.adresse1 ? `${item.adresse1} -` : ''} ${
								item.adresse1suite ? `${item.adresse1suite} -` : ''
							} ${item.adresse2 ? `${item.adresse2} -` : ''} ${
								item.adresse2suite ? `${item.adresse2suite} -` : ''
							} ${item.code_postal ? `${item.code_postal} -` : ''} ${
								item.lieu_principal
									? `${item.lieu_principal}`
									: item.commune
									? `${item.commune}`
									: ''
							}`;
							const dateValides = item.dates_valides && item.dates_valides[0];
							let date = '';
							if (dateValides) {
								if (dateValides.date_debut !== dateValides.date_fin) {
									date = `${dateValides.date_debut} au ${dateValides.date_fin}`;
								} else {
									date = `${dateValides.date_debut} ${
										dateValides.heure_debut ? ` ${dateValides.heure_debut}` : ''
									} ${
										dateValides.heure_fin ? ` à ${dateValides.heure_fin}` : ''
									}`;
								}
							}
							const telephone =
								item.telephone && item.portable
									? `${item.telephone} - ${item.portable}`
									: item.telephone ?? item.portable ?? '';

							return (
								<tr key={item.nom_offre}>
									<th>{item.nom_offre ?? ''}</th>
									<th>{date}</th>
									<th>{address}</th>
									<th>{telephone}</th>
									<th>{item.entree ? 'X' : ''}</th>
								</tr>
							);
						})}
				</tbody>
			</table>
		</>
	);
};

export default MySelectionArray;
